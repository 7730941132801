import styled, { css } from "styled-components";
import { PlaceCollection } from "../types";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useClickOutside } from "melodies-source/utils";
import { useState, useCallback, useMemo, useEffect } from "react";
import { usePopper } from "react-popper";
import { SvgForward } from "melodies-source/Svgs/Forward";
import { SvgBack } from "melodies-source/Svgs/Back";
import { MapPopupContent } from "./MapPopupContent";
import { Placement, PositioningStrategy } from "@popperjs/core";

interface MapPopupWrapperProps {
  anchor?: google.maps.Point;
  collection?: PlaceCollection;
  onClose: VoidFunction;
}

export const MapWrapper = ({ ...props }: MapPopupWrapperProps) => {
  return <MapPopupWrapper {...props} />;
};

export const MapPopupWrapper = ({
  anchor,
  collection,
  onClose,
}: MapPopupWrapperProps) => {
  const places = collection?.features?.map((c) => c.properties);
  const [activeIndex, setActiveIndex] = useState(0);
  const [containerRef, setContainerRef] = useState<HTMLDivElement>(null);
  const [popupRef, setPopupRef] = useState<HTMLDivElement>(null);
  const isOpen = Boolean(places?.length);

  const popperSettings = useMemo(
    () => ({
      placement: "bottom-start" as Placement,
      strategy: "fixed" as PositioningStrategy,
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
        {
          name: "offset",
          options: {
            offset: ({ placement, popper }) =>
              placement.includes("top")
                ? [20, popper.height * -0.26]
                : [20, popper.height * -0.32],
          },
        },
      ],
    }),

    [anchor],
  );

  const { styles, attributes } = usePopper(
    containerRef,
    popupRef,
    popperSettings,
  );

  const close = useCallback(() => onClose(), []);
  useClickOutside(popupRef, close);

  const selectedPlace = places?.[activeIndex];

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % places.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + places.length) % places.length,
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrev();
    } else if (event.key === "ArrowRight") {
      handleNext();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [isOpen]);

  const yPos = (anchor?.y || 0) * 1;

  return (
    <MapPopupWrapperContainer>
      {anchor && (
        <ReferenceElement
          ref={setContainerRef}
          style={{ top: yPos, left: anchor?.x || 0 }}
        />
      )}
      <PopupWrapper
        ref={setPopupRef}
        style={styles.popper}
        {...attributes.popper}
        isOpen={isOpen}
      >
        {selectedPlace && (
          <PopupContainer>
            <MapPopupContent
              key={`place-popup-${selectedPlace?.id}`}
              {...{ place: selectedPlace }}
            />
            {!!(places?.length > 1) && (
              <PopupFooter>
                <NavButton
                  xCenter
                  yCenter
                  disabled={activeIndex === 0}
                  onClick={handlePrev}
                >
                  <SvgBack style={{ margin: "0 2px 0 0" }} />
                </NavButton>
                <Dots yCenter>
                  {places.map((p, i) => (
                    <Dot key={`dot-${i}`} active={i === activeIndex} />
                  ))}
                </Dots>
                <NavButton
                  xCenter
                  yCenter
                  disabled={activeIndex === places?.length - 1}
                  onClick={handleNext}
                >
                  <SvgForward style={{ margin: "0 0 0 2px" }} />
                </NavButton>
              </PopupFooter>
            )}
          </PopupContainer>
        )}
      </PopupWrapper>
    </MapPopupWrapperContainer>
  );
};

const ReferenceElement = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  z-index: 555;
`;

const Dots = styled(FlexRow)`
  gap: 8px;
`;

const Dot = styled.div<{ active?: boolean }>`
  background-color: #cccccc;
  border-radius: 50%;
  width: 8px;
  height: 8px;

  ${(p) =>
    p.active &&
    css`
      background-color: var(--secondary-color);
    `};
`;

const PopupFooter = styled.div`
  background-color: #ffffff;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  justify-items: center;
  padding: 8px;
`;

const NavButton = styled(FlexRow)<{ disabled?: boolean }>`
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  svg {
    color: #cccccc;
  }

  ${(p) =>
    !p.disabled &&
    css`
      border-color: var(--secondary-color);
      svg {
        color: var(--secondary-color);
      }

      &:hover {
        cursor: pointer;
        opacity: 0.85;

        svg {
          opacity: 0.85;
        }
      }
    `};
`;

const PopupContainer = styled(FlexColumn)<{ isOpen?: boolean }>`
  position: relative;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.32);
`;

const PopupWrapper = styled(FlexColumn)<{ isOpen?: boolean }>`
  z-index: 500;

  ${(p) =>
    !p.isOpen &&
    css`
      display: none;
    `};
`;

const MapPopupWrapperContainer = styled.div`
  display: block;
  width: 1px;
  height: 1px;
  position: relative;
`;
