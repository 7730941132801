import { Market } from "Providers/ListProvider/types";
import useUserIdToken from "./useUserIdToken";

export interface VenueOverview {
  artistName: string;
  venues: { [id: string]: { name: string; address: string } };
}

export interface ArtistOverview {
  artistId: string | number;
  brandName: string;
  brandObjective: string;
  markets?: Market[];
}

export interface CampaignOverview {
  client: string;
  goal: string;
  target: string;
  type: "vetted" | "sample";
  artistCount: string;
}

export interface VenueOverviewResponse {
  [venueId: string]: {
    capacity: number | null;
    description: string;
  };
}

export interface ArtistOverviewResponse {
  overview: string;
  highlights: string[];
  artistId: string | number;
}

export interface CampaignOverviewResponse {
  overview: string;
}

interface UseGenerateTextProps {
  token: string;
  getVenueOverview: (
    data: VenueOverview,
  ) => Promise<VenueOverviewResponse | undefined>;
  getArtistOverview: (
    data: ArtistOverview,
  ) => Promise<ArtistOverviewResponse | undefined>;
  getCampaignOverview: (
    data: CampaignOverview,
  ) => Promise<CampaignOverviewResponse | undefined>;
}

export const useGenerateText = (): UseGenerateTextProps => {
  const token = useUserIdToken();

  const getVenueOverview = async (data: VenueOverview) => {
    try {
      const response = await fetch(`/api/venue/overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result?.data;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const getArtistOverview = async ({
    artistId,
    ...data
  }: ArtistOverview): Promise<ArtistOverviewResponse | undefined> => {
    try {
      const response = await fetch(`/api/artist/overview/${artistId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return { artistId, ...result?.data };
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const getCampaignOverview = async (
    data: CampaignOverview,
  ): Promise<ArtistOverviewResponse | undefined> => {
    try {
      const response = await fetch("/api/campaign/overview", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const result = await response.json();
      return result?.data;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return { token, getVenueOverview, getArtistOverview, getCampaignOverview };
};
