import { Map, useMap } from "@vis.gl/react-google-maps";
import { PlaceCollection, PlaceDetail } from "./types";
import { Markers } from "./components/Markers";
import { useMemo, useState } from "react";
import { getGeoJSON } from "utils";
import { DEFAULT_MAP_STYLE_ID } from ".";
import styled from "styled-components";
import { MapPopupWrapper } from "./components/MapPopupWrapper";
import { fromLatLngToPixel } from "./components/helpers";
import { useMapViewport } from "./hooks/use-map-viewport";

type ClusterMapProps = {
  id: string;
  mapId?: string;
  places?: PlaceDetail[];
  bounds?: google.maps.LatLngBoundsLiteral;
  clusterRadius?: number;
  disableControl?: boolean;
  zoom?: number;
};

export const ClusterMap = ({
  id,
  mapId,
  bounds,
  places,
  clusterRadius,
  disableControl,
  zoom: zoomOverride,
}: ClusterMapProps) => {
  const [activeCollection, setActiveCollection] = useState<PlaceCollection>();
  const [anchor, setAnchor] = useState<google.maps.Point>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const map = useMap(id);
  const { bbox, zoom } = useMapViewport({ map });
  const zoomOffset = zoomOverride || zoom;

  const handleCollectionClick = (
    anchor?: google.maps.marker.AdvancedMarkerElement,
    cluster?: PlaceCollection,
  ) => {
    setActiveCollection(null);
    if (anchor) {
      const updatedAnchor = fromLatLngToPixel(anchor.position, map);
      setAnchor(updatedAnchor);
      setActiveCollection(cluster);
    } else {
      setAnchor(null);
      setActiveCollection(null);
    }
  };

  const geojson = useMemo(() => getGeoJSON(places), [places]);

  return (
    <>
      {activeCollection && anchor && (
        <MapPopupWrapper
          anchor={anchor}
          collection={activeCollection}
          onClose={() => setActiveCollection(null)}
        />
      )}
      <Map
        id={id}
        clickableIcons={false}
        defaultBounds={bounds}
        gestureHandling={disableControl ? "none" : "auto"}
        disableDefaultUI={disableControl}
        fullscreenControl={false}
        mapId={mapId || DEFAULT_MAP_STYLE_ID}
        mapTypeControl={false}
        maxZoom={7}
        minZoom={3}
        streetViewControl={false}
        zoomControl={!disableControl}
        onTilesLoaded={() => {
          if (!isLoaded) {
            setIsLoaded(true);
            map?.fitBounds(bounds, 0);
          }
        }}
      >
        {!!geojson && (
          <Markers
            map={map}
            geojson={geojson}
            onCollectionClick={handleCollectionClick}
            activeCollection={activeCollection}
            clusterRadius={clusterRadius}
            zoom={zoomOffset}
            bbox={bbox}
          />
        )}
      </Map>
    </>
  );
};

export const MapContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;
