import regenerateJson from "assets/lotties/regenerate.json";
import type { AnimationItem } from "lottie-web";
import { Button } from "melodies-source/Button";
import { useEffect, useRef } from "react";
import Lottie from "react-lottie-player";

interface RegenerateButtonProps {
  loading?: boolean;
  onClick?(): void;
}

export const RegenerateButton = ({
  loading,
  onClick,
}: RegenerateButtonProps) => {
  const animation = useRef<AnimationItem>();

  useEffect(() => {
    if (loading) {
      animation.current.goToAndPlay(0);
    } else {
      animation.current.goToAndStop(0);
    }
  }, [loading]);
  return (
    <Button
      variant="basicText"
      style={{ padding: 0, width: "fit-content" }}
      gap="8px"
      leftIcon={
        <Lottie
          ref={animation}
          animationData={regenerateJson}
          style={{ width: 18, height: 21 }}
        />
      }
      onClick={() => onClick?.()}
    >
      {loading ? "Generating…" : "Regenerate Text"}
    </Button>
  );
};
