import { useEffect, useMemo, useState } from "react";
import { Modal, ModalWrapper } from "melodies-source/Modal";
import { ShareLists } from "Providers/ListProvider/types";
import { Div } from "melodies-source/utils";
import { Body2, P, Subtitle1, Subtitle2 } from "melodies-source/Text";
import City from "Components/Assets/Icons/City.png";
import Calendar from "Components/Assets/Icons/Calendar.png";
import styled, { css } from "styled-components";
import { Button } from "melodies-source/Button";
import LockSvg from "Components/Assets/Icons/lock.svg?react";
import ViewsSvg from "Components/Assets/Icons/views.svg?react";
import { useNavigate } from "react-router-dom";
import {
  setDoc,
  doc,
  getFirestore,
  collection,
  orderBy,
  query,
  CollectionReference,
  limit,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { AnalyticsEventDocument } from "Providers/AnalyticsProvider";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import { toast } from "react-toastify";

type ListModalProps = {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
} & Partial<ShareLists>;

const ListModal = ({
  isOpen,
  setIsOpen,
  client,
  order,
  campaign,
  campaignGoal,
  createdAt,
  published,
  imageUrl,
  id,
}: ListModalProps) => {
  const navigate = useNavigate();
  const [analyticsCount, setAnalyticsCount] = useState<number | null>(null);
  const firestore = useFirestore();

  const ref = useMemo(
    () =>
      collection(
        firestore,
        "ame_sharelists",
        id,
        "analytics",
      ) as CollectionReference<AnalyticsEventDocument>,
    [id],
  );

  const { data: analyticsData, status: analyticsStatus } =
    useFirestoreCollection(
      query(
        ref,
        where("type", "==", "view:list"),
        orderBy("createdAt", "desc"),
        limit(10),
      ),
    );

  // We do this filtering here because of Firestore's filtering and inequality
  // rules (limitations)o
  const filteredAnalyticsData = (
    analyticsData?.docs.filter((doc) => {
      const data = doc.data();
      return !!data.geo.city && !!data.geo.state;
    }) || []
  ).slice(0, 5);

  const lastDocId = filteredAnalyticsData?.[0]?.id;

  useEffect(() => {
    getCountFromServer(query(ref, where("type", "==", "view:list"))).then(
      (res) => setAnalyticsCount(res.data().count),
    );
  }, [id, lastDocId, ref]);

  const handlePublish = async (listId: string) => {
    if (listId) {
      await setDoc(
        doc(getFirestore(), "ame_sharelists", listId),
        {
          published: published ? false : true,
        },
        { merge: true },
      );
    }
    navigate("/admin");
  };

  const getShareUrl = (id: string) => {
    const shareUrl = new URL(id, window.location.origin);
    return shareUrl.toString();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      variant="small"
      header={`${campaign}`}
    >
      <Container>
        <BrandDetails>
          <InfoContainer hasSummary={false}>
            <LogoBox hasSummary={false} isOval={false}>
              <BrandLogo src={imageUrl} isContain={true} />
            </LogoBox>
            <BrandInfo>
              <Div alignCenter>
                <Icon src={City} />
                <P>{client}</P>
              </Div>
              <TimeContainer>
                <Icon src={Calendar} />
                <P>
                  {DateTime.fromJSDate(createdAt?.toDate()).toFormat(
                    "LLLL dd, yyyy",
                  )}
                </P>
              </TimeContainer>
              <TimeContainer>
                <Icon src={Calendar} />
                <P>{order?.length || 0} Artists</P>
              </TimeContainer>
            </BrandInfo>
          </InfoContainer>
          <Body2>{campaignGoal}</Body2>
          <Button
            variant="basicOutline"
            onClick={() => {
              navigator.clipboard.writeText(getShareUrl(id));
              toast.success("Copied to clipboard.");
            }}
            style={{ margin: "20px auto 5px", maxWidth: 500 }}
            leftIcon={<SvgCopy />}
            gap="8px"
          >{`share.max.live/${id}`}</Button>
          <Button
            variant="basicText"
            leftIcon={<LockSvg />}
            style={{ maxWidth: 500, margin: "auto" }}
            gap="8px"
          >
            Add Password
          </Button>
        </BrandDetails>
        {analyticsCount == null ? (
          <>Loading</>
        ) : (
          <AnalyticsContainer>
            <Header>
              <Subtitle1>
                {analyticsCount} Total View
                {filteredAnalyticsData.length === 1 ? "" : "s"}
              </Subtitle1>
              <ViewsSvg />
            </Header>
            <Subtitle1>Recent Views</Subtitle1>
            {analyticsStatus === "loading" ? (
              <>Loading...</>
            ) : (
              <List>
                {filteredAnalyticsData.length === 0 && (
                  <ListItem>
                    <Subtitle2>No Data</Subtitle2>
                  </ListItem>
                )}
                {filteredAnalyticsData.map((doc) => {
                  const data = doc.data();
                  return (
                    <ListItem key={doc.id}>
                      <Subtitle2>
                        {[data.geo.city, data.geo.state].join(", ")}
                      </Subtitle2>
                      <Body2>{data.createdAt.toDate().toLocaleString()}</Body2>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </AnalyticsContainer>
        )}
      </Container>
      <ButtonContainer>
        <Button
          variant="basicOutline"
          style={{ width: 120 }}
          onClick={() => navigate(`/admin/${id}`)}
        >
          Edit List
        </Button>
        <Button
          variant="basic"
          style={{ width: 120 }}
          onClick={() => {
            handlePublish(id);
            setIsOpen(false);
          }}
        >
          {published ? "Unpublish" : "Publish List"}
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
};

export default ListModal;

const StyledModal = styled(Modal)`
  ${ModalWrapper} {
    h2 {
      margin-top: -5px;
      font-weight: 800;
    }
  }
`;
const Container = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 7px;
  & > h2 {
    font-weight: 800;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
`;
const AnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f7f9;
  gap: 7px;
  & > h4 {
    margin-right: auto;
    padding-left: 10px;
  }
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  background-color: #e0e7eb;
  padding: 5px 10px;
`;
const ListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  h4,
  p {
    color: #000000;
  }
`;
const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
`;
export const BrandDetails = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  grid-template-columns: 1fr;
  opacity: 1;
  z-index: 2;
`;
export const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  width: 140px;
  border-radius: 0px;
  box-shadow: 0px 0px 15px 10px #e7e7e7;
  overflow: hidden;

  ${(props) => props.hasSummary && css``}

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-bottom: 10px;
  }
`;
export const BrandLogo = styled.img<{ isContain: boolean }>`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
`;

export const BrandInfo = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 15px;
  margin: auto 0;
`;
export const InfoContainer = styled.div<{ hasSummary: boolean }>`
  display: flex;
  margin: 15px 0;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;

  div,
  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  div {
    display: flex;
  }
`;
export const TimeContainer = styled.div`
  position: relative;
  &:hover {
    span {
      visibility: visible;
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
  }
`;
export const Icon = styled.img`
  border-radius: 13px;
  width: 26px;
  border: 1px solid #000;
  padding: 2px;
  margin-right: 12px;
  @media (max-width: 950px) {
    width: 22px;
    padding: 1px;
  }
`;
