import styled from "styled-components";
import { FlexRow } from "./Flex";
import { Body2 } from "melodies-source/Text";

type SliderInputProps = {
  label?: string;
  onChange: (value: number) => void;
  value: number;
  disabled?: boolean;
};

export const DraggableSlider = ({
  label,
  onChange,
  value,
  disabled,
}: SliderInputProps) => {
  return (
    <SliderContainer style={{ ...(disabled && { opacity: 0.4 }) }}>
      <Body2>{label}</Body2>
      <SliderInput
        onChange={(e) => (disabled ? {} : onChange(parseInt(e.target.value)))}
        type="range"
        min="1"
        max="100"
        value={value || 0}
      />
      <ValueContainer>{value || 0}</ValueContainer>
    </SliderContainer>
  );
};

const ValueContainer = styled(FlexRow)`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(196, 196, 196, 0.2);
  ${(p) => p.theme.fonts.workSans};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--secondary-color);
  width: 32px;
  height: 32px;
  user-select: none;
`;

const SliderInput = styled.input`
  appearance: none;
  width: 120px;
  height: 8px;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 4px;
  outline: none;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    background: var(--secondary-color);
    cursor: pointer;
  }
`;

const SliderContainer = styled(FlexRow)`
  gap: 8px;
  align-items: center;

  ${Body2} {
    color: var(--sidebar-section-header-color);
    font-weight: 500;
  }
`;
