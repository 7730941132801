import styled from "styled-components";
import Footer from "Components/Footer";
import { H3, H4, LinkText } from "melodies-source/Text";
import blankPeople from "assets/images/blank-people.png";
import sideImage from "assets/images/right-side-accent-shapes.png";
import { FlexColumn } from "Components/Flex";
import sideImageMobile from "assets/images/accent-shapes-mobile.png";
import logoMax from "Components/Assets/logoMax.png";
import { MaxLogo } from "./ArtistPage";

export const Page404 = () => {
  return (
    <Wrapper>
      <div style={{ width: "100%", maxWidth: 1080, margin: "0 auto" }}>
        <MaxLogo src={logoMax} alt="" id="logo" />
      </div>
      <SideAccentImage desktop={sideImage} mobile={sideImageMobile} />
      <Container>
        <MainGraphic src={blankPeople} />
        <H3>The list at this URL is not available</H3>
        <H4>
          If you need assistance, please{" "}
          <Link href="https://www.max.live/contact">Contact Us</Link>
        </H4>
      </Container>
      <Footer style={{ zIndex: 2 }} isArtistPage />
    </Wrapper>
  );
};

const Link = styled(LinkText).attrs({ as: "a" })<{ href?: string }>`
  color: var(--secondary-color);
  text-decoration: underline;
`;

const MainGraphic = styled.img`
  width: 375px;
  height: 198px;
  background-repeat: no-repeat;
  margin-top: -100px;
  margin-bottom: 22px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 280px;
    height: 148px;
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

type SideAccentImageProps = {
  desktop?: string;
  mobile?: string;
};

const SideAccentImage = styled.div<SideAccentImageProps>`
  height: 72vh;
  aspect-ratio: 0.334;
  background-image: ${(p) => `url(${p.desktop});`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.mobile} {
    aspect-ratio: 0.617;
    background-image: ${(p) => `url(${p.mobile});`};
    z-index: 0;
    top: 0;
    right: 0;
    transform: none;
    height: auto;
    width: 75px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.desktop} {
    ${MaxLogo} {
      margin: 50px auto 0 50px;
    }
  }
`;

const Container = styled(FlexColumn)`
  background-color: var(--hover-background-color);
  align-items: center;
  width: 100%;
  height: 276px;
  flex-grow: 0;
  z-index: 0;
  ${H3} {
    color: var(--text-color);
    font-family: var(--max-font-secondary);
    font-size: 30px;
    font-weight: 900;
    line-height: 34px;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: transparent;
    z-index: 2;

    ${H3} {
      font-size: 20px;
      line-height: 28px;
    }

    ${H4} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
