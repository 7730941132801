import { AddArtistActions } from "Components/AddArtistActions";
import { H1, H2, H3, Subtitle1 } from "melodies-source/Text";
import styled, { css } from "styled-components";
import Client from "Components/Assets/Icons/client.svg?react";
import Calendar from "Components/Assets/Icons/calendar.svg?react";
import logo from "Components/Assets/Logo.png";
import { Button } from "melodies-source/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, useUser } from "reactfire";
import { DateTime } from "luxon";
import { SearchArtistModal } from "Components/SearchArtistModal";
import { useListContext } from "Hooks/useListContext";
import { Textarea } from "melodies-source/Textarea";
import { toast } from "react-toastify";
import { AppLoading } from "melodies-source/AppLoading";
import { EditListModal } from "Components/EditListModal";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { SvgLeft } from "melodies-source/Svgs/Left";
import { SvgPencil } from "assets/svg";
import { Datepicker } from "Components/DatePicker";
import { RegenerateButton } from "Components/RegenerateButton";
import { ArtistCard } from "./ArtistCard";
import { DndContainer } from "Components/Sortable";
import { DragEndEvent, DragOverlay } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { setDoc, doc, Timestamp, updateDoc } from "firebase/firestore";

export const AddArtist = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [loadingAI, setLoadingAI] = useState<boolean>(false);
  const { listId } = useParams();
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const isMobile = useMediaQuery();
  const [token, setToken] = useState<string>();
  const { data: user } = useUser();
  const firestore = useFirestore();
  const {
    artists: artistListData,
    list: listData,
    setList,
    save,
    loading,
  } = useListContext();

  useEffect(() => {
    const getToken = async () => {
      const token = await user.getIdToken();
      setToken(token);
    };
    getToken();
  }, []);

  const handlePublish = async () => {
    if (listId) {
      await setDoc(
        doc(firestore, "ame_sharelists", listId),
        {
          published: true,
        },
        { merge: true },
      );
    }
    navigate("/admin");
  };

  const handleRegenerate = async () => {
    setLoadingAI(true);
    try {
      const data = await fetch(`/api/campaign/overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          client: listData.client,
          goal: listData.campaignGoal,
          target: listData.targetPersona,
          type: listData.type ?? "Sample",
          artistCount: listData.artistCount,
        }),
      });
      const updatedOverview = await data.json();
      if (updatedOverview) {
        setList("overview", updatedOverview.data.overview);
        if (updatedOverview.data.overview !== listData?.overview) {
          setIsDirty(true);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAI(false);
    }
  };

  const handleOverviewChange = async (overview: string) => {
    setIsDirty(true);
    setList("overview", overview);
  };

  const handleDateChange = async (date: DateTime) => {
    if (date) {
      try {
        await setDoc(
          doc(firestore, "ame_sharelists", listId),
          {
            displayDate: Timestamp.fromDate(date.toJSDate()),
          },
          { merge: true },
        );
      } catch (err) {
        console.log(err);
      } finally {
        toast.success("List date has been updated.");
      }
    }
  };

  const handleEditList = () => {
    setIsEditOpen(true);
  };

  const filterArtists = async (id: number) => {
    const order = listData?.order?.filter((artistId) => {
      return artistId !== id;
    });
    setList("order", order);
    toast.success("List has been saved successfully.");
    try {
      await setDoc(
        doc(firestore, "ame_sharelists", listId),
        {
          order,
        },
        { merge: true },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveDraft = async () => {
    try {
      await setDoc(
        doc(firestore, "ame_sharelists", listId),
        {
          overview: listData?.overview,
        },
        { merge: true },
      );
    } catch (err) {
      console.log(err);
    } finally {
      toast.success("List has been saved successfully.");
      setIsDirty(false);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const f = listData?.order.findIndex((id) => id === active.id);
      const t = listData?.order.findIndex((id) => id === over.id);
      const newOrder = arrayMove(listData?.order, f, t);
      setList("order", newOrder);

      updateDoc(doc(firestore, "ame_sharelists", listId), {
        order: newOrder,
      })
        .then(() => toast.success("Order updated.", { autoClose: 1000 }))
        .catch((error) => {
          toast.error("There was an issue updating the list order.");
          console.error(error);
        });
    }
  };

  if (loading) return <AppLoading />;

  return (
    <ListWrapper>
      <Button
        leftIcon={<SvgLeft />}
        gap="8px"
        onClick={() => navigate("../")}
        variant="basicText"
      >
        Back to Admin
      </Button>
      {isMobile ? (
        <EditContainer>
          <H1>{listData?.campaign}</H1>
          <Button
            variant="basicText"
            leftIcon={<SvgPencil style={{ width: 15 }} />}
            gap="8px"
            onClick={() => handleEditList()}
          >
            Edit List Details
          </Button>
        </EditContainer>
      ) : (
        <AddArtistActions
          campaignName={listData?.campaign || ""}
          handlePublish={handlePublish}
          handleSaveArtists={save}
          editList={handleEditList}
        />
      )}
      <BrandDetails>
        <InfoContainer hasSummary={false}>
          <LogoBox hasSummary={false} isOval={false}>
            <BrandLogo src={listData?.imageUrl ?? logo} isContain={true} />
          </LogoBox>
          <BrandInfo>
            <Details>
              <Client />
              <H3>{listData?.client}</H3>
            </Details>
            <Details>
              <Calendar />
              <H3 style={{ width: "100%", whiteSpace: "nowrap" }}>
                {DateTime.fromJSDate(
                  (listData?.displayDate || listData?.createdAt)?.toDate(),
                ).toFormat("LLL dd, yyyy")}
              </H3>
              <Datepicker
                type="dateOnly"
                renderInput={
                  <Button
                    leftIcon={<SvgPencil />}
                    variant="basicText"
                    style={{ padding: 0, width: "auto", gap: 4 }}
                  >
                    Edit
                  </Button>
                }
                onChange={(v) => handleDateChange(v)}
                value={DateTime.fromJSDate(
                  (listData?.displayDate || listData?.createdAt)?.toDate(),
                )}
              />
            </Details>
          </BrandInfo>
        </InfoContainer>
        <AIOverview>
          <OverviewHeader>
            <Subtitle1>Campaign Overview</Subtitle1>
            <RegenerateButton
              loading={loadingAI}
              onClick={() => handleRegenerate()}
            />
          </OverviewHeader>
          <Description>
            <Textarea
              value={listData?.overview}
              onChange={(v) => {
                handleOverviewChange(v);
              }}
            />
          </Description>
          {isDirty && (
            <Button
              variant="basicOutline"
              w={200}
              onClick={() => {
                saveDraft();
              }}
            >
              Save Overview
            </Button>
          )}
        </AIOverview>
      </BrandDetails>

      <ModuleWrapper>
        <ModuleContainer>
          <RadioContainer>
            <H2>{listData?.type || "Sample"} Artists</H2>
          </RadioContainer>
          <DndContainer
            items={artistListData}
            onDragEnd={handleDragEnd}
            variant="column"
            options={{ gap: 10 }}
          >
            {({ activeId }) => (
              <>
                {artistListData?.map((artist) => (
                  <ArtistCard
                    key={artist.id}
                    {...{ listId, artist, filterArtists }}
                  />
                ))}
                <DragOverlay>
                  {activeId && (
                    <ArtistCard
                      key={activeId}
                      {...{
                        listId,
                        artist: artistListData.find((a) => a.id === activeId),
                        filterArtists,
                      }}
                    />
                  )}
                </DragOverlay>
              </>
            )}
          </DndContainer>
          <Button
            variant="basicOutline"
            w={200}
            onClick={() => setIsOpen(true)}
          >
            Add Artist(s)
          </Button>
        </ModuleContainer>
      </ModuleWrapper>
      {isMobile && (
        <AddArtistActions
          campaignName={listData?.campaign || ""}
          handlePublish={handlePublish}
          handleSaveArtists={save}
          editList={handleEditList}
        />
      )}
      {isOpen && (
        <SearchArtistModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
      <EditListModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  & > button {
    font-weight: 700;
    align-self: flex-start;
    margin-top: 32px;
    margin-bottom: 22px;
    width: auto;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100%;

    & > button {
      margin-top: 20px;
      margin-bottom: 8px;
      margin-left: 20px;
    }
  }
`;

const ModuleWrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const ModuleContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 10px;
  background-color: #f6f6f6;
  box-shadow: 0px 0px 20px 0px #c5c5c5;
  & > button {
    margin-top: 15px;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    padding: 10px 20px 20px;
    & > button {
      width: 100%;
    }
  }
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  & > h2 {
    font-weight: 800;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const OverviewHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -10px;
`;

const BrandDetails = styled.div`
  display: flex;
  width: 100%;
  margin: 15px 0;
  gap: 25px;
  align-items: flex-start;

  ${(p) => p.theme.mediaQueries.mobile} {
    padding: 15px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    p {
      font-size: 13px;
    }
  }
`;

const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  width: 160px;
  border-radius: 0px;
  box-shadow: 0px 0px 15px 10px #e7e7e7;
  overflow: hidden;

  ${(props) =>
    props.hasSummary &&
    css`
      min-width: 150px;
      max-width: 150px;
    `}

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-bottom: 10px;
  }
`;

const BrandLogo = styled.img<{ isContain: boolean }>`
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  border: 0.5px solid #dcdcdc;
  margin-right: 25px;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-right: 10px;
  }
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 20px;
  margin: auto 0;
  @media (max-width: 950px) {
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
  }
  h3 {
    font-weight: 600;
  }
`;

const InfoContainer = styled.div<{ hasSummary: boolean }>`
  display: flex;
  margin: 15px 0;
  gap: 25px;
  align-items: flex-start;

  @media (max-width: 950px) {
    align-items: flex-start;
    gap: 15px;
    p {
      font-size: 13px;
    }
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
  &:hover {
    span {
      visibility: visible;
    }
  }
`;

const AIOverview = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column !important;
  gap: 10px;
  margin-left: auto;
  margin-top: -10px;
  position: relative;
  & > button {
    align-self: flex-end;
    height: 37px;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

const Description = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 135px;
  border-radius: 6px;
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
  & > div {
    width: 100%;
  }
  textarea {
    margin-top: -10px;
    margin-left: -5px;
    border: none;
    :focus {
      box-shadow: none;
    }
  }
`;

const EditContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  button {
    width: auto;
  }

  & > h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    padding: 15px 20px;
  }
`;
