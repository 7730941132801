import { FlexColumn, FlexRow } from "Components/Flex";
import { CircleMap, MapPoint } from "Components/Maps";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { SvgFitToScreen } from "assets";
import { useArtist } from "Providers/ArtistProvider";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { useMap } from "@vis.gl/react-google-maps";
import { MapContainer, MapPositionButton } from "../common";
import { getBounds } from "Components/Maps/components/helpers";
import { PhonePreview } from "Components";

type ListenershipMapProps = {
  points: MapPoint[];
  bounds?: google.maps.LatLngBounds;
  variant?: "desktop" | "mobile";
  onEdit?: VoidFunction;
  onClose?: VoidFunction;
};

const mapIds = {
  desktop: "d7601a4d94346a6d",
  mobile: "ebf1be7d704a4dce",
};

export const ListenershipMap = ({
  points,
  bounds,
  variant = "desktop",
  onEdit,
  onClose,
}: ListenershipMapProps) => {
  const [disableMapControl, setDisableMapControl] = useState<boolean>(true);
  const { overrides, setOverride } = useArtist();
  const mapId = mapIds[variant];
  const map = useMap(mapId);

  const mapOverrides = overrides?.maps?.listenership?.desktop;

  const handleSaveMapPosition = () => {
    if (!map) {
      return;
    }
    setOverride(`maps.listenership.${variant}`, {
      bounds: map.getBounds()?.toJSON(),
      zoom: map.getZoom(),
    });
    setDisableMapControl(true);
    onClose();
  };

  const mapSettings = useMemo(() => {
    return {
      ...mapOverrides,
      bounds:
        mapOverrides?.bounds ||
        bounds?.toJSON() ||
        getBounds(points, true)?.toJSON(),
    };
  }, [mapOverrides, points, bounds]);

  const renderMap = mapSettings?.bounds && (
    <CircleMap
      id={mapId}
      points={points}
      bounds={mapSettings?.bounds}
      disableControl={disableMapControl}
    />
  );

  return (
    <Wrapper>
      <Preview>
        {variant === "desktop" ? (
          <MapContainer
            isActive={!disableMapControl}
            style={{ marginBottom: 16 }}
          >
            {renderMap}
          </MapContainer>
        ) : (
          <PhonePreview title="Markets" isActive={!disableMapControl}>
            {renderMap}
          </PhonePreview>
        )}

        {disableMapControl ? (
          <MapPositionButton
            onClick={() => {
              setDisableMapControl(false);
              onEdit();
            }}
          >
            <SvgFitToScreen />
            Edit Map Settings
          </MapPositionButton>
        ) : (
          <FlexRow gap="12px" style={{ alignSelf: "flex-end" }}>
            <MapPositionButton
              isDestructive
              onClick={() => {
                onClose();
                setDisableMapControl(true);
                map?.fitBounds(mapSettings?.bounds, 0);
              }}
            >
              Cancel
            </MapPositionButton>
            <MapPositionButton onClick={handleSaveMapPosition}>
              <SvgCheck />
              Done
            </MapPositionButton>
          </FlexRow>
        )}
      </Preview>
    </Wrapper>
  );
};

const Preview = styled(FlexColumn)`
  align-items: center;
  gap: 32px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
