import styled from "styled-components";

interface FlexBaseProps {
  flex?: string;
  column?: boolean;
  row?: boolean;
  gap?: string;

  smFlex?: string;
  smColumn?: boolean;
  smColumnReverse?: boolean;
  smRow?: boolean;
  smGap?: string;

  xEnd?: boolean;
}

interface FlexDirectionProps extends FlexBaseProps {
  xStart?: boolean;
  yStart?: boolean;
  xCenter?: boolean;
  yCenter?: boolean;
  xSpaceAround?: boolean;
  xSpaceBetween?: boolean;
  xEnd?: boolean;
  yEnd?: boolean;
}

export const Flex = styled.div<FlexBaseProps>`
  box-sizing: border-box;
  display: flex;
  ${(props) => props.column && "flex-direction: column;"};
  ${(props) => props.row && "flex-direction: row;"};
  ${(props) => props.flex && `flex: ${props.flex};`};
  ${(props) => props.gap && `gap: ${props.gap};`};
  ${(props) => props.xEnd && "justify-content: flex-end;"};

  ${(props) => props.theme.mediaQueries.mobile} {
    ${(props) => props.smColumn && "flex-direction: column;"};
    ${(props) => props.smColumnReverse && "flex-direction: column-reverse;"};
    ${(props) => props.smRow && "flex-direction: row;"};
    ${(props) => props.smFlex && `flex: ${props.smFlex};`};
    ${(props) => props.smGap && `gap: ${props.smGap};`};
  }
`;

export const FlexColumn = styled.div<FlexDirectionProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${(props) => props.gap && `gap: ${props.gap};`};
  ${(props) => props.xStart && "align-items: flex-start;"};
  ${(props) => props.xCenter && "align-items: center;"};
  ${(props) => props.xEnd && "align-items: flex-end;"};
  ${(props) => props.yStart && "justify-content: flex-start;"};
  ${(props) => props.yCenter && "justify-content: center;"};
  ${(props) => props.yEnd && "justify-content: flex-end;"};
  ${(props) => props.flex && `flex: ${props.flex}`};
`;

export const FlexRow = styled.div<FlexDirectionProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  ${(props) => props.gap && `gap: ${props.gap};`};
  ${(props) => props.xStart && "justify-content: flex-start;"};
  ${(props) => props.xCenter && "justify-content: center;"};
  ${(props) => props.xEnd && "justify-content: flex-end;"};
  ${(props) => props.xSpaceAround && "justify-content: space-around;"};
  ${(props) => props.xSpaceBetween && "justify-content: space-between;"};
  ${(props) => props.yStart && "align-items: flex-start;"};
  ${(props) => props.yCenter && "align-items: center;"};
  ${(props) => props.yEnd && "align-items: flex-end;"};
  ${(props) => props.flex && `flex: ${props.flex};`};

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${(props) => props.smColumn && "flex-direction: column"};
    ${(props) => props.smGap && `gap: ${props.smGap};`};
  }
`;
