import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

export const getDateTime = (date: unknown) => {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Timestamp) {
    return DateTime.fromJSDate(date.toDate());
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === "number") {
    return DateTime.fromMillis(date);
  }

  if (typeof date === "string" && !Number.isNaN(new Date(date).getTime())) {
    return DateTime.fromJSDate(new Date(date));
  }

  throw new Error("#getDateTime: Invalid input");
};

export const getTimestamp = (date: unknown) => {
  if (date instanceof DateTime) {
    return Timestamp.fromDate(date.toJSDate());
  }

  if (date instanceof Timestamp) {
    return date;
  }

  if (date instanceof Date) {
    return Timestamp.fromDate(date);
  }

  if (typeof date === "number") {
    return Timestamp.fromMillis(date);
  }

  if (typeof date === "string" && !Number.isNaN(new Date(date).getTime())) {
    return Timestamp.fromDate(new Date(date));
  }

  throw new Error("#getTimestamp: Invalid input");
};

export const isSameISODate = (first: unknown, second: unknown) => {
  return getDateTime(first).toISODate() === getDateTime(second).toISODate();
};

export const isDateInFuture = (date?: unknown) => {
  if (!date) {
    return false;
  }

  return DateTime.now() < getDateTime(date);
};
