import {
  doc,
  FieldValue,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { GeoContext } from "Providers/GeoProvider";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "reactfire";

interface ListViewEvent {
  type: "view:list";
}

interface ArtistViewEvent {
  type: "view:artist";
  artistId: number;
}

export type AnalyticsEvent = ListViewEvent | ArtistViewEvent;

export type AnalyticsEventDocument<T = Timestamp> = AnalyticsEvent & {
  uid: string;
  createdAt: T;
  geo: {
    city?: string;
    state?: string;
  };
};

interface Task {
  listId: string;
  event: AnalyticsEvent;
}

interface Queue {
  loading: boolean;
  tasks: Task[];
}

const Context = createContext<{
  track: (listId: string, event: AnalyticsEvent) => void;
}>({
  track: () => {},
});

export const useAnalytics = () => useContext(Context);

export const AnalyticsProvider = ({ children }: { children?: ReactNode }) => {
  const { data: user } = useUser();
  const geo = useContext(GeoContext);
  const [queue, setQueue] = useState<Queue>({ loading: false, tasks: [] });
  const uid = user?.uid;

  const track = useCallback((listId: string, event: AnalyticsEvent) => {
    setQueue((prev) => ({
      ...prev,
      tasks: [...prev.tasks, { listId, event }],
    }));
  }, []);

  useEffect(() => {
    if (!uid) return;
    if (!geo.city || !geo.state) return;
    if (queue.tasks.length === 0) return;
    if (queue.loading) return;

    const perform = async () => {
      const task = queue.tasks[0];
      const window = Math.floor(Date.now() / (1000 * 60 * 5));
      const docId = `${task.event.type}#${uid}#${window}`;
      const db = getFirestore();
      const ref = doc(db, `/ame_sharelists/${task.listId}/analytics/${docId}`);

      setQueue((prev) => ({
        loading: true,
        tasks: prev.tasks.slice(1),
      }));

      const data: AnalyticsEventDocument<FieldValue> = {
        ...task.event,
        uid: uid,
        createdAt: serverTimestamp(),
        geo: {
          state: null,
          city: null,
          ...Object.fromEntries(
            Object.entries(geo).filter(([k, v]) => v !== undefined),
          ),
        },
      };

      console.log("Analytics Event: ", ref.path, data);

      if (!import.meta.env.DEV) {
        // TODO: Check if doc already exists and ignore
        await setDoc(ref, data);
      }
    };

    perform()
      .catch((error) => {
        console.log("Analytics Error: ", error);
      })
      .finally(() => {
        setQueue((prev) => ({ ...prev, loading: false }));
      });
  }, [uid, geo, queue]);

  return <Context.Provider value={{ track }}>{children}</Context.Provider>;
};
