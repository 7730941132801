import { Modal, ModalWrapper } from "melodies-source/Modal";
import { Body1, Body2, H2, H4 } from "melodies-source/Text";
import styled, { css } from "styled-components";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";

export const SpotifySearchModal = ({
  isOpen,
  setIsOpen,
  setShowAddSong,
  artist,
  setOverride,
  tracks,
  videos,
  platformSearch = "",
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      variant="large"
      header={`Add Track from ${platformSearch}`}
    >
      <Container>
        <BrandDetails>
          <InfoContainer hasSummary={false}>
            <LogoBox hasSummary={false} isOval={false}>
              <ArtistImage src={artist?.image} isContain={false} />
            </LogoBox>
            <BrandInfo>
              <ArtistDetails>
                <H2>{artist?.name ?? artist?.title}</H2>
                <Body1>
                  {platformSearch === "Youtube" &&
                    `${artist?.services?.youtube?.subscribers || 0} Subscribers`}
                  {platformSearch === "Spotify" &&
                    (!!artist?.services?.spotify?.listeners
                      ? `${artist?.services?.spotify?.listeners || 0} Monthly Listeners`
                      : `${artist?.services?.spotify?.followers || 0} Monthly Followers`)}
                </Body1>
              </ArtistDetails>
              <InputWrapper>
                <TextInput
                  label={`${platformSearch} Share Link`}
                  placeholder="Paste URL Here..."
                  value={
                    artist?.track?.type === "spotify-artist"
                      ? artist?.track.id
                      : ""
                  }
                  onChange={(v) =>
                    setOverride("track", { type: "spotify-artist", id: v })
                  }
                />
                <Button variant="basicOutline" w={110}>
                  + Add
                </Button>
              </InputWrapper>
            </BrandInfo>
          </InfoContainer>
        </BrandDetails>
        <H2>Popular on {platformSearch}</H2>
        <List>
          {platformSearch === "Youtube" &&
            (videos || [])?.map((video) => {
              return (
                <ListItem>
                  <VideoThumb src={video?.image ?? artist?.image} />
                  <H4>{video?.title}</H4>
                  <Body2>{video?.duration}</Body2>
                  <Body2>{video?.viewCount} views</Body2>
                  <Button
                    variant="basicOutline"
                    w={110}
                    onClick={() => {
                      setOverride("track", { type: "youtube", id: video.id });
                      setIsOpen("");
                      setShowAddSong(false);
                    }}
                  >
                    + Add
                  </Button>
                </ListItem>
              );
            })}
          {platformSearch === "Spotify" &&
            (tracks || [])?.map((track) => {
              return (
                <ListItem key={track?.name}>
                  <ArtistImage
                    src={track?.album?.images[1]?.url}
                    isContain={false}
                  />
                  <H4>{track?.name}</H4>
                  <Body2>{track?.duration_ms}</Body2>
                  <Body2>{track?.popularity} Popularity</Body2>
                  <Button
                    variant="basicOutline"
                    w={110}
                    onClick={() => {
                      setOverride("track", { type: "spotify", id: track.id });
                      setIsOpen("");
                      setShowAddSong(false);
                    }}
                  >
                    + Add
                  </Button>
                </ListItem>
              );
            })}
        </List>
      </Container>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${ModalWrapper} {
    h2 {
      margin-top: -5px;
      font-weight: 800;
    }
  }
`;
const Container = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 7px;
  & > h2 {
    font-weight: 800;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  & > h2 {
    font-weight: 800;
  }
  input {
    width: 100%;
  }
  button {
    width: 135px;
  }
`;
const ArtistDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 7px;
`;
const ListItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px 0px;
  border-top: 1px solid #dedede;
  align-items: center;
  h4 {
    margin: auto 100px auto 0px;
  }
  img {
    width: 60px;
  }
`;
const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
`;
export const BrandDetails = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  grid-template-columns: 1fr;
  opacity: 1;
  z-index: 2;
`;
export const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  max-width: 200px;
  border-radius: 0px;
  box-shadow: 0px 0px 15px 10px #e7e7e7;
  overflow: hidden;

  ${(props) => props.hasSummary && css``}

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-bottom: 10px;
  }
`;
export const ArtistImage = styled.img<{ isContain: boolean }>`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
`;
const VideoThumb = styled.img`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
`;

export const BrandInfo = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 15px;
  justify-content: space-between;
  width: 80%;
  height: 100%;
`;
export const InfoContainer = styled.div<{ hasSummary: boolean }>`
  display: flex;
  margin: 15px 0;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;

  div,
  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  & > div {
    display: flex;
  }
`;
export const TimeContainer = styled.div`
  position: relative;
  &:hover {
    span {
      visibility: visible;
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
  }
`;
export const Icon = styled.img`
  border-radius: 13px;
  width: 26px;
  border: 1px solid #000;
  padding: 2px;
  margin-right: 12px;
  @media (max-width: 950px) {
    width: 22px;
    padding: 1px;
  }
`;
