import { Map, useMap } from "@vis.gl/react-google-maps";
import { MapPoint } from "./types";
import { useCallback, useState } from "react";
import { DEFAULT_MAP_STYLE_ID } from ".";
import { Circle } from "./components/Circle";
import { useMapViewport } from "./hooks/use-map-viewport";

type CircleMapProps = {
  id: string;
  mapId?: string;
  points?: MapPoint[];
  bounds?: google.maps.LatLngBoundsLiteral;
  disableControl?: boolean;
  zoom?: number;
};

export const CircleMap = ({
  id,
  mapId,
  points,
  bounds,
  disableControl,
  zoom: zoomOverride,
}: CircleMapProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const map = useMap(id);
  const { zoom } = useMapViewport({ map });
  const zoomOffset = (zoomOverride || zoom || 3) * 0.1;

  const renderCircles = useCallback(() => {
    return points?.map((point, index) => (
      <Circle
        key={`map-circle-${point.id || index}`}
        radius={point.radius / zoomOffset}
        center={point.position}
        strokeWeight={0}
        fillColor="#0240FB"
        fillOpacity={parseFloat(point.density.toFixed(2))}
      />
    ));
  }, [points, zoomOffset]);

  return (
    <Map
      id={id}
      clickableIcons={false}
      defaultBounds={bounds}
      disableDefaultUI={disableControl}
      fullscreenControl={false}
      gestureHandling={disableControl ? "none" : "auto"}
      mapId={mapId || DEFAULT_MAP_STYLE_ID}
      mapTypeControl={false}
      maxZoom={7}
      minZoom={3}
      streetViewControl={false}
      zoomControl={!disableControl}
      onTilesLoaded={() => {
        if (!isLoaded) {
          setIsLoaded(true);
          map?.fitBounds(bounds, 0);
        }
      }}
    >
      {renderCircles()}
    </Map>
  );
};
