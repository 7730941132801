import { Datepicker } from "Components/DatePicker";
import { FlexColumn } from "Components/Flex";
import {
  PlacesAutocomplete,
  PlacesAutocompleteResult,
} from "Components/PlacesAutocomplete";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { ListItem } from "./ListItem";
import { PlaceDetail } from "Components/Maps";
import { createConcertFromPlace } from "./createConcertFromPlace";
import { useGenerateText } from "Hooks";
import { useArtist } from "Providers/ArtistProvider";
import { toast } from "react-toastify";
import { getPlacePhoto } from "Components/Maps/components/getPlacePhoto";

export type ConcertDetails = PlaceDetail;

interface CreateShowProps {
  controls: ReactElement;
  onCreate: (v: PlaceDetail) => void;
  onCancel: VoidFunction;
}

export const SingleForm = ({
  controls,
  onCreate,
  onCancel,
}: CreateShowProps) => {
  const [details, setDetails] = useState<PlaceDetail | null>(null);
  const { getVenueOverview } = useGenerateText();
  const { artist } = useArtist();

  const handleVenueSelect = async (selected: PlacesAutocompleteResult) => {
    const concert = createConcertFromPlace(
      selected,
      details?.date,
      details?.id,
    );

    const response = await getVenueOverview({
      artistName: artist?.title || artist?.name,
      venues: {
        [concert.id]: {
          name: concert.title,
          address: concert.address,
        },
      },
    });

    const result = response?.[concert.id];

    if (!result) {
      toast.error("Failed to get venue overview.");
    }

    if (!details?.imageUrl) {
      concert.imageUrl = await getPlacePhoto(selected.result.place_id);
    }

    setDetails((d) => ({
      ...d,
      ...concert,
      description: result.description || d.description || "",
      capacity: result.capacity || 0,
    }));
  };

  const handleDate = (date: DateTime) => {
    setDetails((d) => ({ ...d, date }));
  };

  const handleCreate = () => {
    if (details && details.date.isValid) {
      onCreate(details);
    }
  };

  return (
    <>
      <Grid>
        <Column>{controls}</Column>
        <Column>
          <FlexColumn gap="16px">
            {details?.addressType ? (
              <FlexColumn>
                <Label style={{ marginBottom: 4 }}>Location</Label>
                <ListItem
                  label={
                    details.addressType === "establishment"
                      ? details.title
                      : details.address
                  }
                  caption={
                    details.addressType === "establishment"
                      ? details.address
                      : undefined
                  }
                  onRemove={() => {
                    if (details.date) {
                      setDetails({
                        date: details.date,
                      } as PlaceDetail);
                    } else {
                      setDetails(null);
                    }
                  }}
                />
              </FlexColumn>
            ) : (
              <PlacesAutocomplete
                onChange={handleVenueSelect}
                label="Location"
                placeholder="Type a venue or address..."
              />
            )}
            <Datepicker
              label="Date and Time"
              onChange={handleDate}
              value={details?.date}
              placeholder="Pick a date..."
              minDate={DateTime.now()}
              timeZone={details?.timeZone}
              disabled={!details?.address}
            />
            {details?.address && details?.addressType === "address" && (
              <TextInput
                label="Venue Name"
                placeholder="Enter venue name..."
                value={details.title}
                onChange={(v) => setDetails((d) => ({ ...d, title: v }))}
              />
            )}
          </FlexColumn>
        </Column>
        <SingleFormFooter>
          <Button onClick={onCancel} variant="basicOutline">
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            isDisabled={!details?.title || !details?.address || !details?.date}
            variant="basic"
          >
            Add
          </Button>
        </SingleFormFooter>
      </Grid>
    </>
  );
};

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  min-height: 0;

  ${(p) => p.theme.mediaQueries.mobile} {
    gap: 20px;
  }
`;

export const SingleFormFooter = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    width: auto;
    min-width: 120px;
  }

  ${(p) => p.theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
  }
`;

const Column = styled(FlexColumn)`
  min-height: 0;
`;
