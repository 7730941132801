import { SvgGear } from "assets";
import { Flex, FlexColumn, FlexRow } from "Components/Flex";
import { SpinnerRing } from "Components/LoadingScreen/SpinnerRing";
import { collection, doc, getFirestore } from "firebase/firestore";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { useConcertsAi } from "Hooks/useConcertsAi";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Checkbox } from "melodies-source/Selectable";
import { SvgHelpAlt } from "melodies-source/Svgs/HelpAlt";
import { Body1, Body2, Caption, H3, H4 } from "melodies-source/Text";
import { HTMLTextearea } from "melodies-source/Textarea";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { pluralize } from "utils/pluralize";
import { REQUIRED_MSG } from "utils/yupStrictPassword";
import * as yup from "yup";
import { useIsMobile } from "melodies-source/utils";
import { PlaceDetail } from "Components/Maps";
import { ConcertCard } from "./ConcertCard";

enum FormStatus {
  NoShowsFound,
}

const PROMPT_SCHEMA = yup.object().shape({
  prompt: yup.string().trim().required(REQUIRED_MSG),
});

const SELECTION_SCHEMA = yup.object().shape({
  selection: yup.array().min(1, "Shows are required"),
});

export interface ConcertsBulkFormProps {
  upcoming?: { date: DateTime }[];
  onCancel: () => void;
  onSubmit: (concerts: PlaceDetail[]) => void;
  controls?: ReactElement;
}

export const ConcertsBulkForm = ({
  upcoming,
  onCancel,
  onSubmit,
  controls,
}: ConcertsBulkFormProps) => {
  const [options, setOptions] = useState<PlaceDetail[]>([]);
  const { analyze } = useConcertsAi();
  const isMobile = useIsMobile();

  const findOverlappingShow = (option: PlaceDetail) =>
    upcoming?.find((show) => show.date.toISODate() === option.date.toISODate());

  const overlapped = options.filter((o) => findOverlappingShow(o));
  const Title = isMobile ? H4 : H3;
  const Text = isMobile ? Body2 : Body1;

  return options.length ? (
    <Formik<{ selection: (string | number)[] }>
      key="options"
      validationSchema={SELECTION_SCHEMA}
      initialValues={{
        selection: options
          .filter((o) => !findOverlappingShow(o))
          .map((o) => o.id),
      }}
      onSubmit={(values) => {
        onSubmit(options.filter((o) => values.selection.includes(o.id)));
      }}
    >
      {({ values, touched, errors }) => (
        <StyledForm>
          <Container>
            <Column>
              <Text>
                We found{" "}
                {pluralize(options.length - overlapped.length, "new show")}
                {!!overlapped.length && (
                  <>
                    , and {pluralize(overlapped.length, "show")} that already
                    exist{overlapped.length === 1 ? "s" : ""} on this account
                  </>
                )}
                . Please review for accuracy before importing new shows.
              </Text>
            </Column>
            <ShowsColumn>
              <FieldArray name="selection">
                {({ push, remove }) => (
                  <List>
                    {options.map((option) => {
                      const overlappingShow = findOverlappingShow(option);

                      return (
                        <Flex
                          key={option.id}
                          gap="16px"
                          style={{
                            minWidth: 0,
                          }}
                        >
                          <FlexRow yCenter style={{ width: 16 }}>
                            {!overlappingShow && (
                              <Checkbox
                                value={values.selection.includes(option.id)}
                                onChange={(checked) =>
                                  checked
                                    ? push(option.id)
                                    : remove(
                                        values.selection.indexOf(option.id),
                                      )
                                }
                              />
                            )}
                          </FlexRow>
                          <ConcertCard
                            concert={option}
                            isNew={!overlappingShow}
                            isSelected={values.selection.includes(option.id)}
                            disableTruncate
                          />
                        </Flex>
                      );
                    })}
                  </List>
                )}
              </FieldArray>
              {touched.selection && errors.selection && (
                <Caption hasError style={{ marginTop: "4px" }}>
                  {errors.selection.toString()}
                </Caption>
              )}
            </ShowsColumn>
          </Container>
          <Footer>
            <Button
              type="button"
              variant="tertiary"
              onClick={() => setOptions([])}
            >
              Back
            </Button>
            <Button type="submit" disabled={!values.selection.length}>
              Import{" "}
              {!values.selection.length
                ? "Shows"
                : pluralize(values.selection.length, "Show")}
            </Button>
          </Footer>
        </StyledForm>
      )}
    </Formik>
  ) : (
    <Formik<{ prompt: string }>
      key="prompt"
      initialValues={{ prompt: "" }}
      validationSchema={PROMPT_SCHEMA}
      onSubmit={async (values, form) => {
        const results = await analyze(values.prompt);
        if (results.length) {
          setOptions(results);
        } else {
          form.setStatus(FormStatus.NoShowsFound);
        }
      }}
    >
      {({ status, handleReset, isSubmitting, values }) => (
        <StyledForm>
          <Container>
            <Column>
              {isSubmitting ? (
                <>
                  <Title>Hang tight!</Title>
                  <Text style={{ marginTop: 20 }}>
                    To save you time, we're leveraging AI behind the scenes to
                    swiftly locate, format, and organize your upcoming shows.
                    Your patience is greatly appreciated.
                  </Text>
                </>
              ) : status === FormStatus.NoShowsFound ? (
                <Text>
                  We could not compile a show list based on the information
                  provided. Please check the text that was copied and try again.
                  <br />
                  <br />
                  If available, include the venue, city, date, and time. Please
                  use official venue names for an accurate location.
                </Text>
              ) : (
                controls || (
                  <Text>
                    This tool will intelligently add one or multiple shows to
                    your page from text that has been pasted from another
                    source.
                  </Text>
                )
              )}
            </Column>
            <Column>
              {isSubmitting ? (
                <SpinnerRing
                  icon={<SvgGear />}
                  message="Analyzing Show Information..."
                />
              ) : status === FormStatus.NoShowsFound ? (
                <FlexColumn xCenter>
                  <SvgHelpAlt width={63} height={63} />
                  <Title>No Shows Found</Title>
                </FlexColumn>
              ) : (
                <>
                  <Field name="prompt">
                    {({ field, meta }: FieldProps) => {
                      const props = {
                        placeholder:
                          "Drop your show details here. SET will seamlessly handle the processing and styling for optimal presentation.",
                        hasError: meta.touched && !!meta.error,
                        style: { flexGrow: 1 },
                      };

                      return (
                        <>
                          <HTMLTextearea {...props} {...field} />
                          {props.hasError && (
                            <Caption hasError style={{ marginTop: "4px" }}>
                              {meta.error}
                            </Caption>
                          )}
                        </>
                      );
                    }}
                  </Field>
                  {!isMobile && (
                    <Body2 style={{ marginTop: "12px" }}>
                      If available, include the venue, city, date, and time
                      (links and/or buttons will be ignored if they are copied).
                      Please use official venue names for an accurate location.
                      Processing may take time.
                    </Body2>
                  )}
                </>
              )}
            </Column>
          </Container>
          {!isSubmitting && (
            <Footer>
              <Button type="button" variant="basicOutline" onClick={onCancel}>
                Cancel
              </Button>
              {status === FormStatus.NoShowsFound ? (
                <Button type="button" variant="basic" onClick={handleReset}>
                  Restart
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="basic"
                  disabled={!values?.prompt}
                >
                  Import
                </Button>
              )}
            </Footer>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  min-height: 0;

  ${(p) => p.theme.mediaQueries.mobile} {
    gap: 20px;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 30px;

  ${(p) => p.theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
  }
`;

const Column = styled(FlexColumn)`
  min-height: 0;
  ${HTMLTextearea} {
    min-height: 220px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    ${HTMLTextearea} {
      min-height: 300px;
    }
  }
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
  min-height: 0;
  flex-grow: 1;
  overflow: auto;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ShowsColumn = styled(Column)`
  margin-right: -20px;
`;
