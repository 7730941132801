import { useState } from "react";
import { SingleForm } from "./SingleForm";
import { getDateTime } from "utils/date";
import { ConcertsBulkForm } from "./ConcertsBulkForm";
import { FlexColumn } from "Components/Flex";
import { Radio } from "melodies-source/Selectable";
import { Body2 } from "melodies-source/Text";
import styled from "styled-components";
import { PlaceDetail } from "Components/Maps";

interface AddConcertProps {
  concerts?: any[];
  onCancel?: () => void;
  onSubmit?: (v: PlaceDetail) => void;
  onBulkSubmit?: (v: PlaceDetail[]) => void;
}

export const AddConcerts = ({
  concerts = [],
  onCancel,
  onSubmit,
  onBulkSubmit,
}: AddConcertProps) => {
  const [isManualEntry, setIsManualEntry] = useState(true);
  const upcoming = concerts.map(({ date }) => ({ date: getDateTime(date) }));
  const controls = <Controls {...{ isManualEntry, setIsManualEntry }} />;

  return (
    <Container>
      {isManualEntry ? (
        <SingleForm
          controls={controls}
          onCancel={onCancel}
          onCreate={(v) => onSubmit?.(v)}
        />
      ) : (
        <ConcertsBulkForm
          controls={controls}
          upcoming={upcoming}
          onCancel={onCancel}
          onSubmit={(v) => onBulkSubmit?.(v)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  column-gap: 44px;
  row-gap: 20px;
  padding: 30px 0 0 0;
`;

type ControlProps = {
  isManualEntry: boolean;
  setIsManualEntry: (val: boolean) => void;
};

const Controls = ({ isManualEntry, setIsManualEntry }: ControlProps) => {
  return (
    <FlexColumn>
      <Radio
        value={isManualEntry}
        label="Manually add a show"
        onChange={() => setIsManualEntry(true)}
        style={{ alignItems: "center", marginBottom: 8 }}
      />
      <Radio
        value={!isManualEntry}
        label="Paste one or multiple shows"
        onChange={() => setIsManualEntry(false)}
        style={{ alignItems: "center" }}
      />
      {!isManualEntry && (
        <Body2 style={{ marginTop: 20, color: "var(--secondary-text-color)" }}>
          This tool will intelligently add one or multiple shows to your SET
          account that have been copied from another source.
          <br /> <br />
          Start by selecting a list of shows from a webpage or document that you
          want to import. Then copy and paste that information into the text
          field.
        </Body2>
      )}
    </FlexColumn>
  );
};
