import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import logoMax from "Components/Assets/logoMax.png";
import Footer from "Components/Footer";
import { ProgressBar } from "Components/ProgressBar/index";
import Soundcloud from "Components/Assets/SoundCloud.png";
import { Accordion } from "../../melodies-source/Accordion/index";
import { Body1, Body2, H2, H3, H4, P } from "../../melodies-source/Text/index";
import { Button } from "../../melodies-source/Button/index";
import { DonutChart } from "../../melodies-source/DonutChart/index";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "Hooks/useMediaQuery";
import ArtistLinks from "Components/ArtistLinks";
import { SvgLocation } from "../../melodies-source/Svgs/Location";
import { SvgCareerStage4 } from "../../melodies-source/Svgs/CareerStage4";
import { SvgCareerStageLegacy } from "melodies-source/Svgs/CareerStageLegacy";
import { SvgMusic } from "../../melodies-source/Svgs/Music";
import { SvgLeft } from "melodies-source/Svgs/Left";
import CircleChecked from "Components/Assets/Icons/circle-checked.svg?react";
import { DivergingChart } from "Components/DivergingChart";
import { Player } from "Components/Player";
import { AppLoading } from "melodies-source/AppLoading";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import logo from "Components/Assets/Logo.png";
import { MapToolbar, MapViews, MarketsMap } from "Components/MarketsMap";
import { useArtist } from "Providers/ArtistProvider";
import { socials } from "Components/Assets/Icons/socials";
import { useListContext } from "Hooks/useListContext";
import { useAnalytics } from "Providers/AnalyticsProvider";
import { FlexRow } from "Components/Flex";

export const ArtistPage = () => {
  const { artist, overrides, loading } = useArtist();
  const navigate = useNavigate();
  const isMobile = useMediaQuery();
  const isTablet = useMediaQuery("(max-width: 1150px)");
  const { track } = useAnalytics();
  const { listId } = useListContext();
  const [mapView, setMapView] = useState<MapViews>("listenership");

  const handleBackButton = () => {
    navigate("../");
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (!!artist?.id) {
      track(listId, {
        type: "view:artist",
        artistId: artist.id,
      });
    }
  }, [artist?.id]);

  useEffect(() => {
    if (overrides?.market?.hidden) {
      setMapView("tourDates");
    }
  }, [overrides]);

  if (loading && !artist) return <AppLoading />;

  const displayLocations = artist?.locations?.reduce((accum, val) => {
    const locIndex = accum?.findIndex((loc) => val.title.includes(loc.title));
    if (locIndex === -1) {
      accum.push(val);
    }
    return accum;
  }, []);

  const attributes = artist?.attributes;

  const genderChartData = attributes?.gender?.map((attr) => ({
    label: attr.title,
    value: Math.floor(attr.value * 100),
  }));
  const soundBars = artist?.genres?.sort(
    (a, b) => b.raw_weight_float - a.raw_weight_float,
  );
  const half = Math.ceil(soundBars?.length / 2);
  const soundBarArr1 = soundBars?.slice(0, half);
  const soundBarArr2 = soundBars?.slice(half);

  let renderAccordion = [];

  if (artist?.overview?.html && !artist?.overview?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: <SectionTitle>Artist Overview</SectionTitle>,
      body: (
        <OverviewContainer>
          <Body>
            <MarkdownBody>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {artist?.overview?.html}
              </ReactMarkdown>
            </MarkdownBody>
          </Body>
          <HighlightList>
            {artist?.highlights?.map((highlight) => (
              <HighlightWrapper>
                <CircleChecked
                  style={{ width: 20, height: 20, marginTop: 2 }}
                />
                <H4>{highlight}</H4>
              </HighlightWrapper>
            ))}
          </HighlightList>
        </OverviewContainer>
      ),
    });
  }

  if (!artist?.followers?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <SectionTitle>Fanbase</SectionTitle>
          <Body2>
            Insights into an artist's social media following and fans' music
            streaming
          </Body2>
        </Header>
      ),
      body: (
        <SocialContainer>
          {!!artist?.services?.facebook?.likes && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.facebook?.url)}
            >
              <IconWrapper>
                <socials.FacebookColor />
              </IconWrapper>
              <H4>
                {(artist?.services?.facebook?.likes || 0).toLocaleString()}
              </H4>
              <P>Likes</P>
            </FanButton>
          )}
          {!!artist?.services?.instagram?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.instagram?.url)}
            >
              <IconWrapper>
                <socials.InstagramColor />
              </IconWrapper>
              <H4>
                {(artist?.services?.instagram?.followers || 0).toLocaleString()}
              </H4>
              <P>Followers</P>
            </FanButton>
          )}
          {!!artist?.services?.twitter?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.twitter?.url)}
            >
              <IconWrapper>
                <socials.XDark />
              </IconWrapper>
              <H4>
                {(artist?.services?.twitter?.followers || 0).toLocaleString()}
              </H4>
              <P>Followers</P>
            </FanButton>
          )}
          {(!!artist?.services?.spotify?.followers ||
            !!artist?.services?.spotify?.listeners) && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.spotify?.url)}
            >
              <IconWrapper>
                <socials.SpotifyColor />
              </IconWrapper>
              <H4>
                {(
                  artist?.services?.spotify?.listeners ||
                  artist?.services?.spotify?.followers ||
                  0
                ).toLocaleString()}
              </H4>
              <P>
                {!!artist?.services?.spotify?.listeners
                  ? "Listeners"
                  : "Followers"}
              </P>
            </FanButton>
          )}
          {!!artist?.services?.youtube?.views && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.youtube?.url)}
            >
              <IconWrapper>
                <socials.YouTubeColor />
              </IconWrapper>
              <H4>
                {(artist?.services?.youtube?.views || 0).toLocaleString()}
              </H4>
              <P>Views</P>
            </FanButton>
          )}
          {!!artist?.services?.tiktok?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.tiktok?.url)}
            >
              <IconWrapper>
                <socials.TikTokColor />
              </IconWrapper>
              <H4>{artist?.services?.tiktok?.followers?.toLocaleString()}</H4>
              <P>Followers</P>
            </FanButton>
          )}
          {!!artist?.services?.soundcloud?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.soundcloud?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Soundcloud} />
              </IconWrapper>
              <H4>
                {(
                  artist?.services?.soundcloud?.followers || 0
                ).toLocaleString()}
              </H4>
              <P>Listeners</P>
            </FanButton>
          )}
        </SocialContainer>
      ),
    });
  }

  if (!artist?.sound?.hidden && !!artist?.genres?.length) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <SectionTitle>Sound</SectionTitle>
          <Body2>
            The artist's unique genre fingerprint and data-driven
            categorizations
          </Body2>
        </Header>
      ),
      body: (
        <ContainerProgress>
          <div>
            {soundBarArr1?.map((genre, i) => {
              return (
                <div key={i}>
                  <H4>{genre?.title}</H4>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              );
            })}
          </div>
          <div>
            {soundBarArr2?.map((genre, i) => {
              return (
                <div key={i}>
                  <H4>{genre?.title}</H4>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              );
            })}
          </div>
        </ContainerProgress>
      ),
    });
  }

  if (!artist?.track?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <SectionTitle>Music Sample</SectionTitle>
        </Header>
      ),
      body: (
        <Body spotify={true}>
          <Player {...artist?.track} />
        </Body>
      ),
    });
  }

  if (!artist?.charts?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <SectionTitle>Audience</SectionTitle>
          <Body2>
            Insights into an artist's audience based on a comparison to other
            artists' fan bases
          </Body2>
        </Header>
      ),
      body: (
        <BodyAudience>
          <ChartWrapper>
            {!!attributes?.gender?.length && (
              <ChartBox donut={true}>
                <H3>Gender</H3>
                <div>
                  <DonutChart
                    w={isMobile || isTablet ? 200 : 400}
                    onSelectItem={() => {}}
                    chartDimensions={isMobile || isTablet ? 140 : 220}
                    isArtistList
                    withLegend
                    data={genderChartData}
                  />
                </div>
              </ChartBox>
            )}

            {!!attributes?.age?.length && (
              <ChartBox>
                <H3>Age</H3>
                <DivergingChart
                  items={attributes?.age}
                  showAverage={attributes?.["onlyOverAverage"]}
                />
              </ChartBox>
            )}

            {!!attributes?.income?.length && (
              <ChartBox>
                <H3>Household Income</H3>
                <DivergingChart
                  items={attributes?.income}
                  showAverage={attributes?.["onlyOverAverage"]}
                />
              </ChartBox>
            )}
          </ChartWrapper>

          <ChartWrapper>
            {!!attributes?.ethnicity?.length && (
              <ChartBox>
                <H3>Ethnicity</H3>
                <DivergingChart
                  items={attributes?.ethnicity}
                  showAverage={attributes?.["onlyOverAverage"]}
                />
              </ChartBox>
            )}

            {!!attributes?.other?.length &&
              !attributes?.["attributes_hidden"] && (
                <ChartBox>
                  <H3>Consumer Attributes</H3>
                  <DivergingChart
                    items={attributes?.other}
                    showAverage={attributes?.["onlyOverAverage"]}
                  />
                </ChartBox>
              )}
          </ChartWrapper>
        </BodyAudience>
      ),
    });
  }

  if (!overrides?.market?.hidden || !overrides?.marketTour?.hidden) {
    const sectionContent = {
      title: "Markets",
      description: "Insights into an artist's key locations or audience reach",
    };
    if (overrides?.market?.hidden) {
      sectionContent.title = "Tour Dates";
      sectionContent.description = "Showing available tour dates.";
    }
    if (overrides?.marketTour?.hidden) {
      sectionContent.title = "Listenership";
      sectionContent.description = "Showing fan listenership.";
    }
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <MapHeader>
          <SectionTitle>{sectionContent.title}</SectionTitle>
          <Body2>{sectionContent.description}</Body2>
        </MapHeader>
      ),
      headerRenderIfActive: (
        <MapHeader>
          <SectionTitle>{sectionContent.title}</SectionTitle>
          <FlexRow yCenter xEnd>
            <Body2>{sectionContent.description}</Body2>
            {!overrides?.market?.hidden && !overrides?.marketTour?.hidden && (
              <MapToolbar onChange={setMapView} value={mapView} />
            )}
          </FlexRow>
        </MapHeader>
      ),
      markets: true,
      body: (
        <BodyMap>
          <MarketsMap view={mapView} />
        </BodyMap>
      ),
    });
  }

  if (artist?.background_html) {
    renderAccordion.push({
      defaultOpen: true,
      header: <H3>Artist Biography</H3>,
      body: (
        <Body>
          <Body1>{artist?.background_html}</Body1>
        </Body>
      ),
    });
  }

  return (
    <Container>
      <MaxLogo src={logoMax} alt="" id="logo" />
      <LogoMobile src={logo} alt="" id="logoMobile" />
      <Wrapper>
        <Button
          variant="basic"
          w={180}
          onClick={handleBackButton}
          leftIcon={<SvgLeft />}
          gap="8px"
          style={{ alignSelf: "flex-start" }}
        >
          Return To List
        </Button>
        <ArtistContainer>
          <div>
            <ArtistImage src={artist?.image ?? logo} alt="featured artist" />
          </div>
          <MobilePadding>
            <H2>{artist?.title || artist?.name}</H2>
            {!!displayLocations?.length && (
              <span>
                <SvgLocation />
                <Details>
                  {displayLocations.map(({ title }) => title).join(" • ")}
                </Details>
              </span>
            )}
            {!!artist?.audience_size && (
              <span>
                <SvgCareerStage4 />
                <Details>{artist?.audience_size}</Details>
              </span>
            )}
            {!!artist?.eras?.length && (
              <span>
                <SvgCareerStageLegacy />
                <Details>{artist?.eras?.join(", ")}</Details>
              </span>
            )}
            {(!!artist?.primaryGenre || !!artist?.genres?.length) && (
              <span>
                <SvgMusic />
                <Details>
                  {artist?.primaryGenre ||
                    artist?.genres
                      ?.slice(0, 3)
                      .map(({ title }) => title)
                      ?.join(" • ")}
                </Details>
              </span>
            )}
            {!isMobile && <ArtistLinks services={artist?.services} />}
          </MobilePadding>
        </ArtistContainer>
        <StyledAccordion sections={renderAccordion} />
        {isMobile && <ArtistLinks services={artist?.services} />}
      </Wrapper>
      <Footer isArtistPage={true} />
    </Container>
  );
};

const SectionTitle = styled(H3)`
  font-size: 25px;
  line-height: 35px;
  font-family: "Archivo", sans-serif;
  font-weight: 900;
`;

const Container = styled.div`
  position: relative;
  color: #000;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;
  width: clamp(900px, 80vw, 1170px);
  margin: 0px auto 25px;
  overflow: hidden;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 50px auto 50px auto;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-self: flex-start;
  @media (max-width: 950px) {
    margin: 10px auto;
    padding: 0;
    & > button {
      margin-left: 15px;
    }
  }
`;

export const MaxLogo = styled.img`
  width: 100%;
  max-width: 100px;
  object-fit: contain;
  margin: 20px auto 20px 0;
  @media (max-width: 950px) {
    margin: 20px;
  }
`;

const LogoMobile = styled.img`
  display: none;
  @media (max-width: 950px) {
    display: block;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    right: -30px;
    margin: auto 0 auto auto;
    z-index: 1;
    width: 20%;
    max-width: 150px;
    top: 0;
  }
`;

const ArtistContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  & > div {
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
  }
  span {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    min-height: 20px;

    svg {
      margin: 1px 10px -10px 0;
      color: #0240fb;
      display: flex;
      flex-shrink: 0;
    }
  }
  @media (max-width: 950px) {
    flex-direction: column;
    gap: 10px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    span {
      margin-top: 6px;
      & + span {
        margin-top: 4px;
      }
    }
  }
`;

const ArtistImage = styled.img`
  height: 240px;
  border-radius: 0px;
  border: 1px solid lightgrey;
  margin-right: 44px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
  @media (max-width: 950px) {
    width: 100%;
    max-width: 500px;
    height: unset;
    margin-right: 0;
  }
`;

const Details = styled(P)`
  display: inline-block;
  border-radius: 50px;
  border: 0px solid lightgrey;
  color: #333333;
  font-weight: 500;

  @media (max-width: 950px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`;

const SocialIcon = styled.img`
  width: 100%;
  margin: 10px auto;
  object-fit: contain;
`;

const HighlightList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  padding-top: 15px;
  @media (max-width: 950px) {
    width: 100%;
    padding-top: 0px;
  }
`;

const MobilePadding = styled.div`
  @media (max-width: 950px) {
    padding-left: 15px;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  @media (max-width: 950px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const HighlightWrapper = styled.div`
  display: flex;
  gap: 10px;
  svg {
    flex-shrink: 0;
  }
  @media (max-width: 950px) {
    h4 {
      font-size: 12px;
    }
  }
`;

const FanButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  height: fit-content;
  min-width: 100px;
  max-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  border: 0px solid lightgrey;
  p {
    font-size: 13px;
  }
  &:hover {
    border: none;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
  position: relative;

  @media (min-width: 550px) and (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 550px) {
    //  max-width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledAccordion = styled(Accordion)`
  margin: 40px 0;
  @media (max-width: 950px) {
    margin: 15px 0;
  }
`;

const Body = styled.div<{ spotify?: boolean }>`
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    max-width: 85vw;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  ${({ spotify }) =>
    spotify &&
    css`
      @media (max-width: 950px) {
        max-width: 90vw;
      }
    `}
`;
const MarkdownBody = styled.div`
  font-family: "Work Sans";
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  h3 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 1.9rem;
  }
  table,
  tbody,
  tr,
  th {
    border: 1px solid #bfbfbf;
    padding: 10px;
    text-align: center !important;
  }
  td {
    border: 1px solid #e2e2e2;
    padding: 10px 15px;
    text-align: left !important;
    font-size: 0.8rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${Body2} {
    color: ${(p) => p.theme.colors.black80};
    margin-top: 2px;
    margin-left: 30px;
    text-align: right;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    ${Body2} {
      text-align: left;
      margin-left: 0px;
    }
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    align-items: center;
  }
`;

const MapHeader = styled(Header)`
  & > ${FlexRow} {
    gap: 20px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    & > ${FlexRow} {
      gap: 18px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const ContainerProgress = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 120px;
  row-gap: 0px;
  margin-top: 10px;

  @media (max-width: 950px) {
    max-width: 80vw;
    gap: 0px;
    grid-template-columns: 1fr;
  }
  & > div {
    margin: 10px 10px 10px 0;
    h4 {
      color: #333;
      font-size: 15px;
    }
    @media (max-width: 950px) {
      margin: 10px 10px -10px 0;
    }
  }
  & > div > div {
    margin: 0px 0px 20px 0;
  }
`;

const BodyAudience = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  gap: 32px;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: calc(50% - 17px);
  gap: 30px;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

const ChartBox = styled.div<{ mb?: boolean; donut?: boolean }>`
  width: 100%;
  max-width: 500px;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid lightgrey;
  padding: 24px 28px 32px;
  margin: 0px;
  ${(props) => props.mb && "margin-bottom: 30px;"};
  @media (max-width: 950px) {
    padding: 20px 25px;
    & > ${H3} {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  ${(props) => props.theme.mediaQueries.desktop} {
    padding: 32px 48px 44px;
    & > ${H3} {
      margin-bottom: 24px;
      text-align: center;
    }
  }

  ${(props) =>
    props.donut &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      & > div {
        display: flex;
        flex-grow: 1;
        margin-left: 10px;
      }
    `}
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 63px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const BodyMap = styled.div`
  margin: 15px 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
