export const getPlacePhoto = async (placeId): Promise<string | null> => {
  const { Place } = (await google.maps.importLibrary(
    "places",
  )) as google.maps.PlacesLibrary;
  try {
    const place = new Place({
      id: placeId,
    });
    await place.fetchFields({
      fields: ["photos"],
    });
    if (place.photos?.length) {
      return place.photos[0].getURI();
    }
  } catch (err) {
    console.error(err);
    return "";
  }
};
