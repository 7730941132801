import { H1 } from "melodies-source/Text";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch } from "melodies-source/Switch";

export const SwitchCards = ({ sections, handleHiddenProperty, ...props }) => {
  return (
    <SectionContainer {...props}>
      {sections.map((section, index) => (
        <Card
          {...section}
          key={index}
          handleHiddenProperty={handleHiddenProperty}
        />
      ))}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  background-color: #f4f7f9;
  box-shadow: 0px 4px 20px 0px #d1d0d0;
  position: relative;
  z-index: 1;
  @media (max-width: 950px) {
    padding: 20px;
  }
`;
const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  z-index: 20;
  padding: 0 12px;
  @media (max-width: 950px) {
    align-items: flex-start;
    & > div:first-child {
      margin-top: 6px;
    }
  }
`;
const SectionBody = styled.div`
  padding: 12px 12px;
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
`;

export const Card = ({
  header,
  body,
  hidden,
  name,
  handleHiddenProperty,
  ...props
}) => {
  return (
    <SectionWrapper {...props}>
      {hidden && <Overlay />}
      <SectionHeader>
        <Switch
          value={!hidden}
          onChange={() => handleHiddenProperty(`${name}.hidden`, !hidden)}
        />
        {header}
      </SectionHeader>
      <SectionBody>{body}</SectionBody>
    </SectionWrapper>
  );
};
