import { googleGeocoder, googlePrediction } from "utils/maps";
import { debounce } from "lodash";
import { OptionProps } from "melodies-source/Autocomplete";
import { SvgLocation } from "melodies-source/Svgs/Location";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useUserIdToken from "./useUserIdToken";

interface PlacesAutocomplete {
  loading: boolean;
  setQuery: React.Dispatch<SetStateAction<string>>;
  query: string;
  results: OptionProps[];
  getAddressDetails: (v: OptionProps) => Promise<
    | {
        place: google.maps.GeocoderResult;
        placeName: string;
      }
    | undefined
  >;
  userIdToken?: string;
}

export const usePlacesAutocomplete = (): PlacesAutocomplete => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<OptionProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userIdToken = useUserIdToken();

  const handleSearch = useMemo(() => {
    return debounce(async (queryString) => {
      setLoading(true);
      try {
        const results = await googlePrediction(queryString, userIdToken);
        if (results) {
          const places = results
            .filter((r) => !!r.structured_formatting.secondary_text)
            .map((c) => ({
              icon: <SvgLocation />,
              label: c.structured_formatting.main_text,
              caption: c.structured_formatting.secondary_text,
              value: c.place_id,
            }));
          setResults(places);
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      } finally {
        setLoading(false);
      }
    }, 500);
  }, [userIdToken]);

  useEffect(() => {
    if (!!query) {
      handleSearch(query);
    } else {
      handleSearch.cancel();
      setResults([]);
    }
    return () => {
      handleSearch.cancel();
    };
  }, [query]);

  const getAddressDetails = useCallback(
    async (selected: OptionProps) => {
      const placeId = selected.value;
      const placeName = selected.label;
      const places = await googleGeocoder(placeId, userIdToken);
      if (places.length) {
        return { place: places[0], placeName };
      }
    },
    [userIdToken],
  );

  return { loading, setQuery, query, results, getAddressDetails, userIdToken };
};
