import { useState } from "react";
import { FlexColumn, FlexRow } from "Components/Flex";
import styled from "styled-components";
import { PlaceDetail } from "Components/Maps";
import { Datepicker } from "Components/DatePicker";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import { useGenerateText } from "Hooks";
import { useArtist } from "Providers/ArtistProvider";
import { SingleFormFooter, createConcertFromPlace } from "Components/Concerts";
import { ImageUpload } from "Components/ImageUpload";
import { useParams } from "react-router-dom";
import { SvgRefreshLarge } from "melodies-source/Svgs/RefreshLarge";
import { RegenerateButton } from "Components/RegenerateButton";
import { Body1, Label } from "melodies-source/Text";
import {
  PlacesAutocomplete,
  PlacesAutocompleteResult,
} from "Components/PlacesAutocomplete";
import { Spinner } from "melodies-source/Spinner";
import { SvgPencil } from "assets/svg";

type EditConcert = Omit<PlaceDetail, "capacity"> & {
  capacity?: string | number;
};

interface EditConcertFormProps {
  concert?: PlaceDetail;
  onCancel?: () => void;
  onSubmit?: (v: PlaceDetail) => void;
}

export const EditConcertForm = ({
  concert,
  onCancel,
  onSubmit,
}: EditConcertFormProps) => {
  const [details, setDetails] = useState<EditConcert>(concert);
  const [replaceImage, setReplaceImage] = useState(false);
  const { getVenueOverview } = useGenerateText();
  const { artist } = useArtist();
  const { listId } = useParams();
  const [loadingAI, setLoadingAI] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState(false);
  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const handleDate = (date: DateTime) => {
    setDetails((d) => ({ ...d, date }));
  };

  const handleSubmit = () => {
    const updatedDetails = { ...details };
    if (typeof updatedDetails.capacity === "string") {
      if (!Number.isNaN(Number(updatedDetails.capacity))) {
        updatedDetails.capacity = Number(updatedDetails.capacity);
      } else {
        updatedDetails.capacity = 0;
      }
    }
    onSubmit(details as PlaceDetail);
  };

  const handleGenerateText = async () => {
    try {
      setLoadingAI(true);

      const response = await getVenueOverview({
        artistName: artist.title || artist.name,
        venues: {
          [details.id]: {
            name: details.title,
            address: details.address,
          },
        },
      });
      const result = response?.[details.id];
      if (result) {
        setDetails((d) => ({
          ...d,
          description: result.description || d.description,
          capacity: result.capacity || 0,
        }));
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setLoadingAI(false);
    }
  };

  const handleVenueSelect = async (selected: PlacesAutocompleteResult) => {
    setAddressLoading(true);
    const concert = createConcertFromPlace(
      selected,
      details?.date,
      details?.id,
    );

    const response = await getVenueOverview({
      artistName: artist.title || artist.name,
      venues: {
        [details.id]: {
          name: concert.title,
          address: concert.address,
        },
      },
    });
    const { description, capacity } = response?.[details.id] || {};

    setDetails((d) => ({ ...d, ...concert, description, capacity }));
    setEditAddress(false);
    setAddressLoading(false);
  };

  return (
    <>
      <Container>
        <Datepicker
          label="Date and Time"
          onChange={handleDate}
          value={details?.date}
          placeholder="Pick a date..."
          minDate={DateTime.now()}
          timeZone={details?.timeZone}
          type="dateOnly"
        />
        {!replaceImage && details.imageUrl ? (
          <FlexColumn gap="8px" style={{ alignItems: "flex-start" }}>
            <PlaceImage
              style={{ backgroundImage: `url(${details.imageUrl})` }}
              onClick={() => setReplaceImage(true)}
            />
            <Button
              variant="basicText"
              leftIcon={<SvgRefreshLarge style={{ width: 20, height: 20 }} />}
              onClick={() => setReplaceImage(true)}
              style={{
                gap: 8,
                width: "min-content",
              }}
            >
              Replace
            </Button>
          </FlexColumn>
        ) : (
          <ImageUpload
            uploadPath={`/ame_sharelists/${listId}`}
            uploadFileName="user"
            onComplete={(img) => {
              setDetails((d) => ({ ...d, imageUrl: img }));
              setReplaceImage(false);
            }}
            withAspectRatio
            aspectRatio={1.58 / 1}
          />
        )}
        <TextInput
          label="Venue Name"
          value={details.title}
          onChange={(v) => setDetails((d) => ({ ...d, title: v }))}
        />
        {!editAddress ? (
          <FlexColumn gap="4px" xStart>
            <Label>Venue Address</Label>
            {addressLoading ? <Spinner /> : <Body1>{details.address}</Body1>}

            <Button
              w="auto"
              variant="basicText"
              onClick={() => setEditAddress(true)}
              gap="4px"
              leftIcon={<SvgPencil style={{ width: 20, height: 20 }} />}
            >
              Change Address
            </Button>
          </FlexColumn>
        ) : (
          <FlexColumn>
            <PlacesAutocomplete
              label="Venue Address"
              onChange={handleVenueSelect}
              placeholder="Type a venue or address..."
            />
            <Button
              w="auto"
              variant="basicText"
              onClick={() => setEditAddress(true)}
              style={{ fontSize: 14 }}
            >
              Cancel
            </Button>
          </FlexColumn>
        )}
        <TextInput
          label="Venue Capacity"
          value={details.capacity?.toString()}
          onChange={(v) =>
            setDetails((d) => ({ ...d, capacity: v.replace(/\D/g, "") }))
          }
        />
        <FlexColumn>
          <Textarea
            label="Venue Description"
            value={details.description}
            onChange={(v) => setDetails((d) => ({ ...d, description: v }))}
          />
          <RegenerateButton loading={loadingAI} onClick={handleGenerateText} />
        </FlexColumn>
      </Container>
      <SingleFormFooter>
        <Button onClick={onCancel} variant="basicOutline">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!details?.title || !details?.address || !details?.date}
          variant="basic"
        >
          Save
        </Button>
      </SingleFormFooter>
    </>
  );
};

const PlaceImage = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  /* padding-top: 50%; */
  aspect-ratio: 300/190;
  width: 100%;
`;

const Container = styled(FlexColumn)`
  gap: 20px;
  padding: 30px 0 0 0;
`;
