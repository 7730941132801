import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { set } from "lodash";
import { useAnalytics } from "Providers/AnalyticsProvider";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { PasswordDialog } from "../../Components/PasswordDialog";
import { mergeListOverrides } from "./overrides";
import { Artist, ArtistOverride, ShareLists } from "./types";
import { listDefaults } from "./listDefaults";

interface ListAuthOptions {
  password?: string;
  invalidPassword?: boolean;
  isDialogOpen?: boolean;
}

type AddArtist = { artist: Artist; overrides: ArtistOverride };

interface ListContextProps {
  listId: string;
  list: ShareLists;
  artists: Artist[];
  loading: boolean;
  save: (path?: string) => void;
  saveLoading: boolean;
  loadAnimation?: boolean;
  setLoadAnimation: (v: boolean) => void;
  setList: (property: keyof ShareLists, value: any) => void;
  addArtists: (artists: AddArtist[]) => Promise<void>;
}

export const ListContext = createContext({} as ListContextProps);

export const ListProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setListDetails] = useState<ShareLists>();
  const [listAuth, setListAuth] = useState<ListAuthOptions>({});
  const [listDocLoading, setListDocLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { track } = useAnalytics();
  const [loadAnimation, setLoadAnimation] = useState(true);

  const db = useFirestore();
  const { listId } = useParams();
  const navigate = useNavigate();

  const handleError = (error: Error) => {
    console.error(error);
    navigate("/");
  };

  useEffect(() => {
    if (!listId) return;
    setListDocLoading(true);

    track(listId, { type: "view:list" });

    try {
      const unsubscribe = onSnapshot(
        doc(db, "ame_sharelists", listId) as DocumentReference<ShareLists>,
        (snap) => handleSnapshot(snap),
        (error) => handleError(error),
      );

      return () => unsubscribe();
    } catch (error) {
      console.error(error);
    }
  }, [listId]);

  const handleSnapshot = (snap: DocumentSnapshot<ShareLists>) => {
    const localPass = localStorage.getItem(listId);

    if (snap.exists()) {
      if (snap.get("hasPassword")) {
        if (localPass) {
          setListAuth((prev) => ({ ...prev, password: localPass }));
        } else {
          setListAuth({ isDialogOpen: true });
        }
      }
      const snapshot = snap.data();
      const shareListData = { ...listDefaults, ...snapshot };
      setListDetails(shareListData);
    } else {
      navigate("/");
    }

    setListDocLoading(false);
  };

  const { data: artistData, status: artistStatus } = useFirestoreCollectionData(
    collection(
      db,
      `ame_sharelists/${listId}/artists`,
    ) as CollectionReference<Artist>,
  );

  const { data: artistOverrides, status: overrideStatus } =
    useFirestoreCollectionData(
      collection(
        db,
        `ame_sharelists/${listId}/artist_overrides`,
      ) as CollectionReference<ArtistOverride>,
      {
        idField: "id",
      },
    );

  const save = async (path?: string) => {
    setSaveLoading(true);
    try {
      await setDoc(doc(db, "ame_sharelists", listId), list, { merge: true });

      toast.success("List has been saved.");

      if (path) {
        navigate(path);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const setList = (property: keyof ShareLists, value: any) => {
    const listDetails = { ...list };
    const updatedList = set(listDetails, property, value);
    setListDetails(updatedList);
  };

  const addArtists = async (newArtists: AddArtist[]) => {
    if (!newArtists?.length) {
      return;
    }
    const promises = [];
    for (const { artist, overrides } of newArtists) {
      promises.push(
        setDoc(
          doc(db, `ame_sharelists/${listId}/artists/${artist.id}`),
          artist,
        ),
      );
      if (overrides) {
        promises.push(
          setDoc(
            doc(db, `ame_sharelists/${listId}/artist_overrides/${artist.id}`),
            overrides,
          ),
        );
      }
    }
    await Promise.all(promises);
    toast.success(
      `${newArtists.length} Artist${
        newArtists.length === 1 ? "" : "s"
      } added to list.`,
    );
  };

  const loading =
    listDocLoading ||
    artistStatus === "loading" ||
    overrideStatus === "loading";

  const artists = useMemo(() => {
    return mergeListOverrides(artistData, artistOverrides, list?.order);
  }, [artistData, artistOverrides, list?.order]);

  return (
    <ListContext.Provider
      value={{
        listId,
        artists, // Array of artists with a shallow merge of overrides.
        addArtists, // Method for adding new artists.
        loading,
        list, // Current local state of list.
        setList, // Method for updating list details.
        save, // Save current state of list to firestore.
        saveLoading,
        loadAnimation,
        setLoadAnimation,
      }}
    >
      {!listAuth.isDialogOpen ? (
        children
      ) : (
        <PasswordDialog
          onSubmit={(pass) =>
            setListAuth({ ...listAuth, invalidPassword: false, password: pass })
          }
          isInvalid={listAuth.invalidPassword}
          loading={loading}
        />
      )}
    </ListContext.Provider>
  );
};
