import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Div, DivThemeCSSProps } from "../utils";
import { useClickOutside } from "../utils/hooks";

export const SelectMenuBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
`;

export const SelectMenuDiv = styled(Div)`
  background-color: white;
  border: solid 1px#F9FAFB;
  position: absolute;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 8px;
  max-height: 250px;
  z-index: 10000000000;
  ${(p) => p.theme.css.scrollbarDark};
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
`;

type Props = {
  isOpen?: boolean;
  onClose?: Function;
  onClickOutside?: Function;
  children: any;
} & DivThemeCSSProps;
export const SelectMenu = ({
  isOpen = false,
  onClickOutside,
  onClose = () => {},
  children,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom",
    }
  );
  useClickOutside(referenceElement, onClickOutside ?? onClose);

  useEffect(() => {
    if (update) update();
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Div ref={setReferenceElement} pRelative {...props}>
          <SelectMenuDiv
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </SelectMenuDiv>
        </Div>
      )}
    </>
  );
};
