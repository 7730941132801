import { AdminHeader } from "Components/AdminHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "reactfire";

export const AdminAuth = ({ children }) => {
  const navigate = useNavigate();
  const { status, data: user } = useUser();
  const [groups, setGroups] = useState<string[] | null>(null);

  useEffect(() => {
    if (status !== "loading") {
      if (user) {
        user.getIdTokenResult().then((res) => {
          setGroups(res.claims.groups || []);
        });

        window.Sentry?.setUser({ email: user.email, id: user.uid });
      } else {
        setGroups([]);
      }
    }
  }, [user, status]);

  if (status === "loading" || groups === null) {
    return <div>Loading</div>;
  }

  if (!user) {
    navigate("/login");
    return <></>;
  }

  if (!groups.includes("artist-share-lists@max.live")) {
    navigate("/403");
    return;
  }

  return (
    <>
      <AdminHeader />
      {children}
    </>
  );
};
