export const SvgBroadcast = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m4.478 4 1.076.986a6.528 6.528 0 0 0-2.1 4.803c0 1.898.809 3.608 2.1 4.803l-1.076.986A7.977 7.977 0 0 1 2 9.79C2 7.512 2.951 5.457 4.478 4Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.817 9.79a2.182 2.182 0 1 0 4.364 0 2.182 2.182 0 0 0-4.364 0Zm2.91 0a.727.727 0 1 1-1.455 0 .727.727 0 0 1 1.455 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="m7.711 6.964-1.08-.99a5.079 5.079 0 0 0-1.722 3.815 5.08 5.08 0 0 0 1.721 3.817l1.081-.99a3.63 3.63 0 0 1-1.347-2.827A3.63 3.63 0 0 1 7.71 6.964ZM15.522 4A7.977 7.977 0 0 1 18 9.789a7.977 7.977 0 0 1-2.478 5.79l-1.076-.987a6.528 6.528 0 0 0 2.1-4.803 6.528 6.528 0 0 0-2.1-4.803L15.522 4Z"
    />
    <path
      fill="currentColor"
      d="M15.092 9.79c0-1.52-.666-2.884-1.721-3.817l-1.08.99a3.63 3.63 0 0 1 1.346 2.826 3.63 3.63 0 0 1-1.347 2.826l1.08.99a5.079 5.079 0 0 0 1.722-3.816Z"
    />
  </svg>
);
