import { Button } from "melodies-source/Button";
import { H3 } from "melodies-source/Text";
import { Textarea } from "melodies-source/Textarea";
import { TextInput } from "melodies-source/TextInput";
import { useArtist } from "Providers/ArtistProvider";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { useUser } from "reactfire";
import { useListContext } from "Hooks/useListContext";
import { RegenerateButton } from "Components/RegenerateButton";

export const Highlights = () => {
  const { artist, overrides, setOverride, save, setArtistOverrides } =
    useArtist();
  const { list } = useListContext();
  const [token, setToken] = useState<string>();
  const { status, data: user } = useUser();
  const [loadingAI, setLoadingAI] = useState<boolean>(false);

  useEffect(() => {
    const getToken = async () => {
      const token = await user.getIdToken();
      setToken(token);
    };
    getToken();
  }, []);

  const handleRegenerate = async () => {
    try {
      setLoadingAI(true);

      const data = await fetch(`/api/artist/overview/${artist?.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          artist,
          brandName: list?.client,
          brandObjective: list?.campaignGoal,
        }),
      });
      const parsedData = await data.json();
      let update = Object.assign({}, overrides);
      update.overview = { html: parsedData?.data?.overview, hidden: false };
      if (!artist?.highlights?.length) {
        update.highlights = parsedData?.data?.highlights;
      }
      setArtistOverrides(update);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAI(false);
    }
  };

  return (
    <Body>
      <ModuleOverviewContent>
        <div>
          <Textarea
            label="Artist Overview"
            placeholder="Enter Artist Overview"
            value={artist?.overview?.html}
            onChange={(v) =>
              setArtistOverrides((prev) => ({
                ...prev,
                overview: { ...prev.overview, html: v },
              }))
            }
          />
          <RegenerateButton
            loading={loadingAI}
            onClick={() => handleRegenerate()}
          />
        </div>
        <div>
          <H3>Artist Highlights</H3>
          {(overrides?.highlights || [])?.map((highlight, i) => (
            <HighlightWrapper key={`key_${i}`}>
              <TextInput
                label={`Highlight ${i + 1}`}
                placeholder="Enter Highlight"
                value={highlight}
                onChange={(v) => {
                  const highlights = [...overrides.highlights];
                  highlights[i] = v;
                  setOverride("highlights", highlights);
                }}
              />
              <SvgCloseLarge
                onClick={() => {
                  let update = [...artist?.highlights];
                  update = update.filter((item, idx) => i !== idx);
                  setOverride("highlights", update);
                }}
              />
            </HighlightWrapper>
          ))}
          <Button
            variant="basicText"
            leftIcon={<SvgAddAlt style={{ width: 15 }} />}
            style={{ padding: 0, width: "fit-content" }}
            onClick={() =>
              setArtistOverrides((prev) => ({
                ...prev,
                highlights: [...prev.highlights, ""],
              }))
            }
          >
            Add Highlight
          </Button>
        </div>
      </ModuleOverviewContent>
    </Body>
  );
};

const Body = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  @media (max-width: 950px) {
    max-width: 85vw;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const HighlightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    margin-top: 15px;
    margin-left: 5px;
    cursor: pointer;
  }
  @media (max-width: 950px) {
  }
`;

const ModuleOverviewContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  input {
    margin-bottom: 10px;
    width: 100%;
  }
  textarea {
    height: 230px;
  }
  h3 {
    font-weight: 700;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;
