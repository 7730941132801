import { Login } from "./Login";
import { AnalyticsProvider } from "Providers/AnalyticsProvider";
import { ArtistProvider } from "Providers/ArtistProvider";
import { GeoProvider } from "Providers/GeoProvider";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ListProvider } from "../Providers";
import { AddArtist } from "./AddArtist";
import { AdminAuth } from "./AdminAuth";
import { AdminListView } from "./AdminListView";
import { ArtistPage } from "./ArtistPage";
import { CreateList } from "./CreateList";
import { EditArtist } from "./EditArtist";
import { Landing } from "./Landing";
import { Page404 } from "./Page404";
import { useEffect } from "react";
import { useAuth, useUser } from "reactfire";
import { signInAnonymously } from "firebase/auth";
import { Page403 } from "./Page403";

export const InternalRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/admin/*"
          element={
            <AdminAuth>
              <AdminRoutes />
            </AdminAuth>
          }
        />

        <Route path="/:listId/*" element={<ListRoutes />} />
        <Route path="/login" element={<Login />} />
        <Route path="/403" element={<Page403 />} />
        <Route path="/" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export const ListRoutes = () => {
  const { status, data: user } = useUser();
  const auth = useAuth();
  useEffect(() => {
    if (status !== "loading" && user === null) {
      signInAnonymously(auth);
    }
  }, [status]);
  return (
    <GeoProvider>
      <AnalyticsProvider>
        <ListProvider>
          <Routes>
            <Route index element={<Landing />} />
            <Route
              path="/:artistId"
              element={
                <ArtistProvider excludeHiddenMetrics>
                  <ArtistPage />
                </ArtistProvider>
              }
            />
          </Routes>
        </ListProvider>
      </AnalyticsProvider>
    </GeoProvider>
  );
};

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<AdminListView />} />
      <Route path="/create-list" element={<CreateList />} />
      <Route
        path="/:listId/*"
        element={
          <ListProvider>
            <Routes>
              <Route index element={<AddArtist />} />
              <Route
                path="/:artistId"
                element={
                  <ArtistProvider>
                    <EditArtist />
                  </ArtistProvider>
                }
              />
            </Routes>
          </ListProvider>
        }
      />
    </Routes>
  );
};
