import { FlexColumn, FlexRow } from "Components/Flex";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { SvgDesktopLarge } from "melodies-source/Svgs/DesktopLarge";
import { SvgMobile } from "melodies-source/Svgs/Mobile";
import { PlaceDetail } from "Components/Maps";
import { useArtist } from "Providers/ArtistProvider";
import { Concert } from "Providers/ListProvider/types";
import { CalendarDisplay } from "Components/CalendarDisplay";
import { Body2, H4 } from "melodies-source/Text";
import { Checkbox } from "melodies-source/Selectable";
import { Button } from "melodies-source/Button";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { getDateTime } from "utils/date";
import { AddConcerts } from "Components";
import { toast } from "react-toastify";
import { Modal, ModalWrapper } from "melodies-source/Modal";
import { EditConcertForm } from "../EditConcertForm";
import { DateTime } from "luxon";
import { Tab, Tabs } from "../common";
import { useListContext } from "Hooks/useListContext";
import { getMarketBounds } from "../getMarketBounds";
import { TourDateMap } from "./TourDateMap";
import { useGenerateText } from "Hooks";
import { doc, updateDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { Spinner } from "melodies-source/Spinner";

export const concertsToMapPoints = (
  concerts: Concert[],
  bounds?: google.maps.LatLngBounds,
) => {
  const result: PlaceDetail[] = [];
  if (concerts) {
    for (const concert of concerts) {
      const date = getDateTime(concert.date);
      const isInsideBounds = !bounds ? true : bounds.contains(concert.position);
      if (date > DateTime.now().startOf("day") && isInsideBounds) {
        result.push({
          ...concert,
          id: concert.id,
          position: concert.position,
          date,
        });
      }
    }
  }
  return result;
};

interface Queue {
  loading: boolean;
  tasks: PlaceDetail[];
}

export const TourDates = () => {
  const [view, setView] = useState<"desktop" | "mobile">("desktop");
  const [edit, setEdit] = useState<boolean>(false);
  const [addTourDate, setAddTourDate] = useState<boolean>(false);
  const [editTourDate, setEditTourDate] = useState<PlaceDetail | null>(null);
  const { artist, overrides, setOverride } = useArtist();
  const { list, listId } = useListContext();
  const { getVenueOverview } = useGenerateText();
  const firestore = useFirestore();
  const [queue, setQueue] = useState<Queue>({ loading: false, tasks: [] });

  const marketBounds = useMemo(
    () => getMarketBounds(list?.markets),
    [list?.markets],
  );

  const concerts = useMemo(
    () => concertsToMapPoints(artist?.concerts),
    [artist?.concerts],
  );

  const visibleConcerts = concerts?.filter(({ hidden }) => !hidden);

  const handleAddConcerts = (concerts: PlaceDetail[], isEdit?: boolean) => {
    const concertObj = concerts.reduce((acc, concert) => {
      acc[concert.id] = {
        ...concert,
        date: concert.date.toISO(),
        hidden: false,
      };
      return acc;
    }, {});

    setOverride("concerts", { ...overrides?.concerts, ...concertObj });
    toast.success(
      isEdit
        ? "Tour date updated successfully."
        : `${concerts.length} Tour Date${
            concerts.length === 1 ? "" : "s"
          } added to list.`,
    );
    setEditTourDate(null);
    setAddTourDate(null);
  };

  const handleGenerateText = async (concert: PlaceDetail) => {
    setQueue((prev) => ({
      ...prev,
      loading: true,
    }));
    const response = await getVenueOverview({
      artistName: artist.title || artist.name,
      venues: {
        [concert.id]: {
          name: concert.title,
          address: concert.address,
        },
      },
    });
    const result = response?.[concert.id];
    if (result) {
      const fieldPath = `concerts.${concert.id}`;
      const concertUpdate = {
        description: result.description || concert.description,
        capacity: result.capacity || 0,
        date: concert.date.toISO(),
      };
      setQueue((prev) => ({
        ...prev,
        tasks: prev.tasks.slice(1),
      }));
      // Store place results in local overrides for immediate access
      setOverride(fieldPath, {
        ...concert,
        ...concertUpdate,
        hidden: false,
      });
      // Save place results to Firestore for future use in case the user unchecks before saving
      try {
        if (!artist?.id) return;
        await updateDoc(
          doc(
            firestore,
            "ame_sharelists",
            listId,
            "artist_overrides",
            artist.id.toString(),
          ),
          {
            [fieldPath]: {
              ...concert,
              ...concertUpdate,
            },
          },
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (queue.tasks.length === 0) return;
    if (queue.loading) return;

    const task = queue.tasks[0];
    handleGenerateText(task)
      .catch((error) => {
        console.log("Generate overview error: ", error);
      })
      .finally(() => {
        setQueue((prev) => ({ ...prev, loading: false }));
      });
  }, [queue]);

  const handleCheck = (v: boolean, concert: PlaceDetail) => {
    if (v && !concert.description) {
      setQueue((prev) => ({
        ...prev,
        tasks: [...prev.tasks, concert],
      }));
    }
    setOverride(`concerts.${concert.id}.hidden`, !v);
  };

  return (
    <>
      <Wrapper>
        <FlexColumn xStart style={{ minHeight: 0 }}>
          <List>
            {concerts?.map((c) => (
              <ConcertDetail
                {...c}
                key={c.id}
                onChange={(v) => handleCheck(v, c)}
                checked={!overrides?.concerts?.[c.id]?.hidden}
                onEdit={() => setEditTourDate(c)}
                loading={Boolean(queue.tasks?.find(({ id }) => id === c.id))}
              />
            ))}
          </List>
          <AddTourButton
            leftIcon={<SvgAddAlt />}
            variant="basicOutline"
            onClick={() => setAddTourDate(true)}
          >
            Add Tour Dates
          </AddTourButton>
        </FlexColumn>
        <Preview>
          <Tabs>
            <Tab
              style={{ borderRadius: "18px 0 0 18px" }}
              active={view === "desktop"}
              leftIcon={<SvgDesktopLarge />}
              disabled={edit}
              {...(!edit && { onClick: () => setView("desktop") })}
            >
              Desktop
            </Tab>
            <Tab
              style={{ borderRadius: "0 18px 18px 0" }}
              active={view === "mobile"}
              leftIcon={<SvgMobile />}
              disabled={edit}
              {...(!edit && { onClick: () => setView("mobile") })}
            >
              Mobile
            </Tab>
          </Tabs>

          {marketBounds && (
            <TourDateMap
              places={visibleConcerts}
              bounds={marketBounds}
              variant={view}
              onEdit={() => setEdit(true)}
              onClose={() => setEdit(false)}
            />
          )}
        </Preview>
      </Wrapper>
      <StyledModal
        isOpen={addTourDate}
        onClose={() => setAddTourDate(null)}
        header="Add Tour Dates"
      >
        <AddConcerts
          onSubmit={(concert) => handleAddConcerts([concert])}
          onBulkSubmit={handleAddConcerts}
          onCancel={() => setAddTourDate(null)}
        />
      </StyledModal>
      <StyledModal
        isOpen={Boolean(editTourDate)}
        onClose={() => setEditTourDate(null)}
        header="Edit Tour Date"
      >
        <EditConcertForm
          concert={editTourDate}
          onSubmit={(concert) => handleAddConcerts([concert], true)}
          onCancel={() => setEditTourDate(null)}
        />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  ${ModalWrapper} {
    overflow: initial;
    max-height: initial;
    border-radius: 0;
    padding: 24px 32px;
  }
`;

const AddTourButton = styled(Button)`
  width: auto;
  min-width: 120px;
  gap: 8px;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 44px;
`;

const List = styled(FlexColumn)`
  flex-grow: 1;
  padding-top: 4px;
  gap: 20px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  min-height: 0;
  max-height: 443px;
  margin-top: -2px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.15);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(126, 133, 139, 0.26);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Preview = styled(FlexColumn)``;

type ConcertProps = {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  onEdit?: VoidFunction;
  loading?: boolean;
} & PlaceDetail;

const ConcertDetail = ({
  checked,
  onChange,
  onEdit,
  date,
  title,
  address,
  city,
  state,
  loading,
}: ConcertProps) => {
  return (
    <ConcertContainer>
      <Checkbox onChange={onChange} value={checked} isDisabled={loading} />
      <FlexRow gap="12px" yCenter>
        <CalendarDisplay date={date} />
        <FlexColumn yCenter>
          <H4>{title}</H4>
          <Body2>{city && state ? `${city}, ${state}` : address}</Body2>
        </FlexColumn>
      </FlexRow>
      <FlexRow yCenter xCenter>
        {loading ? (
          <Spinner />
        ) : (
          <Button variant="basicText" onClick={() => onEdit?.()}>
            Edit
          </Button>
        )}
      </FlexRow>
    </ConcertContainer>
  );
};

const ConcertContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr 40px;
  column-gap: 20px;
  padding-right: 32px;
  width: 100%;
  min-width: 0;

  ${FlexRow}, ${FlexColumn} {
    width: 100%;
    min-width: 0;
  }

  ${H4} {
    font-family: "Poppins";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  ${Body2} {
    font-family: "Poppins";
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 36px;
  }

  ${Body2} {
    font-weight: 500;
  }

  ${H4} {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }
`;
