import { Button } from "melodies-source/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SvgLeft } from "melodies-source/Svgs/Left";

export const EditArtistActions = ({ onSave, listId }) => {
  const navigate = useNavigate();
  return (
    <ActionContainer>
      <ButtonContainer>
        <Button
          variant="basicText"
          w={150}
          leftIcon={<SvgLeft />}
          onClick={() => navigate(`/admin/${listId}`)}
          gap="8px"
        >
          Back to List
        </Button>
        <Button variant="basic" w={150} onClick={() => onSave()}>
          Save
        </Button>
      </ButtonContainer>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  width: clamp(900px, 80vw, 1170px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto 20px;
  & > h1 {
    font-weight: 800;
  }
  @media (max-width: 950px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 950px) {
    width: 90%;
    margin: 20px auto 0;
  }
`;
