import { MapSettings } from "Components/Maps";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { Option } from "melodies-source/Autocomplete";

export const Eras = [
  "40s",
  "50s",
  "60s",
  "70s",
  "80s",
  "90s",
  "00s",
  "10s",
  "20s",
  "30s",
] as const;
export type Era = (typeof Eras)[number];

export interface Coords {
  lat: number;
  lon: number;
}

const MarketTypes = ["DMA", "State", "Country"] as const;
export type MarketType = (typeof MarketTypes)[number];

const ListTypes = ["Vetted", "Sample"] as const;
export type ListType = (typeof ListTypes)[number];

export interface Market {
  id: number | string;
  name: string;
  type: MarketType;
  coords: Coords;
  bounds?: google.maps.LatLngBoundsLiteral;
}

export interface ShareLists {
  client: string;
  campaign: string;
  introduction?: string;
  description?: string;
  notes?: string;
  imageUrl: string;
  backgroundImageUrl?: string;
  publishedStart?: DateTime;
  publishedEnd?: DateTime;
  hasPassword: boolean;
  updatedAt: Timestamp;
  createdAt: Timestamp;
  displayDate?: Timestamp;
  createdBy?: { uid: string | number; name: string };
  expiresAt?: Timestamp;
  sortKeys: (string | number)[];
  fileHash: string;
  campaignGoal?: string;
  targetPersona?: string;
  artistCount?: string;
  attributes?: string[];
  markets?: Market[];
  marketType?: MarketType;
  published?: boolean;
  type?: ListType;
  id?: string;
  order?: number[];
  overview?: string;
  thumbnailAlign?: Alignment;
  engagementType?: "tourDates" | "digitalEngagement";
}

export interface ShareList {
  artists: { [id: string]: Artist };
  settings: Settings;
}

export interface Artist {
  id: number;
  artist_management: ArtistManagement;
  artist_weight: number;
  audience_attributes_type: string;
  audience_attributes: AudienceAttribute[];
  audience_size: string;
  audience: any[];
  background_html: string | false;
  background_last_updater: any[];
  charts?: HiddenProp;
  concerts: ArtistConcert[];
  description: string;
  eras_display: string;
  eras: Era[];
  followers?: FollowerOverride;
  genres: Genre[];
  image: string;
  is_trending: number;
  locations: (Location | { title: string })[];
  market?: HiddenProp;
  name?: string;
  notes: any[];
  overview?: Overview;
  primaryGenre?: string;
  score: number;
  services: Services;
  sound?: HiddenProp;
  styles: any[];
  tags: any[];
  title: string;
  top_markets: TopMarket[];
  track?: Track;
  trending_audience: any;
  trending_blog_posts: any[];
}

const AlignmentTypes = [
  "circle cover",
  "circle scale",
  "oval cover",
  "oval scale",
] as const;
type Alignment = (typeof AlignmentTypes)[number];

export interface Settings {
  order: number[];
  artist_overrides: ArtistOverrides;
  thumbnail_alignment: Alignment;
  background_alignment: string;
}

export interface ArtistOverrides {
  [id: string]: ArtistOverride;
}

export interface HiddenProp {
  hidden?: boolean;
}

export type Concerts = { [id: string]: Concert };

export interface ArtistOverride {
  id: string;
  audience_size?: string;
  audience?: Audience;
  biography?: Biography;
  concerts?: Concerts;
  eras?: string[];
  followers?: FollowerOverride;
  highlights?: string[];
  image?: string;
  locations?: { title: string }[];
  maps?: Maps;
  market?: HiddenProp;
  marketTour?: HiddenProp;
  overview?: Overview;
  primaryGenre?: string;
  sound?: SoundOverride;
  title?: string;
  track?: Track;
  website?: Wikipedia;
  wikipedia?: Wikipedia;
}

type SoundOverride = {
  // genres?: (null | HiddenProp)[];
  [id: string | number]: HiddenProp;
} & { hidden?: boolean };

interface FollowerOverride {
  twitter?: HiddenProp;
  youtube?: HiddenProp;
  facebook?: HiddenProp;
  spotify?: HiddenProp & Spotify;
  wikipedia?: HiddenProp;
  instagram?: HiddenProp;
  soundcloud?: HiddenProp;
  tiktok?: HiddenProp & TikTok;
  hidden?: boolean;
}

export interface Audience {
  gender_hidden?: boolean;
  ethnicity_hidden?: boolean;
  income_hidden?: boolean;
  age_hidden?: boolean;
  attributes_hidden?: boolean;
  hidden?: boolean;
  attributes?: { [id: string | number]: HiddenProp };
  selected_attributes?: number[];
  only_display_overindex?: boolean;
  display_over_average?: boolean;
}

export interface Overview {
  hidden: boolean;
  html: string;
}

export interface Track {
  id?: string;
  type?: "spotify" | "spotify-artist" | "youtube" | "soundcloud";
  hidden?: boolean;
}

export interface Biography {
  hidden: boolean;
  html?: string;
}

export interface ArtistManagement {
  booking_agent: string;
  record_label: string;
  general_manager: any;
  website: string;
  wikipedia: string;
}

export interface AudienceAttribute {
  id: string;
  value: number;
  title: string;
  description: string;
  category: string;
  subcategory: string;
  family: string;
  selected: boolean;
  hidden: boolean;
  scaledValue?: number;
}

export interface Genre {
  id: number;
  title: string;
  title_exact: string;
  tree_title: string;
  tree_title_exact: string;
  weight_float: number;
  raw_weight_float: number;
  type: number;
  parents: any[];
}

export interface Services {
  twitter: Twitter;
  youtube: Youtube;
  facebook: Facebook;
  spotify: Spotify;
  wikipedia: Wikipedia;
  instagram: Instagram;
  soundcloud: Soundcloud;
  tiktok?: TikTok;
  website?: Wikipedia;
  hidden?: boolean;
}

export interface Twitter {
  id: number;
  url: string;
  title: string;
  username: string;
  username_exact: string;
  followers: number;
  friends: number;
  description: string;
  image_url: string;
  hidden?: boolean;
}

export interface Youtube {
  id: string;
  url: string;
  views: number;
  subscribers: number;
  videos: number;
  hidden?: boolean;
}

export interface Facebook {
  id: number;
  title: string;
  title_exact: string;
  url: string;
  about: string;
  website: string;
  likes: number;
  hidden?: boolean;
}

export interface Spotify {
  id: string;
  url: string;
  title: string;
  title_exact: string;
  followers: number;
  listeners?: number;
  popularity: number;
  image_url: string;
  hidden?: boolean;
}

export interface Wikipedia {
  id: number;
  title: string;
  url: string;
}

export interface Instagram {
  id: number;
  title: string;
  url: string;
  username: string;
  username_exact: string;
  followers: number;
  hidden?: boolean;
}

export interface Soundcloud {
  id: string;
  username: string;
  username_exact: string;
  followers: number | string;
  url: string;
  track_count: number;
  hidden?: boolean;
}

export interface TikTok {
  followers: number;
  url: string;
  hidden?: boolean;
}

export interface TopMarket {
  id: number;
  title: string;
  audience_size: number;
  value: number;
  location_type: string;
  code: number;
  score: number;
  coords: Coords;
  location: GeoPoint;
}

export interface Coords {
  lon: number;
  lat: number;
}

export interface GeoPoint {
  type: "point";
  coordinates: [latitude: number, longitude: number];
}

export interface Location {
  id: number;
  title: string;
  weight: number;
  coords: Coords;
  location: GeoPoint;
}

export type ConcertVenue = {
  coords?: Coords;
  state?: string;
  id?: string;
  city?: string;
  title?: string;
  location?: GeoPoint;
};

export type ArtistConcert = {
  id?: string | number;
  date?: string;
  title?: string;
  description?: string;
  image_url?: string;
  venue?: ConcertVenue;
  capacity?: number;
};

export interface Concert extends Partial<ArtistConcert> {
  imageUrl?: string;
  address?: string;
  placeId?: string;
  position?: google.maps.LatLngLiteral;
  hidden?: boolean;
}

type ResponsiveMapSettings = {
  desktop?: MapSettings;
  mobile?: MapSettings;
};

export type Maps = {
  listenership?: ResponsiveMapSettings;
  tourDates?: ResponsiveMapSettings;
};
