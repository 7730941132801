import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FullscreenModal } from "../FullscreenModal";
import { ListItem as ListItemBase } from "../ListItem";
import { SelectMenu } from "../SelectMenu";
import { Spinner } from "../Spinner";
import { Caption, H2, Label as LabelBase, P } from "../Text";
import { TextInput } from "../TextInput";
import { Div, DivThemeCSSProps, useClickOutside } from "../utils";
import { SvgSearch } from "../Svgs/Search";
import { SvgCloseLarge } from "../Svgs/CloseLarge";

const ListItem = styled(ListItemBase)(
  ({ theme: { colors } }) => css`
    :hover {
      cursor: pointer;
      background: ${colors.gray2};
    }
  `,
);

type ContainerProps = {
  isFullscreen?: boolean;
};
const Container = styled(Div)<ContainerProps>(
  ({ isFullscreen }) => css`
    ${isFullscreen &&
    css`
      height: 100%;
      display: grid;
      width: 100%;
      grid-template-rows: auto auto 1fr;
    `}
  `,
);

const CloseIcon = styled(SvgCloseLarge)`
  :hover {
    ${(p) => p.theme.css.clickable};
  }
`;

const Label = styled(LabelBase)`
  color: ${(p) => p.theme.colors.black70};
`;

type ListItemWrapperProps = {
  isFirst: boolean;
};
const ListItemWrapper = styled(Div)<ListItemWrapperProps>`
  ${(p) =>
    !p.isFirst &&
    css`
      border-top: solid 1px ${(p) => p.theme.colors.gray3};
    `}
`;

export type Option = {
  label: string;
  value: string;
  icon?: JSX.Element;
  data?: any;
};

export type OptionProps = {
  isSelected?: boolean;
} & Option;
const OptionDefault = ({ label, isSelected }: OptionProps) => {
  return <ListItem isSelected={isSelected}>{label}</ListItem>;
};

type BaseProps = {
  label?: string;
  fullscreenConfig?: {
    isOpen: boolean;
    onClose: () => void;
    headerUI?: JSX.Element;
  };
  placeholder?: string;
  CustomOption?: React.FC<OptionProps>;
  value?: Option;
  helperText?: string;
  hasError?: boolean;
  onClear?: () => void;
  headerUI?: JSX.Element;
  onChange?: (o: Option) => void;
} & DivThemeCSSProps;
type AutocompleteProps = BaseProps & {
  text: string;
  setText: Function;
  options: Option[];
  isLoading?: boolean;
} & DivThemeCSSProps;
export const AutocompleteBase = ({
  label,
  fullscreenConfig,
  placeholder = "Select Option",
  value,
  hasError,
  text,
  isLoading,
  CustomOption,
  setText,
  onChange,
  onClear,
  options,
  helperText,
  ...props
}: AutocompleteProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const OptionComponent = CustomOption || OptionDefault;
  const isFullscreen = !!fullscreenConfig;
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  useClickOutside(referenceElement, () => setIsMenuOpen(false));

  const onSelect = (o: Option) => {
    setText(o.label);
    onChange?.(o);
    setIsMenuOpen(false);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      setText("");
    }
  };

  const optionsUI = isLoading ? (
    <Spinner mt={50} mb={50} />
  ) : (
    <>
      {!options?.length && (
        <P m={20}>
          No results were found. Please check your spelling or try a new search…
        </P>
      )}
      {options?.map((o, index) => (
        <ListItemWrapper
          key={index}
          isFirst={index === 0}
          onClick={() => onSelect(o)}
        >
          <OptionComponent {...o} isSelected={value?.value === o.value} />
        </ListItemWrapper>
      ))}
    </>
  );

  const mainUI = (
    <Container isFullscreen={isFullscreen} {...props} ref={setReferenceElement}>
      {isFullscreen && (
        <Div>
          <H2 mb={10}>{label}</H2>
          {fullscreenConfig?.headerUI}
        </Div>
      )}
      {!isFullscreen && <Label>{label}</Label>}
      <Div mt={4}>
        <TextInput
          hasError={hasError}
          leftIcon={<SvgSearch />}
          rightIcon={!!value ? <CloseIcon onClick={handleClear} /> : undefined}
          placeholder={placeholder}
          value={text}
          onChange={(v) => setText(v)}
          onFocus={() => setIsMenuOpen(true)}
        />
      </Div>
      {isFullscreen ? (
        <Div yscroll scrollbarDark mt={10}>
          {optionsUI}
        </Div>
      ) : (
        <>
          <SelectMenu
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            onClickOutside={() => {}}
          >
            {optionsUI}
          </SelectMenu>
          {helperText && (
            <Caption hasError={hasError} mt={4}>
              {helperText}
            </Caption>
          )}
        </>
      )}
    </Container>
  );

  return (
    <>
      {fullscreenConfig ? (
        <FullscreenModal
          isOpen={fullscreenConfig.isOpen}
          onClose={fullscreenConfig.onClose}
        >
          {mainUI}
        </FullscreenModal>
      ) : (
        mainUI
      )}
    </>
  );
};

export const AutocompleteAsync = ({
  getOptions,
  text,
  setText,
  ...props
}: BaseProps & {
  getOptions: (text: string) => Promise<Option[]>;
  text: string;
  setText: Function;
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let cancel = false;
    (async () => {
      setIsLoading(true);
      const _options = await getOptions(text);
      if (!cancel) {
        setOptions(_options);
        setIsLoading(false);
      }
    })();
    return () => {
      cancel = true;
    };
  }, [text]);

  return (
    <AutocompleteBase {...{ isLoading, text, setText, options, ...props }} />
  );
};

export const Autocomplete = ({
  options,
  ...props
}: BaseProps & { options: Option[] }) => {
  const [text, setText] = useState("");

  const filteredOptions = options?.filter((o) =>
    o.label.toLowerCase().includes(text.toLowerCase()),
  );

  return (
    <AutocompleteBase
      {...{ text, setText, options: filteredOptions, ...props }}
    />
  );
};
