import SvgCalendarOutline from "assets/svg/calendar.svg?react";
import { DateTime } from "luxon";
import styled from "styled-components";

export const CalendarDisplay = ({ date }: { date: DateTime }) => {
  return (
    <CalendarWrapper>
      <DayOfWeek>{date.toFormat("ccc")}</DayOfWeek>
      <Container>
        <Month>{date.toFormat("LLL")}</Month>
        <Day>{date.toFormat("dd")}</Day>
        <Year>{date.year}</Year>
      </Container>
      <CalendarImage />
    </CalendarWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  z-index: 1;
  padding-top: 2px;
`;

const DayOfWeek = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 7px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 5px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 1;
  margin: 3px 0;
  opacity: 0.8;

  ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.3px;
    line-height: 8px;
    font-size: 7px;
  }
`;

const Month = styled.p`
  color: #333;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 10px;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.8;

  ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.5px;
    line-height: 9px;
    font-size: 8px;
  }
`;

const Day = styled.p`
  color: var(--secondary-color);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 16px;
    line-height: 16px;
  }
`;

const Year = styled(Month)`
  font-size: 8px;
  line-height: 8px;
  opacity: 0.7;
`;

const CalendarImage = styled(SvgCalendarOutline)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: #333;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  position: relative;
  width: 54px;
  height: 54px;
  margin-top: -1px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 42px;
    height: 44px;
    padding-top: 8px;
    margin-top: -2px;
  }
`;
