import styled, { css } from "styled-components";
import { useListContext } from "Hooks/useListContext";
import logo from "Components/Assets/Logo.png";
import logoMax from "Components/Assets/logoMax.png";
import logoPattern from "Components/Assets/LogoPattern.png";
import { Card } from "melodies-source/Card/index";
import { Caption, H2, H3, P, Subtitle1 } from "melodies-source/Text/index";
import { Button } from "melodies-source/Button/index";
import { useNavigate, useParams } from "react-router-dom";
import ListSummary from "Components/ListSummary";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgCareerStage4 } from "melodies-source/Svgs/CareerStage4";
import { SvgCareerStageLegacy } from "melodies-source/Svgs/CareerStageLegacy";
import { SvgMusic } from "melodies-source/Svgs/Music";
import { SvgRight } from "melodies-source/Svgs/Right";
import Client from "Components/Assets/Icons/client.svg?react";
import Calendar from "Components/Assets/Icons/calendar.svg?react";
import { DateTime } from "luxon";
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getTopGenre } from "Providers/ArtistProvider/mergeOverrides";

export const Landing = () => {
  const { list, artists, loadAnimation, setLoadAnimation } = useListContext();
  const navigate = useNavigate();
  const { listId } = useParams();
  const isMobile = useMediaQuery();
  const animationContainer = useRef<HTMLDivElement>(null);
  const [windowInnerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showDescription, setShowDescription] = useState<boolean>(false);

  useEffect(() => {
    const _setInnerWidth = () => setInnerWidth(window.innerWidth);
    _setInnerWidth();
    window.addEventListener("resize", _setInnerWidth);
    return () => {
      window.removeEventListener("resize", _setInnerWidth);
    };
  }, []);

  useLayoutEffect(() => {
    let ctx: gsap.Context = gsap.context(() => {
      const tl: gsap.core.Timeline = gsap.timeline();
      if (artists) {
        if (isMobile) {
          tl.to("#content", { opacity: 1, duration: 0.1 })
            .to(".tab", { stagger: 0.2, opacity: 1, y: 10 })
            .to("#logoMobile", {
              opacity: 1,
              duration: 0.5,
              left: 0,
              right: "-3%",
            })
            .to("#logoPattern", {
              opacity: 1,
              duration: 0.1,
            });
        } else {
          if (loadAnimation) {
            tl.to("#slider", { duration: 1, xPercent: -100 })
              .to("#logo", { opacity: 1, duration: 0.4, left: 0, right: 0 })
              .to("#slider", { duration: 1, xPercent: 0 })
              .to("#logo", {
                opacity: 1,
                duration: 0.4,
                right: "-100px",
              })
              .to("#content", { opacity: 1, duration: 0.1 })
              .to(".tab", { stagger: 0.2, opacity: 1, y: 10 })
              .to("#logoPattern", {
                opacity: 1,
                duration: 0.1,
              });
          } else {
            tl.to("#logo", {
              opacity: 1,
              duration: 0.4,
              right: "-100px",
            })
              .to("#content", { opacity: 1, duration: 0.1 })
              .to(".tab", { stagger: 0.2, opacity: 1, y: 10 })
              .to("#logoPattern", {
                opacity: 1,
                duration: 0.1,
              });
          }
        }
        setLoadAnimation(false);
      }
      tl.play();
    });
    return () => ctx.revert();
  }, [artists]);

  return (
    <Wrapper ref={animationContainer}>
      <Slider id="slider"></Slider>
      <Logo src={logo} alt="" id="logo" />
      <LogoMobile src={logo} alt="" id="logoMobile" />
      <LogoPattern src={logoPattern} alt="" id="logoPattern" />
      <Content as="section" id="content">
        <BrandContainer>
          <MaxLogo src={logoMax} alt="" id="logo" />
          <H2>{list?.campaign}</H2>
          <BrandDetails>
            <LogoBox hasSummary={false} isOval={false}>
              <BrandLogo src={list?.imageUrl ?? logo} isContain={true} />
            </LogoBox>
            <BrandInfo>
              <Details>
                <Client />
                <H3>{list?.client}</H3>
              </Details>
              <Details>
                <Calendar />
                <H3>
                  {DateTime.fromJSDate(list?.createdAt?.toDate()).toFormat(
                    "LLL dd, yyyy",
                  )}
                </H3>
              </Details>
            </BrandInfo>
          </BrandDetails>
          <ListSummary summary={list?.introduction ?? list?.overview} />
          <Highlight
            alignLeft={windowInnerWidth > 1500}
            vetted={(list?.type || "") === "Vetted"}
          >
            <Subtitle1>
              {(list?.type || "") === "Vetted" ? "Vetted" : "Sample"} Artists
            </Subtitle1>
          </Highlight>
          <Description>
            {(list?.type || "") === "Vetted" ? (
              <>
                <Subtitle1 style={{ color: "#000" }}>
                  Expires:{" "}
                  {DateTime.fromJSDate(list?.expiresAt?.toDate()).toFormat(
                    "MM/dd/yy",
                  )}
                </Subtitle1>
                <Caption>
                  Vetted Artists have been verified against the provided
                  criteria for this program; inclusive of verification that each
                  artist has existing fan bases that match the desired consumer
                  demographic, each artist does not currently have any existing
                  brand collaborations/partnerships that pose conflict and have
                  been verified against general background and brand
                  friendliness.
                  {!showDescription ? (
                    <>
                      ..{" "}
                      <span onClick={() => setShowDescription(true)}>more</span>
                    </>
                  ) : (
                    ` All vetted artists have confirmed interest in
                  the program and tentative availability against the campaign
                  timeline, and have tentatively agreed to accept the
                  partnership upon presentation of any formal offer by
                  MAX/Brand. NOTE: This list expires on
                  ${DateTime.fromJSDate(list?.expiresAt?.toDate()).toFormat(
                    "MM/dd/yy",
                  )}
                  in conjunction with the timeline you have been provided to
                  ensure the program’s targeted launch.`
                  )}
                </Caption>
              </>
            ) : (
              <Caption>
                NOTE: This Sample Artist List is provided for example purposes
                to demonstrate the data available in the MAX Platform™ and the
                relative size/type of artist we recommend for your program. The
                list is subject to change based on campaign needs, artist
                availability, changes in audience attributes, competing brand
                partnerships, and other factors.
                {!showDescription ? (
                  <>
                    ..{" "}
                    <span onClick={() => setShowDescription(true)}>more</span>
                  </>
                ) : (
                  ` Once we receive program approval, our Artist Relations team will begin our vetting process (which includes reaching out to the artists to ensure availability, authentic brand affinity, interest in the opportunity, and more), and a Fully Vetted Artist List will be submitted for your review.`
                )}
              </Caption>
            )}
          </Description>
        </BrandContainer>
        <ArtistListContainer id="listContent" shortList={artists?.length < 4}>
          {artists?.map((artist, i) => {
            let truncatedEras = [
              artist?.eras?.slice(0, 1),
              " - ",
              artist?.eras?.slice(-1),
            ];
            const genres = artist?.primaryGenre || getTopGenre(artist?.genres);
            const title = artist.title || artist.name;
            const nameCheck = title?.length > 27;
            if (artists?.length < 4) {
              return (
                <ArtistCard
                  isElevated={true}
                  key={i}
                  className="tab"
                  shortList={artists?.length < 4 && artists?.length !== 1}
                  singleArtist={artists?.length === 1}
                >
                  <img src={artist?.image} alt="featured artist" />
                  <InfoList
                    background={artist?.image}
                    shortList={artists?.length < 4 && artists?.length !== 1}
                    singleArtist={artists?.length === 1}
                  >
                    {isMobile && (
                      <img src={artist?.image} alt="featured artist" />
                    )}
                    <TitleDiv>
                      <TitleH3 longName={nameCheck}>{title}</TitleH3>
                    </TitleDiv>
                    <MobileList>
                      <div>
                        {genres && (
                          <div>
                            <P>
                              <SvgMusic />
                              {genres}
                            </P>
                          </div>
                        )}
                        {!!artist?.locations?.length && (
                          <div>
                            <P>
                              <SvgLocation />
                              {artist?.locations
                                ?.map(({ title }) => title)
                                .slice(0, 1)}
                            </P>
                          </div>
                        )}
                        {!!artist?.eras?.length && (
                          <div>
                            <P>
                              <SvgCareerStageLegacy />
                              {artist?.eras?.length > 2
                                ? truncatedEras
                                : artist?.eras?.join(", ")}
                            </P>
                          </div>
                        )}
                        {!!artist?.audience_size && (
                          <div>
                            <P>
                              <SvgCareerStage4 />
                              {artist?.audience_size}
                            </P>
                          </div>
                        )}
                      </div>
                    </MobileList>
                    <ButtonWrapper>
                      <StyledButton
                        shortList={artists?.length < 4}
                        variant="primary"
                        rightIcon={<SvgRight />}
                        onClick={() => navigate(`/${listId}/${artist?.id}`)}
                        vetted={(list?.type || "") === "Vetted"}
                      >
                        View Artist
                      </StyledButton>
                    </ButtonWrapper>
                  </InfoList>
                </ArtistCard>
              );
            } else {
              return (
                <ArtistCard isElevated={true} key={i} className="tab">
                  <img src={artist?.image} alt="featured artist" />
                  <InfoList background={artist?.image}>
                    {isMobile && (
                      <img src={artist?.image} alt="featured artist" />
                    )}
                    <TitleDiv>
                      <TitleH3 longName={nameCheck}>{title}</TitleH3>
                    </TitleDiv>
                    <MobileList>
                      <div>
                        {genres && (
                          <div>
                            <P>
                              <SvgMusic />
                              {genres}
                            </P>
                          </div>
                        )}
                        {!!artist?.locations?.length && (
                          <div>
                            <P>
                              <SvgLocation />
                              {artist?.locations
                                ?.map(({ title }) => title)
                                .slice(0, 1)}
                            </P>
                          </div>
                        )}
                        {!!artist?.eras?.length && (
                          <div>
                            <P>
                              <SvgCareerStageLegacy />
                              {artist?.eras?.length > 2
                                ? truncatedEras
                                : artist?.eras?.join(", ")}
                            </P>
                          </div>
                        )}
                        {!!artist?.audience_size && (
                          <div>
                            <P>
                              <SvgCareerStage4 />
                              {artist?.audience_size}
                            </P>
                          </div>
                        )}
                      </div>
                      <StyledButton
                        variant="primary"
                        rightIcon={<SvgRight />}
                        onClick={() => navigate(`/${listId}/${artist?.id}`)}
                        vetted={(list?.type || "") === "Vetted"}
                      >
                        View Artist
                      </StyledButton>
                    </MobileList>
                  </InfoList>
                  <FloatButton>
                    <StyledButton
                      variant="primary"
                      rightIcon={<SvgRight />}
                      onClick={() => navigate(`/${listId}/${artist?.id}`)}
                      vetted={(list?.type || "") === "Vetted"}
                    >
                      View Artist
                    </StyledButton>
                  </FloatButton>
                </ArtistCard>
              );
            }
          })}
        </ArtistListContainer>
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  opacity: 0;
  z-index: 2;
  position: relative;
  @media (max-width: 950px) {
    flex-direction: column;
    margin: auto;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  overflow: hidden;
`;

const BrandContainer = styled.div`
  display: flex;
  width: 30%;
  max-width: 500px;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
  padding: 50px;
  & > h2 {
    font-weight: 800;
  }
  @media (max-width: 950px) {
    width: 100%;
    margin: auto;
    max-width: 700px;
    padding: 20px;
  }
`;

const BrandDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  opacity: 1;
  z-index: 2;
  & > img {
    width: 50%;
    max-width: 300px;
  }
  @media (max-width: 950px) {
    flex-direction: row;
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    span {
      visibility: visible;
    }
  }

  & + & {
    ${H3} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const Highlight = styled.div<{ alignLeft: boolean; vetted?: boolean }>`
  background-color: black;
  width: calc(100% + 50px);
  padding: 10px 10px 10px 50px;
  transform: translateX(-50px);
  ${Subtitle1} {
    color: #fff;
    border-left: ${({ vetted }) =>
      vetted ? "4px solid #0240FB;" : "4px solid #fa017d;"};
    padding-left: 10px;
  }
  ${({ alignLeft }) =>
    alignLeft &&
    css`
      width: 100%;
      padding: 10px 10px 10px 15px;
      transform: translateX(0px);
    `}
  @media (max-width: 950px) {
    width: calc(100% + 200px);
    padding: 10px 10px 10px 200px;
    transform: translateX(-200px);
  }
`;

const MaxLogo = styled.img`
  width: 100%;
  max-width: 100px;
  object-fit: contain;
  margin-bottom: 40px;
`;

const Slider = styled.div`
  background-color: #000;
  width: 100%;
  display: grid;
  place-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
`;

const Logo = styled.img`
  object-fit: cover;
  opacity: 0;
  max-width: 200px;
  position: absolute;
  top: 35%;
  right: -10%;
  scale: 3;
  margin: auto 0 auto auto;
  z-index: 1;
  @media (max-width: 1250px) and (min-width: 951px) {
    top: 320px;
  }
  @media (max-width: 950px) {
    display: none;
  }
`;

const LogoMobile = styled.img`
  display: none;
  @media (max-width: 950px) {
    display: block;
    object-fit: cover;
    opacity: 0;
    position: absolute;
    right: -20%;
    margin: auto 0 auto auto;
    z-index: 1;
    width: 20%;
    max-width: 150px;
    top: 0;
  }
`;

const LogoPattern = styled.img`
  object-fit: cover;
  opacity: 0;
  max-width: 300px;
  position: absolute;
  top: 40%;
  left: 150px;
  scale: 2;
  margin: auto auto auto 0;
  z-index: 1;
  @media (max-width: 950px) {
    top: 400px;
  }
`;

const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  width: 200px;
  border-radius: 0px;
  box-shadow: 0px 0px 15px 10px #e7e7e7;
  overflow: hidden;

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-bottom: 10px;
  }
`;

const BrandLogo = styled.img<{ isContain: boolean }>`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
`;

const BrandInfo = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 10px;
  margin: auto 0;
  h2 {
    @media (max-width: 950px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  span {
    margin-top: 20px;
    font-size: 15px;
    color: #666666;
  }
  h3 {
    font-weight: 600;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-self: flex-start;
`;

const TitleH3 = styled(H3)<{ longName: boolean }>`
  font-family: Archivo;
  font-size: 23px;
  font-weight: 800;
  color: #fff;

  ${(props) =>
    props.longName &&
    css`
      font-size: 15px;
      @media (max-width: 950px) {
        font-size: 13px;
      }
    `}
`;

const MobileList = styled.div`
  button {
    display: none;
  }
  @media (max-width: 950px) {
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
    }
  }
`;

type InfoListProps = {
  background: string;
  shortList?: boolean;
  singleArtist?: boolean;
};

const InfoList = styled.div<InfoListProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  left: 0;
  bottom: 0;
  height: 170px;
  min-height: fit-content;
  width: 100%;
  padding: 20px 14px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  ${TitleH3} {
    margin-bottom: 15px;
  }
  ${({ shortList }) =>
    shortList &&
    css`
      position: relative;
      padding: 30px;
      height: unset;
      background: rgba(0, 0, 0, 1);
      ${TitleH3} {
        margin-bottom: 25px;
      }
    `}
  ${({ singleArtist }) =>
    singleArtist &&
    css`
      @media (min-width: 1250px) {
        position: relative;
        padding: 30px;
        height: unset;
        background: rgba(0, 0, 0, 1);
        ${TitleH3} {
          margin-bottom: 25px;
        }
      }
    `}
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 235px;
    flex-direction: column;
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: -2px;
    overflow: hidden;
    img {
      display: block;
      position: absolute;
      filter: blur(10px) brightness(15%);
      scale: 1.1;
      left: 0;
      bottom: 5%;
      z-index: 0;
      object-position: bottom;
      aspect-ratio: 1.44;
    }
    div {
      z-index: 5;
    }
    ${({ shortList }) => shortList && "height: unset; padding: 20px;"}
  }
  & > div {
    display: flex;
    flex-direction: row;

    ${P} {
      font-size: 12px;
      color: #fff;
    }
    svg {
      margin: 5px 10px -5px 0;
    }
    & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  & > div {
    margin-top: -10px;
    @media (max-width: 950px) {
      margin-top: 0px;
      flex-direction: column;
    }
  }
  & > div:first-child {
    /* flex-grow: 1; */
  }
  div:nth-child(2n) {
    justify-content: flex-end;
  }
`;

const ArtistListContainer = styled.div<{ shortList?: boolean }>`
  padding: 135px 10vw 50px 0;
  z-index: 2;
  display: grid;
  opacity: 1;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  & ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  ${({ shortList }) =>
    shortList &&
    css`
      grid-template-columns: 1fr;
    `}
  ${({ shortList }) =>
    !shortList &&
    css`
      @media (max-width: 1250px) and (min-width: 951px) {
        grid-template-columns: 1fr;
        max-width: 700px;
        margin: auto;
        max-height: unset;
      }
    `}
  @media (max-width: 950px) {
    padding: 20px;
    grid-template-columns: 1fr;
    max-height: unset;
    max-width: 700px;
    margin: auto;
  }
`;

type ArtistCardProps = {
  isElevated: boolean;
  shortList?: boolean;
  singleArtist?: boolean;
};

const ArtistCard = styled(Card)<ArtistCardProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  opacity: 0;
  padding: 0px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
  }
  ${({ shortList }) =>
    shortList &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    `}
  ${({ singleArtist }) =>
    singleArtist &&
    css`
      @media (min-width: 1250px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }
    `}
  @media (max-width: 950px) {
    height: unset;
    width: 100%;
    flex-direction: column;
    & > img {
      max-height: 400px;
      object-fit: cover;
    }
    ${({ shortList, singleArtist }) =>
      (shortList || singleArtist) &&
      css`
        min-height: unset;
        grid-template-columns: 1fr;
        img {
          max-height: 400px;
          max-width: unset;
        }
      `}
  }
  h3 {
    margin: 0px 0 5px 0;
  }
  div > p {
    font-size: 12px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: auto !important;
  width: 100%;
  padding-top: 10px;
`;
const FloatButton = styled.div`
  margin-top: auto !important;
  width: 50%;
  padding-top: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  @media (max-width: 950px) {
    display: none;
  }
`;

const StyledButton = styled(Button)<{
  variant: string;
  shortList?: boolean;
  vetted?: boolean;
}>`
  width: 135px;
  height: 35px;
  border-radius: 22px;
  align-self: center;
  margin-left: auto;
  font-size: 15px;
  background-color: transparent;
  border: ${({ vetted }) =>
    vetted ? "2px solid #0240FB;" : "2px solid #fa017d;"};
  color: #fff;
  &:hover {
    background-color: rgba(256, 256, 256, 0.1);
  }
  ${({ shortList }) =>
    shortList &&
    css`
      margin-left: 0;
      width: 100%;
    `}
  svg {
    height: 16px;
    margin: 0 -10px 0 5px !important;
  }
  @media (max-width: 950px) {
    width: 100%;
    padding: 10px 12px;
    margin: 10px 0 5px 0;
  }
`;

const TimeContainer = styled.div`
  position: relative;
  &:hover {
    span {
      visibility: visible;
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 8px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`;
