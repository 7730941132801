import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { FlexColumn, FlexRow } from "Components/Flex";
import { getContent } from "./getContent";
import { Button } from "melodies-source/Button";
import styled from "styled-components";
import { useListContext } from "Hooks/useListContext";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { ArtistOverviewResponse, useGenerateText } from "Hooks";
import { Id, toast } from "react-toastify";
import { useState } from "react";
import { Checkbox } from "melodies-source/Selectable";
import { Spinner } from "melodies-source/Spinner";
import { useFirestore } from "reactfire";

type DiffCheckProps = {
  attributes?: boolean;
  markets?: boolean;
};

type ConfirmUpdateModalProps = {
  isOpen?: boolean;
  onClose?: VoidFunction;
  diffCheck?: DiffCheckProps;
};

export const ConfirmUpdateModal = ({
  diffCheck = {},
  onClose,
  ...props
}: ConfirmUpdateModalProps) => {
  const [updateDiff, setUpdateDiff] = useState<DiffCheckProps>(diffCheck);
  const [mapUpdateLoading, setMapUpdateLoading] = useState(false);
  const [overviewUpdateLoading, setOverviewUpdateLoading] = useState(false);
  const { title, description } = getContent(diffCheck);
  const { artists, listId, list } = useListContext();
  const { token, getArtistOverview, getCampaignOverview } = useGenerateText();
  const loading = mapUpdateLoading || overviewUpdateLoading;
  const db = useFirestore();

  const updateArtistMaps = async () => {
    const toastId = toast.loading("Updating map settings...");
    try {
      setMapUpdateLoading(true);
      const promises = [];
      for (const artist of artists) {
        promises.push(
          updateDoc(
            doc(db, `ame_sharelists/${listId}/artist_overrides/${artist.id}`),
            { maps: deleteField() },
          ),
        );
      }
      await Promise.all(promises);

      toast.update(toastId, {
        render: "Recalculating tour dates within selected markets...",
      });

      const response = await fetch(`/api/list/${listId}/recalculate_markets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "POST",
      });
      const concertResults = await response.json();

      if (concertResults && Object.keys(concertResults).length) {
        toast.update(toastId, {
          render: "Tour dates for each artist have been updated.",
          type: "success",
          autoClose: 5000,
          isLoading: false,
        });
      } else {
        toast.update(toastId, {
          render:
            "There was an issue saving updated tour dates for each artist.",
          type: "error",
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render:
          "There was an issue recalculating tour dates within selected markets.",
        type: "error",
        isLoading: false,
      });
    } finally {
      setMapUpdateLoading(false);
    }
  };

  const updateArtistOverviews = async (toastId?: Id) => {
    toast.update(toastId, { render: "Generating artist overviews..." });
    const promises: Promise<ArtistOverviewResponse | undefined>[] = [];
    for (const artist of artists) {
      promises.push(
        getArtistOverview({
          artistId: artist.id,
          brandName: list.client,
          brandObjective: list.campaignGoal,
        }),
      );
    }
    const overviewResults = await Promise.all(promises);

    if (overviewResults) {
      toast.update(toastId, {
        render: "Updating artists...",
      });
      try {
        const updateDocPromises = [];
        for (const result of overviewResults) {
          updateDocPromises.push(
            updateDoc(
              doc(
                db,
                `ame_sharelists/${listId}/artist_overrides/${result.artistId}`,
              ),
              {
                overview: {
                  html: result.overview,
                  hidden: false,
                },
                highlights: result.highlights,
              },
            ),
          );
        }

        await Promise.all(updateDocPromises);
        toast.update(toastId, {
          render: "Artists updated.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: "We encountered an issue while saving each artist overview.",
          type: "error",
          isLoading: false,
        });
      }
    } else {
      toast.update(toastId, {
        render:
          "We encountered an issue when fetching updated artist overviews.",
        type: "error",
        isLoading: false,
      });
    }
  };

  const updateOverview = async () => {
    setOverviewUpdateLoading(true);
    const toastId = toast.loading("Generating campaign overview...");
    try {
      const result = await getCampaignOverview({
        client: list.client,
        goal: list.campaignGoal,
        target: list.targetPersona,
        type: (list.type ?? "Sample").toLowerCase() as "vetted" | "sample",
        artistCount: list.artistCount,
      });
      if (result) {
        toast.update(toastId, { render: "Updating campaign..." });
        await updateDoc(doc(db, `ame_sharelists/${listId}`), {
          overview: result.overview,
        });
        await updateArtistOverviews(toastId);
      } else {
        toast.update(toastId, {
          isLoading: false,
          type: "error",
          render: "There was an issue generating campaign overview.",
        });
      }
    } catch (error) {
      toast.update(toastId, {
        isLoading: false,
        type: "error",
        render: "There was an issue storing generated text.",
      });
      console.error(error);
    } finally {
      setOverviewUpdateLoading(false);
    }
  };

  const handleConfirm = async () => {
    if (updateDiff.markets) {
      await updateArtistMaps();
    }
    if (updateDiff.attributes) {
      await updateOverview();
    }
    onClose();
  };

  return (
    <Modal header={title} onClose={onClose} {...props}>
      <Content>
        <Body1>{description}</Body1>
        {diffCheck.attributes && diffCheck.markets && (
          <FlexColumn style={{ marginTop: 16 }}>
            <Checkbox
              label="Update Generated Text"
              onChange={(v) =>
                setUpdateDiff((curr) => ({ ...curr, attributes: v }))
              }
              value={updateDiff.attributes}
            />
            <Checkbox
              label="Update Map View"
              onChange={(v) =>
                setUpdateDiff((curr) => ({ ...curr, markets: v }))
              }
              value={updateDiff.markets}
            />
          </FlexColumn>
        )}
      </Content>
      <FlexRow xEnd gap="14px">
        <Button variant="basicOutline" onClick={onClose} isDisabled={loading}>
          No Thanks
        </Button>
        <Button variant="basic" onClick={handleConfirm} isDisabled={loading}>
          {loading ? <Spinner /> : "Update"}
        </Button>
      </FlexRow>
    </Modal>
  );
};

const Content = styled(FlexColumn)`
  padding: 20px 0;
  min-height: 200px;
`;
