import { PlaceDetail } from "Components/Maps";
import { getLatLngLiteral } from "Components/Maps/components/helpers";
import { PlacesAutocompleteResult } from "Components/PlacesAutocomplete";
import { DateTime } from "luxon";

export const createConcertFromPlace = (
  selected: PlacesAutocompleteResult,
  showDate?: DateTime,
  id?: string | number,
): PlaceDetail => {
  const { result, placeName, timeZone, addressType } = selected;
  const { lat, lng } = getLatLngLiteral(result.geometry.location);
  const date = showDate || DateTime.now().plus({ days: 1 });
  return {
    id: id || `manual-${Math.random().toString(36).substring(2)}`,
    address: result.formatted_address,
    title: placeName,
    addressType,
    position: { lat, lng },
    timeZone,
    date: date.setZone(timeZone),
  };
};
