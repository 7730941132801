export const GOOGLE_PLACES_SEARCH_TYPES = [
  "bar",
  "night_club",
  "point_of_interest",
  "route",
  "premise",
];
export const GOOGLE_PLACES_ESTABLISHMENT_TYPES = [
  "bar",
  "establishment",
  "night_club",
  "point_of_interest",
];

export const googlePrediction = async (input: string, token: string) => {
  const url = new URL(
    "/maps/api/place/autocomplete/json",
    import.meta.env.VITE_MAPS_URL.replace(/\/$/, ""),
  );

  url.searchParams.set("input", input);

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  if (!json.success || json.data.status !== "OK") {
    throw new Error("Autocomplete request failed");
  }

  return json.data.predictions;
};

export const googleGeocoder = async (placeId: string, token: string) => {
  const url = new URL(
    "/maps/api/geocode/json",
    import.meta.env.VITE_MAPS_URL.replace(/\/$/, ""),
  );

  url.searchParams.set("place_id", placeId);
  url.searchParams.set("language", "en");

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  if (!json.success || json.data.status !== "OK") {
    throw new Error("Geocode request failed");
  }

  return json.data.results;
};

export const GOOGLE_MAP_PIN_ICON = {
  path: `M9 4C11.7614 4 14 6.23858 14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4ZM9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5Z
  M9.65773 21.7533L9 22.3276L8.34227 21.7533C2.81131 16.9237 0 12.7068 0 9C0 3.75066 4.09705 0 9 0C13.9029 0 18 3.75066 18 9C18 12.7068 15.1887 16.9237 9.65773 21.7533ZM1 8.92599C1 12.1521 3.63613 16.2106 9 21C14.3639 16.2106 17 12.1521 17 8.92599C17 4.27978 13.3839 1 9 1C4.61613 1 1 4.27978 1 8.92599Z`,
  fillOpacity: 1,
  fillColor: "#000000",
  scale: 1.4,
};

export interface TimeZoneResponse {
  status:
    | "OK"
    | "INVALID_REQUEST"
    | "OVER_DAILY_LIMIT"
    | "OVER_QUERY_LIMIT"
    | "REQUEST_DENIED"
    | "UNKNOWN_ERROR"
    | "ZERO_RESULTS";
  dstOffset?: number;
  errorMessage?: string;
  rawOffset?: number;
  timeZoneId?: string;
  timeZoneName?: string;
}

const getTimeZoneByGeocode = async (
  lat: number,
  lng: number,
  token: string,
): Promise<TimeZoneResponse> => {
  const url = new URL(
    "/maps/api/timezone/json",
    import.meta.env.VITE_MAPS_URL.replace(/\/$/, ""),
  );

  url.searchParams.set("location", `${lat},${lng}`);
  url.searchParams.set("timestamp", Date.now().toString().substring(0, 10));

  const response = await fetch(url.toString(), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();
  return json?.data;
};

export const getTimeZoneIdByGeocode = async (
  lat: number,
  lng: number,
  token: string,
) => {
  const timeZoneResponse = await getTimeZoneByGeocode(lat, lng, token);

  let timeZoneId: string | undefined = undefined;
  if (timeZoneResponse.status === "OK") {
    timeZoneId = timeZoneResponse.timeZoneId;
  }

  return timeZoneId;
};
