import { contiguousUSBounds } from "utils";
import { MapPoint, PlaceDetail } from "../types";

export const convertToMapPoints = (
  current?: any[],
  bounds?: google.maps.LatLngBounds,
) => {
  const result: MapPoint[] = [];
  if (current) {
    for (const point of current) {
      const { lat, lon } = point.coords || {};
      const isInsideBounds = !bounds
        ? true
        : bounds.contains({ lat, lng: lon });
      if (isInsideBounds && !isNaN(Number(lat)) && !isNaN(Number(lon))) {
        result.push({
          id: point.id.toString(),
          density: point.value / 5,
          position: { lat: lat, lng: lon },
          radius: Math.min(
            100000,
            Math.pow(point.audience_size || 0, 1.5) * 5000,
          ),
          title: point.title,
          value: point.value,
        });
      }
    }
  }

  return result;
};

export const getBounds = (points: Partial<PlaceDetail>[], usOnly?: boolean) => {
  if (!points?.length) {
    return;
  }
  const bounds = new google.maps.LatLngBounds();
  for (const { position } of points) {
    const usBounds = new google.maps.LatLngBounds(contiguousUSBounds);
    if (usOnly && !usBounds.contains(position)) {
      continue;
    }
    bounds.extend(position);
  }
  return bounds;
};

export const getLatLngLiteral = (
  position: google.maps.marker.AdvancedMarkerElementOptions["position"],
): google.maps.LatLngLiteral => {
  return {
    lat: typeof position.lat === "function" ? position.lat() : position.lat,
    lng: typeof position.lng === "function" ? position.lng() : position.lng,
  };
};

export const fromLatLngToPixel = (position, map: google.maps.Map) => {
  const scale = Math.pow(2, map.getZoom());
  const proj = map.getProjection();
  const bounds = map.getBounds();

  const nw = proj.fromLatLngToPoint(
    new google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng(),
    ),
  );
  const point = proj.fromLatLngToPoint(position);

  return new google.maps.Point(
    Math.floor((point.x - nw.x) * scale),
    Math.floor((point.y - nw.y) * scale),
  );
};

type ClusterOptionsWithRange = {
  minRange: number;
  maxRange?: number;
  extent: number;
  radius: number;
  label: string;
  id: string;
};

export const clusterOptionsByRange: ClusterOptionsWithRange[] = [
  {
    id: "1",
    label: "Single Market 1",
    minRange: 0,
    maxRange: 50,
    extent: 256,
    radius: 256,
  },
  {
    id: "2",
    label: "Single Market 2",
    minRange: 51,
    maxRange: 130,
    extent: 256,
    radius: 80,
  },
  {
    id: "3",
    label: "Regional 1",
    minRange: 131,
    maxRange: 275,
    extent: 256,
    radius: 80,
  },
  {
    id: "4",
    label: "Regional 2",
    minRange: 276,
    maxRange: 550,
    extent: 256,
    radius: 80,
  },
  {
    id: "5",
    label: "Regional 3",
    minRange: 551,
    maxRange: 1000,
    extent: 256,
    radius: 80,
  },
  {
    id: "6",
    label: "National 1",
    minRange: 1001,
    maxRange: 2000,
    extent: 256,
    radius: 80,
  },
  {
    id: "7",
    label: "National 2",
    minRange: 2001,
    extent: 256,
    radius: 80,
  },
];
