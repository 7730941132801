import styled, { css } from "styled-components";
import phoneMockupImg from "assets/images/phone-frame.png";
import { FlexColumn, FlexRow } from "./Flex";
import { Body2 } from "melodies-source/Text";
import { SvgForward } from "melodies-source/Svgs/Forward";
import SvgMaxLogo from "assets/svg/max-logo.svg?react";

interface PhonePreviewProps {
  children?: React.ReactNode;
  isActive?: boolean;
  title?: string;
}

export const PhonePreview = ({
  children,
  title,
  isActive,
}: PhonePreviewProps) => {
  return (
    <Wrapper>
      <Content isActive={isActive}>
        <FlexColumn style={{ flexGrow: 1 }} yEnd>
          <Title>
            <SvgForward style={{ rotate: "-90deg" }} />
            {title}
          </Title>
          <Description>
            Showing fan listenership and available tour dates at a national
            level.
          </Description>
        </FlexColumn>
        <Mask>{children}</Mask>
        <Footer>
          <SvgMaxLogo />
        </Footer>
      </Content>
      <PhoneImage src={phoneMockupImg} />
    </Wrapper>
  );
};

const Footer = styled(FlexRow)`
  background-color: #000;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  svg {
    width: 40px;
    height: 16px;
  }
`;

const Description = styled(Body2)`
  font-size: 8px;
  line-height: 12px;
  margin: 0 0 12px 12px;
`;

const Title = styled(Body2)`
  color: #333;
  font-weight: 700;
  margin: 0 0 0 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    width: 14px;
    height: 14px;
  }
`;

const Mask = styled.div`
  height: 300px;
  width: 100%;
`;

const Content = styled(FlexColumn)<{ isActive?: boolean }>`
  background-color: #f2f5f7;
  border-radius: 26px;
  overflow: hidden;
  margin: 7px 11px;
  width: calc(100% - 22px);
  height: calc(100% - 14px);
  position: relative;
  z-index: 2;
  justify-content: center;

  ${(p) =>
    p.isActive &&
    css`
      border: 3px solid var(--secondary-color);
    `};
`;

const Wrapper = styled.div`
  width: 224px;
  height: 443px;
  position: relative;
`;

const PhoneImage = styled.img`
  width: 224px;
  height: 443px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;
