import { Body2, Subtitle1, Subtitle2 } from "melodies-source/Text";
import styled from "styled-components";
import MaxLogo from "Components/Assets/MaxLogo.png";
import PencilSvg from "Components/Assets/Icons/pencil.svg?react";
import CircleChecked from "Components/Assets/Icons/circle-checked.svg?react";
import { DateTime } from "luxon";
import { ShareLists } from "Providers/ListProvider/types";
import { useNavigate } from "react-router-dom";

export type ListItemProps = Partial<ShareLists> & { onClick?: VoidFunction };

export const ListItem = ({ onClick, ...data }: ListItemProps) => {
  const navigate = useNavigate();

  return (
    <>
      <ListItemWrapper onClick={onClick}>
        <ClientName className="a">
          <ClientLogo src={data.imageUrl ?? MaxLogo} alt="" />
          <Body2>{data.client}</Body2>
        </ClientName>
        <CampaignDetails className="b">
          <span>Campaign</span>
          <Subtitle1>{data.campaign}</Subtitle1>
          <Subtitle2>{data.targetPersona ?? data.description}</Subtitle2>
        </CampaignDetails>
        <Body2 className="c" style={{ paddingLeft: 10 }}>
          <span>Artists</span>
          {data?.order?.length || "0"}
        </Body2>
        <Body2 className="d">
          <span>Type</span>
          {data?.type ?? "Sample"}
        </Body2>
        <Body2 className="e">
          <span>Created At</span>
          {DateTime.fromJSDate(data?.createdAt?.toDate()).toFormat("MM/dd/yy")}
        </Body2>
        <Body2 className="f">
          <span>Created By</span>
          {data?.createdBy?.name}
        </Body2>
        <Body2
          className="g"
          style={{ margin: "auto", width: "unset", height: 24 }}
        >
          {data.published ? (
            <CircleChecked
              onClick={(e) => {
                window.open(`/${data.id}`, "_blank");
                e.stopPropagation();
              }}
            />
          ) : (
            <PencilSvg
              style={{ color: "#C5CFD6" }}
              onClick={(e) => {
                navigate(`/admin/${data.id}`);
                e.stopPropagation();
              }}
            />
          )}
        </Body2>
      </ListItemWrapper>
    </>
  );
};

const ListItemWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  border-top: 0.5px solid #cccccc;
  gap: 7px;
  padding: 10px 0;
  flex-shrink: 0;
  min-height: 64px;

  &:hover {
    background: #f4f7f9;
  }

  & > p {
    color: black;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    display: none;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    & > div.a {
      grid-area: a;
    }

    & > div.b {
      grid-area: b;
    }

    & > p.c {
      grid-area: c;
      padding-left: 0 !important;
    }

    & > p.d {
      grid-area: d;
    }

    & > p.e {
      grid-area: e;
    }

    & > p.f {
      grid-area: f;
    }

    & > p.g {
      grid-area: g;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: #999999;
      text-transform: uppercase;
    }
    max-width: 520px;
    box-shadow: 0px 0px 15px 2px #cccccc;
    border-top: none;
    padding: 20px 5px 20px 20px;
    row-gap: 15px;
    grid-template-columns: 3fr 2fr 2fr 1fr;
    grid-template-areas:
      "a a . g"
      "b b . ." "c . d ." "e . f .";
  }
`;
const ClientName = styled.div`
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  p {
    font-weight: 500;
    color: black;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    p {
      font-size: 14px;
    }
  }
`;
const CampaignDetails = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h4 {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const ClientLogo = styled.img`
  width: 40px;
  object-fit: contain;
`;
