import { FlexColumn, FlexRow } from "Components/Flex";
import { ClusterMap, PlaceDetail } from "Components/Maps";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SvgFitToScreen } from "assets";
import { useArtist } from "Providers/ArtistProvider";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { useMap } from "@vis.gl/react-google-maps";
import { MapContainer, MapPositionButton } from "../common";
import { getBounds } from "Components/Maps/components/helpers";
import { DraggableSlider, PhonePreview } from "Components";

const mapIds = {
  desktop: "aaef0fe153064376",
  mobile: "6ce66f9ba74e335c",
};

type DesktopProps = {
  places: PlaceDetail[];
  bounds?: google.maps.LatLngBounds;
  variant?: "desktop" | "mobile";
  onEdit?: VoidFunction;
  onClose?: VoidFunction;
};

export const TourDateMap = ({
  places,
  bounds,
  variant = "desktop",
  onEdit,
  onClose,
}: DesktopProps) => {
  const [disableMapControl, setDisableMapControl] = useState<boolean>(true);
  const [clusterRadius, setClusterRadius] = useState<number>(40);
  const { overrides, setOverride } = useArtist();
  const mapId = mapIds[variant];
  const map = useMap(mapId);

  const mapOverrides = overrides?.maps?.tourDates?.[variant];

  useEffect(() => {
    if (mapOverrides?.clusterRadius !== undefined) {
      setClusterRadius(mapOverrides?.clusterRadius);
    }
  }, [mapOverrides]);

  const handleSaveMapPosition = () => {
    if (!map) {
      return;
    }
    setOverride(`maps.tourDates.${variant}`, {
      bounds: map.getBounds()?.toJSON(),
      zoom: map.getZoom(),
      clusterRadius,
    });
    setDisableMapControl(true);
    onClose();
  };

  const mapSettings = useMemo(() => {
    return {
      ...mapOverrides,
      bounds:
        mapOverrides?.bounds ||
        bounds?.toJSON() ||
        getBounds(
          places?.filter(({ hidden }) => !hidden),
          true,
        )?.toJSON(),
    };
  }, [mapOverrides, places, bounds]);

  const renderMap = mapSettings?.bounds && (
    <ClusterMap
      id={mapId}
      places={places}
      bounds={mapSettings?.bounds}
      clusterRadius={clusterRadius}
      disableControl={disableMapControl}
    />
  );

  return (
    <Wrapper>
      {variant === "desktop" ? (
        <MapContainer isActive={!disableMapControl}>{renderMap}</MapContainer>
      ) : (
        <PhonePreview title="Markets" isActive={!disableMapControl}>
          {renderMap}
        </PhonePreview>
      )}

      <MapOptionsContainer>
        <DraggableSlider
          disabled={disableMapControl}
          label="Cluster Radius"
          onChange={setClusterRadius}
          value={clusterRadius}
        />

        {disableMapControl ? (
          <MapPositionButton
            onClick={() => {
              setDisableMapControl(false);
              onEdit();
            }}
          >
            <SvgFitToScreen />
            Edit Map Settings
          </MapPositionButton>
        ) : (
          <FlexRow gap="12px" style={{ alignSelf: "flex-end" }}>
            <MapPositionButton
              isDestructive
              onClick={() => {
                onClose();
                setDisableMapControl(true);
                setClusterRadius(
                  mapOverrides?.clusterRadius !== undefined
                    ? mapOverrides.clusterRadius
                    : 40,
                );
                map?.fitBounds(mapSettings?.bounds, 0);
              }}
            >
              Cancel
            </MapPositionButton>
            <MapPositionButton onClick={handleSaveMapPosition}>
              <SvgCheck />
              Done
            </MapPositionButton>
          </FlexRow>
        )}
      </MapOptionsContainer>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  align-items: center;
`;

export const MapOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 260px 1fr;
  justify-items: flex-end;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  margin-top: 32px;

  ${MapPositionButton} {
    align-self: initial;
  }
`;
