export const SvgLiveLocation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10 5.2a2.4 2.4 0 1 1 0 4.8 2.4 2.4 0 0 1 0-4.8Zm-.8 2.4a.8.8 0 1 1 1.6 0 .8.8 0 0 1-1.6 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10.877 15.004 10 15.77l-.877-.766C5.991 12.27 4.4 9.85 4.4 7.6 4.4 4.417 6.883 2 10 2c3.117 0 5.6 2.417 5.6 5.6 0 2.249-1.59 4.67-4.723 7.404ZM10 3.6c2.243 0 4 1.71 4 4 0 1.638-1.302 3.653-4 6.045C7.302 11.253 6 9.238 6 7.6c0-2.29 1.757-4 4-4Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M4.948 12.244c.329.45.695.907 1.098 1.372-1.49.336-2.446.812-2.446 1.185 0 .646 2.863 1.6 6.4 1.6 3.537 0 6.4-.954 6.4-1.6 0-.373-.956-.85-2.446-1.185.403-.465.77-.922 1.098-1.372C16.85 12.79 18 13.665 18 14.8c0 2.005-3.584 3.2-8 3.2s-8-1.195-8-3.2c0-1.136 1.15-2.011 2.948-2.557Z"
    />
  </svg>
);
