import { useState } from "react";
import { P, Subtitle1 } from "../../melodies-source/Text/index";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

const ListSummary = ({ summary }) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  function sliceToFullWord(str, maxChars) {
    const words = str.split(" ");
    let result = "";
    let charCount = 0;

    for (let word of words) {
      if (charCount + word.length + 1 <= maxChars) {
        result += word + " ";
        charCount += word.length + 1;
      } else {
        break;
      }
    }

    return result.trim();
  }

  return (
    <Summary>
      {summary && (
        <>
          <Subtitle1>Summary</Subtitle1>
          {showDescription || summary?.length < 400 ? (
            <P>{ReactHtmlParser(summary)}</P>
          ) : (
            <P>
              {ReactHtmlParser(sliceToFullWord(summary, 400))}...{" "}
              <span onClick={() => setShowDescription(true)}>more</span>
            </P>
          )}
        </>
      )}
    </Summary>
  );
};
export default ListSummary;

const Summary = styled.div`
  ${Subtitle1},${P} {
    white-space: pre-wrap;
  }
  ${Subtitle1} {
    color: #000;
  }
  ${P} {
    margin-top: 12px;
    color: #000;
  }
  span {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    color: #275dff;
  }
  @media (max-width: 950px) {
    margin-bottom: 20px;
    ${Subtitle1} {
    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
    }
  }
`;
