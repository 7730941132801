import { FlexColumn } from "Components/Flex";
import { H3, H4, Body2, LinkText, Body1 } from "melodies-source/Text";
import defaultPlaceImage from "assets/images/place-image-default.jpg";
import { PlaceDetail } from "../types";
import styled from "styled-components";
import { useState } from "react";

const STRING_MAX_LENGTH = 105;

export const MapPopupContent = ({ place }: { place: PlaceDetail }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isCondensed = place.description?.length >= STRING_MAX_LENGTH;
  const condensedDescription = place.description?.slice(0, STRING_MAX_LENGTH);
  return (
    <Container key={place.id} {...{ height: "auto", minHeight: 380 }}>
      <PlaceImage
        style={{
          backgroundImage: `url(${place.imageUrl || defaultPlaceImage})`,
        }}
      />
      <Content>
        {place.date && <H3>{place.date.toFormat("ccc, LLL d yyyy")}</H3>}
        <Details>
          <H4>{place.title}</H4>
          <Body2>{place.address}</Body2>
        </Details>
        {!!(place.capacity && place.capacity > 0) && (
          <Body2>Capacity: {place.capacity.toLocaleString()}</Body2>
        )}
        {place.description && (
          <Description>
            {isCondensed && !isExpanded
              ? `${condensedDescription}...`
              : place.description}
            {isCondensed && (
              <LinkText onClick={() => setIsExpanded((v) => !v)}>
                {isExpanded ? "less" : "more"}
              </LinkText>
            )}
          </Description>
        )}
      </Content>
    </Container>
  );
};

const Description = styled(Body2)`
  margin-top: 16px;
  ${LinkText} {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #0240fb;
    margin-left: 6px;
    text-decoration: underline;
  }
`;

const PlaceImage = styled.div`
  width: 100%;
  height: 190px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Details = styled(FlexColumn)`
  margin-bottom: 16px;
  max-height: 72px;
  text-overflow: clip;
  overflow: hidden;

  ${Body2} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Content = styled(FlexColumn)`
  padding: 12px 24px 24px;
`;

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 300px;
  height: min-content;
  user-select: none;

  ${H3}, ${Body1}, ${Body2} {
    ${(p) => p.theme.fonts.worksans};
  }

  ${H3} {
    font-weight: 700;
    color: #fa017d;
    margin-bottom: 4px;
  }

  ${H4} {
    display: -webkit-box;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${Body2} {
    font-weight: 400;
    color: #000000;
    line-height: 16px;
  }
`;
