import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { setWith } from "lodash";
import {
  Artist,
  ArtistOverride,
  AudienceAttribute,
  HiddenProp,
} from "../ListProvider/types";
import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { mergeOverrides } from "./mergeOverrides";
import { toast } from "react-toastify";
import { useListContext } from "Hooks/useListContext";

type UpdateArtist = (property: string, value: any) => void;

interface GroupedAttributes {
  age?: AudienceAttribute[];
  ethnicity?: AudienceAttribute[];
  gender?: AudienceAttribute[];
  income?: AudienceAttribute[];
  other?: AudienceAttribute[];
}

export interface UpdatedArtist extends Artist {
  attributes?: GroupedAttributes;
  highlights?: string[];
  tempLocations?: string;
  charts?: HiddenProp;
  marketTour?: HiddenProp;
}
export interface UpdatedArtistOverride extends ArtistOverride {
  tempLocations?: string;
}
interface ArtistContextProps {
  artist?: UpdatedArtist;
  overrides?: UpdatedArtistOverride;
  setArtistOverrides: Dispatch<SetStateAction<UpdatedArtistOverride>>;
  setOverride: UpdateArtist;
  loading: boolean;
  save: VoidFunction;
  saveLoading: boolean;
}

export const ArtistContext = createContext<ArtistContextProps>(
  {} as ArtistContextProps,
);

interface ArtistProviderProps {
  children?: React.ReactNode;
  excludeHiddenMetrics?: boolean;
}

export const ArtistProvider = ({
  children,
  excludeHiddenMetrics,
}: ArtistProviderProps) => {
  const [artistData, setArtistData] = useState<Artist>();
  const [overrides, setArtistOverrides] = useState<UpdatedArtistOverride>();
  const { listId, artistId } = useParams();
  const [listLoading, setListLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const nav = useNavigate();
  const { list } = useListContext();

  const setOverride = (property: string, value: any) => {
    const artistOverrides = { ...overrides };
    const updatedOverrides = setWith(artistOverrides, property, value, Object);
    setArtistOverrides(updatedOverrides);
  };
  const firestore = getFirestore();

  const getImmutableArtist = async () => {
    const artistRef = doc(
      firestore,
      `ame_sharelists/${listId}/artists`,
      artistId,
    ) as DocumentReference<Artist>;
    const artistData = await getDoc(artistRef);
    return artistData.data() as Artist;
  };

  const getMutableArtist = async () => {
    const artistRef = doc(
      firestore,
      `ame_sharelists/${listId}/artist_overrides`,
      artistId,
    ) as DocumentReference<ArtistOverride>;
    const artistData = await getDoc(artistRef);
    return artistData.data() as UpdatedArtistOverride;
  };

  useEffect(() => {
    if (listId && artistId) {
      (async () => {
        setListLoading(true);
        try {
          // get immutable artist data
          const immutableArtist = await getImmutableArtist();
          setArtistData(immutableArtist);

          // get mutable artist overrides
          const mutableArtist = await getMutableArtist();
          if (mutableArtist?.locations?.length) {
            mutableArtist.tempLocations = mutableArtist.locations
              .map((location) => location.title)
              .filter((location) => !!location)
              .join("\n");
          }
          setArtistOverrides(mutableArtist);
        } catch (error) {
          console.error(error);
        } finally {
          setListLoading(false);
        }
      })();
    }
  }, [listId, artistId]);

  const save = async (path?: string) => {
    setSaveLoading(true);
    let overridesUpdate = { ...overrides };
    if (overridesUpdate?.highlights?.length) {
      overridesUpdate.highlights = overridesUpdate?.highlights?.filter(
        (highlight) => !!highlight,
      );
    }
    if (overridesUpdate?.tempLocations) {
      overridesUpdate.locations = overridesUpdate?.tempLocations
        ?.split("\n")
        .reduce((acc: { title: string }[], val: string) => {
          acc.push({ title: val });
          return acc;
        }, []);
      delete overridesUpdate.tempLocations;
    }

    try {
      await setDoc(
        doc(
          getFirestore(),
          `ame_sharelists/${listId}/artist_overrides/${artistId}`,
        ),
        {
          ...overridesUpdate,
        },
        { merge: true },
      );
      toast.success("List has been saved successfully.");
      if (path) {
        nav(path);
      }
    } catch (error) {
      toast.error("There was an issue saving your list. Please try again.");
      console.error(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const artist = useMemo(
    () =>
      mergeOverrides(
        artistData,
        overrides,
        excludeHiddenMetrics,
        list,
      ) as UpdatedArtist,
    [artistData, overrides, list],
  );

  const values = {
    // Merged state of artist data and overrides.
    artist,
    // Local state of artist overrides. Most components should use 'artist'.
    overrides,
    // Use when 'setOverride' is not sufficient for setting override values.
    setArtistOverrides,
    // Default method for updating artist overrides.
    setOverride,
    // True if either artist or artist override data is still being fetched.
    loading: listLoading,
    // Save the current state of overrides to Firestore. Optionally, pass a path to navigate after saving (e.g., save("/admin")).
    save,
    // Loading state for the save request/response from Firestore.
    saveLoading,
  };

  return (
    <ArtistContext.Provider value={values}>{children}</ArtistContext.Provider>
  );
};

export const useArtist = () => useContext(ArtistContext);
