import { Button } from "melodies-source/Button";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import Soundcloud from "Components/Assets/SoundCloud.png";
import PencilSvg from "Components/Assets/Icons/pencil.svg?react";
import CircleChecked from "Components/Assets/Icons/CheckCircleFilled.svg?react";
import { useArtist } from "Providers/ArtistProvider";
import { H4, P } from "melodies-source/Text";
import { EditSocialModal } from "Components/EditSocialModal";
import { socials } from "Components/Assets/Icons/socials";

export const Socials = () => {
  const { artist, setOverride, overrides, save } = useArtist();
  const [isEditOpen, setIsEditOpen] = useState<string>("");

  return (
    <>
      <SocialContainer>
        {!!artist?.services?.facebook?.likes && (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.facebook.hidden",
                artist?.followers?.facebook?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.facebook?.hidden}
          >
            {!artist?.followers?.facebook?.hidden && <StyledCheckmark />}
            <IconWrapper>
              {artist?.followers?.facebook?.hidden ? (
                <socials.FacebookDark />
              ) : (
                <socials.FacebookColor />
              )}
            </IconWrapper>
            <H4>{(artist?.services?.facebook?.likes || 0).toLocaleString()}</H4>
            <P>Likes</P>
          </FanButton>
        )}
        {!!artist?.services?.instagram?.followers && (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.instagram.hidden",
                artist?.followers?.instagram?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.instagram?.hidden}
          >
            {" "}
            {!artist?.followers?.instagram?.hidden && <StyledCheckmark />}
            <IconWrapper>
              {artist?.followers?.instagram?.hidden ? (
                <socials.InstagramDark />
              ) : (
                <socials.InstagramColor />
              )}
            </IconWrapper>
            <H4>
              {(artist?.services?.instagram?.followers || 0).toLocaleString()}
            </H4>
            <P>Followers</P>
          </FanButton>
        )}
        {!!artist?.services?.twitter?.followers && (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.twitter.hidden",
                artist?.followers?.twitter?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.twitter?.hidden}
          >
            {" "}
            {!artist?.followers?.twitter?.hidden && <StyledCheckmark />}
            <IconWrapper>
              <socials.XDark />
            </IconWrapper>
            <H4>
              {(artist?.services?.twitter?.followers || 0).toLocaleString()}
            </H4>
            <P>Followers</P>
          </FanButton>
        )}

        {/* {artist?.services?.spotify?.id && ( */}
        <FanButton
          variant="secondary"
          onClick={() => {
            setOverride(
              "followers.spotify.hidden",
              artist?.followers?.spotify?.hidden ? false : true,
            );
          }}
          hidden={artist?.followers?.spotify?.hidden}
        >
          {" "}
          {!artist?.followers?.spotify?.hidden && <StyledCheckmark />}
          <IconWrapper>
            {artist?.followers?.spotify?.hidden ? (
              <socials.SpotifyDark />
            ) : (
              <socials.SpotifyColor />
            )}
          </IconWrapper>
          <H4>
            {(
              (artist?.services?.spotify?.listeners ??
                artist?.services?.spotify?.followers) ||
              0
            ).toLocaleString()}
          </H4>
          <P>
            {!!artist?.services?.spotify?.listeners ? "Listeners" : "Followers"}
          </P>
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              setIsEditOpen("Spotify");
            }}
          >
            <PencilSvg
              style={{ color: "#0240fb", width: 17, marginRight: 5 }}
            />
            Edit
          </EditButton>
        </FanButton>
        {/* )} */}
        {artist?.services?.youtube?.views && (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.youtube.hidden",
                artist?.followers?.youtube?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.youtube?.hidden}
          >
            {" "}
            {!artist?.followers?.youtube?.hidden && <StyledCheckmark />}
            <IconWrapper>
              {artist?.followers?.youtube?.hidden ? (
                <socials.YouTubeDark />
              ) : (
                <socials.YouTubeColor />
              )}
            </IconWrapper>
            <H4>{(artist?.services?.youtube?.views || 0).toLocaleString()}</H4>
            <P>Views</P>
          </FanButton>
        )}
        {artist?.services?.tiktok?.followers ? (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.tiktok.hidden",
                artist?.followers?.tiktok?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.tiktok?.hidden}
          >
            {" "}
            {!artist?.followers?.tiktok?.hidden && <StyledCheckmark />}
            <IconWrapper>
              {artist?.followers?.tiktok?.hidden ? (
                <socials.TikTokDark />
              ) : (
                <socials.TikTokColor />
              )}
            </IconWrapper>
            <H4>{artist?.services?.tiktok?.followers?.toLocaleString()}</H4>
            <P>Followers</P>
            {artist?.services?.tiktok?.followers && (
              <EditButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditOpen("TikTok");
                }}
              >
                <PencilSvg
                  style={{ color: "#0240fb", width: 17, marginRight: 5 }}
                />
                Edit
              </EditButton>
            )}
          </FanButton>
        ) : (
          <FanButton
            AddSocial
            variant="secondary"
            onClick={() => {
              setIsEditOpen("TikTok");
            }}
          >
            {" "}
            <IconWrapper>
              <socials.TikTokDark style={{ color: "#999999" }} />
            </IconWrapper>
            <H4>
              <SvgAddAlt style={{ width: 13, marginBottom: "-5px" }} /> Add
              TikTok
            </H4>
          </FanButton>
        )}
        {artist?.services?.soundcloud?.followers && (
          <FanButton
            variant="secondary"
            onClick={() => {
              setOverride(
                "followers.soundcloud.hidden",
                artist?.followers?.soundcloud?.hidden ? false : true,
              );
            }}
            hidden={artist?.followers?.soundcloud?.hidden}
          >
            {" "}
            {!artist?.followers?.soundcloud?.hidden && <StyledCheckmark />}
            <IconWrapper>
              <SocialIcon src={Soundcloud} />
            </IconWrapper>
            <H4>
              {(artist?.services?.soundcloud?.followers || 0).toLocaleString()}
            </H4>
            <P>Listeners</P>
          </FanButton>
        )}
      </SocialContainer>
      <EditSocialModal
        isOpen={Boolean(isEditOpen)}
        isEditOpen={isEditOpen}
        setIsOpen={() => setIsEditOpen("")}
        artist={artist}
        setOverride={setOverride}
      />
    </>
  );
};

const EditButton = styled.span`
  position: absolute;
  top: 105%;
  width: 100px;
  color: #0240fb;
  svg {
    color: #0240fb !important;
    margin-top: 6px;
    margin-bottom: -6px;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
  position: relative;
  @media (min-width: 550px) and (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const SocialIcon = styled.img`
  width: 100%;
  margin: 10px auto;
  object-fit: contain;
`;
const StyledCheckmark = styled(CircleChecked)`
  position: absolute;
  top: -8%;
  right: -8%;
`;
const FanButton = styled(Button)<{ hidden?: boolean; AddSocial?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  height: 135px;
  min-width: 100px;
  max-width: 300px;
  background: #ffffff;
  border-radius: 0px;
  border: 2px solid #0240fb;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  p {
    font-size: 13px;
    font-weight: 600;
    color: #000;
  }
  ${({ hidden }) =>
    hidden
      ? css`
          border: 2px solid rgba(0, 0, 0, 0);
          color: #999999 !important;
          h4,
          p,
          svg {
            color: #999999 !important;
          }
          &:hover {
            border: 2px solid rgba(0, 0, 0, 0);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.46);
          }
        `
      : css`
          border: 2px solid #0240fb;
          color: #999999 !important;
          &:hover {
            border: 2px solid #0240fb;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
          }
        `}

  ${({ AddSocial }) =>
    AddSocial &&
    css`
      background: #e3e9ed;
      border: 2px solid rgba(0, 0, 0, 0);
      h4 {
        color: #0240fb;
      }
      & > svg {
        color: #999999 !important;
      }
    `}
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 63px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    color: #000;
  }
`;
