import { FlexColumn, FlexRow } from "Components/Flex";
import { convertToMapPoints } from "Components/Maps/components/helpers";
import { SvgDesktopLarge } from "melodies-source/Svgs/DesktopLarge";
import { SvgMobile } from "melodies-source/Svgs/Mobile";
import { Label } from "melodies-source/Text";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useArtist } from "Providers/ArtistProvider";
import { useListContext } from "Hooks/useListContext";
import { Tab, Tabs } from "../common";
import { ListenershipMap } from "./ListenershipMap";
import { getMarketBounds } from "../getMarketBounds";

export const Listenership = () => {
  const [view, setView] = useState<"desktop" | "mobile">("desktop");
  const [edit, setEdit] = useState<boolean>(false);
  const { artist } = useArtist();
  const { list } = useListContext();

  const marketBounds = useMemo(
    () => getMarketBounds(list?.markets),
    [list?.markets],
  );

  const points = useMemo(
    () => convertToMapPoints(artist?.top_markets),
    [artist?.top_markets],
  );

  const noMarkets = !list?.markets?.length || !Array.isArray(list.markets);

  return (
    <Wrapper>
      <List>
        <Label>
          {noMarkets ? "No markets selected." : "Markets Selected:"}
        </Label>
        {!noMarkets && (
          <ChipContainer gap="8px">
            {list.markets.map((m, i) => (
              <Chip key={`market-chip-${m.id || i}`}>{m.name}</Chip>
            ))}
          </ChipContainer>
        )}
      </List>
      <FlexColumn>
        <Tabs>
          <Tab
            style={{ borderRadius: "18px 0 0 18px" }}
            active={view === "desktop"}
            leftIcon={<SvgDesktopLarge />}
            disabled={edit}
            {...(!edit && { onClick: () => setView("desktop") })}
          >
            Desktop
          </Tab>
          <Tab
            style={{ borderRadius: "0 18px 18px 0" }}
            active={view === "mobile"}
            leftIcon={<SvgMobile />}
            disabled={edit}
            {...(!edit && { onClick: () => setView("mobile") })}
          >
            Mobile
          </Tab>
        </Tabs>

        <ListenershipMap
          points={points}
          bounds={marketBounds}
          variant={view}
          onEdit={() => setEdit(true)}
          onClose={() => setEdit(false)}
        />
      </FlexColumn>
    </Wrapper>
  );
};

const List = styled(FlexColumn)`
  gap: 20px;
  flex-grow: 1;
`;

const ChipContainer = styled(FlexRow)`
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 44px;
`;

const Chip = styled(FlexRow)`
  width: max-content;
  border-radius: 22px;
  height: 44px;
  padding: 10px 20px;
  color: #fff;
  background-color: #01207e;
  ${({ theme }) => theme.fonts.workSans};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
