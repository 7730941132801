import { Concert } from "Providers/ListProvider/types";
import useUserIdToken from "./useUserIdToken";
import { PlaceDetail } from "Components/Maps";
import { getDateTime } from "utils/date";
import { doc, collection, getFirestore } from "firebase/firestore";

type ConcertsAiResponse = {
  concerts: Concert[];
  success: boolean;
};

export const useConcertsAi = () => {
  const userIdToken = useUserIdToken();

  const analyze = async (prompt: string): Promise<PlaceDetail[]> => {
    if (!userIdToken || !prompt) {
      return;
    }

    const response = await fetch("/api/ai/concerts", {
      method: "POST",
      body: JSON.stringify({ prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userIdToken}`,
      },
    });

    const result: ConcertsAiResponse = await response.json();

    const concerts: PlaceDetail[] = [];
    if (result?.success && result?.concerts?.length) {
      for (const concert of result.concerts) {
        concerts.push({
          ...concert,
          id: doc(collection(getFirestore(), "ids")).id,
          date: getDateTime(concert.date),
          position: concert.position,
        });
      }
    }
    return concerts;
  };

  return { analyze };
};
