import React from "react";
import styled, { css } from "styled-components";
import { Caption, Label } from "../Text";
import { Div, DivThemeCSSProps } from "../utils";

export const HTMLTextearea = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-family: "Work Sans";
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  border-radius: 5px;
  background: ${(p) => p.theme.colors.white};
  border: solid 1px ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  padding: 10px 12px;

  resize: none;
  height: 123px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${(p) => p.theme.colors.black80};

  ::placeholder {
    font-size: 12px;
    color: ${(p) => p.theme.colors.black40};
    ${(p: any) =>
      p.isDisabled &&
      css`
        color: ${(p) => p.theme.colors.gray3};
      `};
  }
  :focus {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${(p: any) =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${(p) => p.theme.colors.black40};
      border: solid 1px ${(p) => p.theme.colors.gray3};
    `};

  ${(p: any) =>
    p.hasError &&
    css`
      padding-right: 50px;
      border: solid 2px ${(p) => p.theme.colors.error};
    `};
`;

export const TextAreaLabelWrapper = styled(Div)`
  width: calc(100% - 24px);
  height: 36px;
  padding-bottom: 5px;
  background-color: white;
  /* border: solid 1px black; */
  position: absolute;
  top: 1px;
  ${(props: any) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
`;

type Props = {
  label?: any;
  placeholder?: string;
  value: any;
  onChange: (v: string) => void;
  hasError?: boolean;
  helperText?: string;
  onBlur?: any;
} & DivThemeCSSProps;

export const Textarea = ({
  label = null,
  placeholder = "",
  value,
  onChange,
  hasError = false,
  helperText,
  onBlur = null,
  ...props
}: Props) => {
  return (
    <Div {...props}>
      {label && <Label>{label}</Label>}
      <Div mt={4}>
        <HTMLTextearea
          {...{ onBlur, hasError, placeholder }}
          value={value}
          onChange={(evt) => {
            onChange(evt.target.value);
          }}
        />
        {helperText && (
          <Caption hasError={hasError} mt={4}>
            {helperText}
          </Caption>
        )}
      </Div>
    </Div>
  );
};
