import React from "react";
import maxLogo from "Components/Assets/MaxLogo.png";
import styled from "styled-components";
import { Button } from "../../melodies-source/Button/index";
import { SvgRefreshLarge } from "../../melodies-source/Svgs/RefreshLarge";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { clearFirestoreCache } from "../../Modules/clearFirestoreCache";

interface Props {
  children?: JSX.Element;
  logout?: () => void;
}

export const AdminHeader = ({ children }: Props) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const logout = () =>
    signOut(auth)
      .then(() => {
        clearFirestoreCache();
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  return (
    <Container>
      <Wrapper>
        <Logo src={maxLogo} onClick={() => navigate("/admin")} />
        <Button variant="basicOutline" w={120} onClick={() => logout()}>
          Logout
        </Button>
      </Wrapper>
    </Container>
  );
};

const Logo = styled.img`
  height: 34px;
  margin-left: 0px;
  cursor: pointer;
`;
const Container = styled.div<{ isArtistPage?: boolean }>`
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #e6e9eb;
  z-index: 20;
`;
const Wrapper = styled.div<{ isArtistPage?: boolean }>`
  width: clamp(900px, 90vw, 1500px);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 950px) {
    width: 100%;
  }
`;
