import "./logrocket-init.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Theme, theme } from "../src/melodies-source/Theme";
import { FirebaseAppProvider } from "reactfire";
import { config } from "firebaseConfig";

const updatedTheme = {
  ...theme,
  mediaQueries: {
    ...theme.mediaQueries,
    desktop: `@media all and (min-width: 951px)`,
    mobile: `@media all and (max-width: 950px)`,
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Theme theme={updatedTheme}>
      <FirebaseAppProvider firebaseConfig={config}>
        <App />
      </FirebaseAppProvider>
    </Theme>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
