import * as React from "react";
import { SVGProps } from "react";
export const SvgDesktopLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21,4 C21.5522847,4 22,4.44771525 22,5 L22,16 C22,16.5522847 21.5522847,17 21,17 L13.6,17 C13.6,17.6667724 13.9556683,18.3335447 14.6670049,19.0003171 L17.5,19 C17.7761424,19 18,19.2238576 18,19.5 C18,19.7761424 17.7761424,20 17.5,20 L6.5,20 C6.22385763,20 6,19.7761424 6,19.5 C6,19.2238576 6.22385763,19 6.5,19 L9.33299511,19.0003171 C10.0443317,18.3335447 10.4,17.6667724 10.4,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,5 C2,4.44771525 2.44771525,4 3,4 L21,4 Z M21,5 L3,5 L3,15 L21,15 L21,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
