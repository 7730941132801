import React from "react";
import styled from "styled-components";
import maxLogoWhite from "Components/Assets/MaxLogoWhite.png";
import { DateTime } from "luxon";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isArtistPage?: boolean;
}

export const Footer = ({ isArtistPage, ...props }: Props) => {
  let date = DateTime.now().toFormat("yyyy");
  return (
    <Container {...props}>
      <Wrapper isArtistPage={isArtistPage}>
        <Logo src={maxLogoWhite} />
        <div>
          <p>© {date} Music Audience Exchange</p>
          <div>
            <a
              href="https://www.max.live/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy & Terms of Use
            </a>
            <a
              href="https://www.max.live/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us{" "}
            </a>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const Logo = styled.img`
  height: 25px;

  @media (max-width: 950px) {
    height: 20px;
  }
`;
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #000;
`;
const Wrapper = styled.div<{ isArtistPage: boolean }>`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  ${(props) => props.isArtistPage && "max-width: 1080px;"}

  @media (max-width: 950px) {
    padding-left: 0px;
  }

  p {
    color: grey;
    font-size: 12px;
    margin: 10px 0;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    @media (max-width: 950px) {
      flex-direction: column;
    }
  }

  & > div > div {
    display: flex;
    flex-direction: row;
    margin-right: 20px;

    @media (max-width: 950px) {
      margin-top: 20px;
    }
    a {
      text-decoration: none;
      font-size: 12px;
      color: white;
      margin-left: 20px;
      @media (max-width: 950px) {
        margin: 0 20px 0 0;
        font-size: clamp(10px, 2vw, 12px);
      }
    }
  }
`;
