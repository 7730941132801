import { Button } from "melodies-source/Button";
import { ReactEventHandler, useRef, useState } from "react";
import ReactCrop, {
  Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { getCroppedImg } from "./getCroppedImg";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 14px;
  gap: 20px;
`;

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: 500,
        height: 500,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
interface ImageCropProps {
  imageUrl?: string;
  imageType?: string;
  aspectRatio?: number;
  withAspectRatio?: boolean;
  onCancel?: () => void;
  onCrop?: (img: Blob) => void;
}

export const ImageCrop = ({
  imageUrl,
  imageType,
  aspectRatio = undefined,
  withAspectRatio = true,
  onCancel,
  onCrop,
}: ImageCropProps) => {
  const [crop, setCrop] = useState<Crop>();
  const img = useRef<HTMLImageElement>(null);

  const handleOnLoad: ReactEventHandler<HTMLImageElement> = () => {
    if (img.current) {
      const { width, height } = img.current;
      const newCrop = centerAspectCrop(width, height, 16 / 9);
      setCrop(newCrop);
    }
  };

  const handleSave = async () => {
    if (img.current && crop?.width && crop?.height) {
      const blob = await getCroppedImg(img.current, crop, imageType);

      if (blob) {
        onCrop?.(blob);
      }
    }
  };

  return (
    <Container>
      <ReactCrop
        crop={crop}
        onChange={setCrop}
        aspect={withAspectRatio ? aspectRatio : undefined}
      >
        <img src={imageUrl} ref={img} onLoad={handleOnLoad} alt="crop" />
      </ReactCrop>
      <Actions>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </Actions>
    </Container>
  );
};
