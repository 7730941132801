import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

setupLogRocketReact(LogRocket);

if (import.meta.env.VITE_ENV !== "dev") {
  LogRocket.init("fyqxo2/artist-share-lists", {
    release: import.meta.env.VITE_GIT_SHA,
    network: {
      responseSanitizer: (response) => response,
    },
    mergeIframes: true,
    shouldDebugLog: false,
  });
}
