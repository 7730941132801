import { useState } from "react";
import { ImageCrop } from "./ImageCrop";
import { ImageSelect } from "./ImageSelect";
import { ImageUploader } from "./ImageUploader";

enum ImageUploadStep {
  Select,
  Crop,
  Upload,
}

export interface ImageUploadProps {
  uploadPath: string;
  uploadFileName?: string;
  uploadWithFileExt?: boolean;
  dropzoneDescription?: string;
  aspectRatio?: number | undefined;
  withAspectRatio?: boolean;
  withBackground?: boolean;
  saveBlob?: boolean;
  className?: string;
  onCancel?: () => void;
  onComplete?: (url: any) => void;
}

export const ImageUpload = ({
  uploadPath,
  uploadFileName,
  uploadWithFileExt,
  dropzoneDescription,
  aspectRatio,
  withAspectRatio,
  withBackground,
  className,
  saveBlob,
  onCancel,
  onComplete,
}: ImageUploadProps) => {
  const [step, setStep] = useState(ImageUploadStep.Select);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageType, setImageType] = useState<string>();
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  return (
    <div className={className}>
      {step === ImageUploadStep.Select && (
        <ImageSelect
          dropzoneDescription={dropzoneDescription}
          withBackground={withBackground}
          onSelect={(url, type) => {
            if (!type?.match(/png|jpe?g/gi)) {
              alert('Invalid image type. Please upload PNG or JPG/JPEG');
              return;
            }
            setImageUrl(url);
            setImageType(type);
            setStep(ImageUploadStep.Crop);
          }}
        />
      )}
      {step === ImageUploadStep.Crop && (
        <ImageCrop
          imageUrl={imageUrl}
          imageType={imageType}
          aspectRatio={aspectRatio}
          withAspectRatio={withAspectRatio}
          onCancel={() => {
            setStep(ImageUploadStep.Select);
            onCancel?.();
          }}
          onCrop={(blob) => {
            setImageBlob(blob);
            setStep(ImageUploadStep.Upload);
          }}
        />
      )}
      {step === ImageUploadStep.Upload && (
        <ImageUploader
          blob={imageBlob}
          path={uploadPath}
          fileName={uploadFileName}
          withFileExt={uploadWithFileExt}
          onCancel={() => {
            setStep(ImageUploadStep.Select);
            onCancel?.();
          }}
          saveBlob={saveBlob}
          onComplete={(url) => {
            setTimeout(() => {
              setStep(ImageUploadStep.Select);
              onComplete?.(url);
            }, 1000);
          }}
        />
      )}
    </div>
  );
};
