import { useMediaQuery } from "Hooks/useMediaQuery";
import React from "react";
import styled from "styled-components";

export const ProgressBar = ({ progress }) => {
  const isMobile = useMediaQuery();
  let percentage;
  if (progress === 1) {
    percentage = 100;
  } else {
    percentage = Math.floor(progress * 100);
  }

  const Parentdiv = {
    height: "15px",
    width: "100%",
    backgroundColor: "lightgrey",
    borderRadius: 40,
    margin: "10px 0",
  };

  const Childdiv = {
    height: "15px",
    width: "40vw",
    maxWidth: `430px`,
    background:
      "linear-gradient(to left, #FA017C 2% , #F5027E 14%, #E90584 26%, #D40A8F 39%, #B7119E 52%, #921BB1 65%, #6427C8 78%, #2F34E3 90%, #0240FB 100%)",
    borderRadius: 40,
    position: "absolute",
  };

  const ChilddivMobile = {
    height: "15px",
    width: "76vw",
    maxWidth: `940px`,
    background:
      "linear-gradient(to left, #FA017C 2% , #F5027E 14%, #E90584 26%, #D40A8F 39%, #B7119E 52%, #921BB1 65%, #6427C8 78%, #2F34E3 90%, #0240FB 100%)",
    borderRadius: 40,
    position: "absolute",
  };

  const PercentContainer = {
    height: "15px",
    width: `${percentage}%`,
    background: "transparent",
    borderRadius: 40,
    overflow: "hidden",
    position: "relative",
  };

  return (
    <Wrapper>
      <div style={Parentdiv}>
        {/* @ts-ignore */}
        <div style={PercentContainer}>
          {/* @ts-ignore */}
          <div style={isMobile ? ChilddivMobile : Childdiv}></div>
        </div>
      </div>
      <Percent>
        <p>{percentage}%</p>
      </Percent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const Percent = styled.div`
  width: 50px;
  p {
    margin: 8px 0px -8px 20px;
    font-size: 14px;
  }
  @media (max-width: 950px) {
    width: 20px;
    font-size: 12px;
    margin-right: 10px;
  }
  @media (min-width: 500px) and (max-width: 950px) {
    p {
      margin: 8px 0px -8px 40px;
      font-size: 15px;
    }
  }
`;
