import { useState, useEffect } from "react";
import { Button } from "melodies-source/Button";
import styled, { css } from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { Checkbox, Radio } from "melodies-source/Selectable";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { useNavigate } from "react-router-dom";
import { Div } from "melodies-source/utils";
import { Label } from "melodies-source/Text";
import { SvgClearCircle } from "melodies-source/Svgs/ClearCircle";

export const ListActions = ({
  onSearch = () => {},
  onFilter = () => {},
  onSort = () => {},
}: {
  onSearch: (q: string) => void;
  onFilter: (filters: string[]) => void;
  onSort: (value: string) => void;
}) => {
  const [inputValue, setInputValue] = useState<string>();
  const [sort, setSort] = useState<string>("date_desc");
  const [filters, setFilters] = useState<string[]>([]);
  const [filterActive, setFilterActive] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    onSort(sort);
  }, [sort]);

  const handleSearch = () => {
    onSearch(inputValue || "");
  };

  const clearSearch = () => {
    setInputValue("");
    onSearch("");
  };

  const handleFilter = (value: string, checked: boolean) => {
    let arr = Array.from(filters);
    if (checked) {
      if (!arr.includes(value)) arr.push(value);
    } else {
      arr = arr.filter((s) => s !== value);
    }
    setFilters(arr);
    onFilter(arr);
  };

  return (
    <Div>
      <ActionContainer>
        <Button
          variant="basic"
          w={200}
          onClick={() => navigate("/admin/create-list")}
        >
          + Create List
        </Button>
        <SearchContainer filterActive={filterActive}>
          <TextInput
            placeholder="Search Artist Share Lists by client, campaign or created by"
            onChange={(v: string) => setInputValue(v)}
            value={inputValue}
            leftIcon={<SvgSearch />}
            rightIcon={
              inputValue ? (
                <SvgClearCircle
                  style={{ cursor: "pointer" }}
                  onClick={clearSearch}
                />
              ) : null
            }
            onKeyDown={({ code }) => {
              if (code === "Enter") handleSearch();
            }}
          />
          <Button
            variant="basic"
            w={120}
            onClick={handleSearch}
            className="searchBtn"
          >
            Search
          </Button>
          <Button
            variant="secondary"
            id="filterBtn"
            w={100}
            onClick={() => setFilterActive(!filterActive)}
            rightIcon={
              <SvgDropdown
                style={{
                  marginTop: 2,
                }}
              />
            }
          >
            Filter
          </Button>
        </SearchContainer>
      </ActionContainer>
      {filterActive && (
        <Contained>
          <SortAndFilterContainer>
            <Div style={{ flexGrow: 1 }}>
              <Label>Sort By</Label>
              <Div style={{ marginTop: "10px" }}>
                <Radio
                  variant="radio"
                  value={sort === "recent"}
                  label="Recently Updated"
                  onChange={() => setSort("recent")}
                  style={{ alignItems: "center", marginBottom: 8 }}
                />
                <Radio
                  variant="radio"
                  value={sort === "date_asc"}
                  label="Created Date (ascending)"
                  onChange={() => setSort("date_asc")}
                  style={{ alignItems: "center", marginBottom: 8 }}
                />
                <Radio
                  variant="radio"
                  value={sort === "date_desc"}
                  label="Created Date (descending)"
                  onChange={() => setSort("date_desc")}
                  style={{ alignItems: "center", marginBottom: 8 }}
                />
                <Radio
                  variant="radio"
                  value={sort === "client"}
                  label="Client"
                  onChange={() => setSort("client")}
                  style={{ alignItems: "center", marginBottom: 8 }}
                />
                <Radio
                  variant="radio"
                  value={sort === "campaign"}
                  label="Campaign"
                  onChange={() => setSort("campaign")}
                  style={{ alignItems: "center", marginBottom: 8 }}
                />
              </Div>
            </Div>
            <Div style={{ flexGrow: 1 }}>
              <Label>Show Only</Label>
              <Div style={{ marginTop: "10px" }}>
                <Checkbox
                  value={filters.includes("me")}
                  onChange={(checked) => handleFilter("me", checked)}
                  label="My Artist Lists"
                />
                <Checkbox
                  value={filters.includes("published")}
                  onChange={(checked) => handleFilter("published", checked)}
                  label="Published Lists"
                />
              </Div>
            </Div>
          </SortAndFilterContainer>
        </Contained>
      )}
    </Div>
  );
};

const Contained = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: 0 auto;
  padding: 0 20px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const SortAndFilterContainer = styled.div`
  width: clamp(650px, 75vw, 800px);
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  border-radius: 5px;
  margin-left: auto;
  margin-top: -35px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    max-width: 520px;
    margin: auto;
    padding: 10px;
    margin-top: 0;
  }
`;

const ActionContainer = styled.div`
  width: clamp(900px, 80vw, 1170px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto;
  padding: 0 15px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin: 15px auto 0;
    & > button {
      display: none;
    }
  }
`;
const SearchContainer = styled.div<{ filterActive?: boolean }>`
  width: 65%;
  max-width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  input {
    border-radius: 50px 0 0 50px;
  }
  & > button.searchBtn {
    border-radius: 0 50px 50px 0;
    margin-left: -13px;
    z-index: 10;
    height: 42px;
  }
  & > button#filterBtn {
    border: none;
    box-shadow: none;
  }
  ${({ filterActive }) =>
    filterActive &&
    css`
      & > button#filterBtn > div {
        transform: rotate(180deg);
      }
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    max-width: 520px;
    flex-direction: column;
    input {
      border-radius: 50px;
    }
    & > button.searchBtn {
      border-radius: 50px;
      width: 100%;
      margin-left: 0;
    }
  }
`;
