import { UpdatedArtist } from "Providers/ArtistProvider";
import handleImg from "Components/Assets/handle.png";
import styled, { css } from "styled-components";
import { H3, Subtitle1 } from "melodies-source/Text";
import { SvgMusic } from "melodies-source/Svgs/Music";
import { SvgCareerStageLegacy } from "melodies-source/Svgs/CareerStageLegacy";
import { SvgCareerStage4 } from "melodies-source/Svgs/CareerStage4";
import { Button } from "melodies-source/Button";
import Delete from "Components/Assets/Icons/Delete.svg?react";
import logo from "Components/Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { getTopGenre } from "Providers/ArtistProvider/mergeOverrides";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type ArtistCardProps = {
  listId: string;
  artist: UpdatedArtist;
  filterArtists: (id: number) => void;
};

export const ArtistCard = ({
  listId,
  artist,
  filterArtists,
  ...props
}: ArtistCardProps) => {
  const navigate = useNavigate();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: artist.id,
  });
  return (
    <ArtistChip
      {...props}
      ref={setNodeRef}
      style={{
        opacity: isDragging ? 0.2 : 1,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <DragHandle
        src={handleImg}
        {...attributes}
        {...listeners}
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
      />
      <BrandLogo src={artist?.image ?? logo} isContain={false} />
      <ArtistContainer>
        <H3>{artist?.name || artist?.title}</H3>
        <ArtistDetails>
          <Subtitle1>
            <SvgMusic color="#0240FB" />
            {getTopGenre(artist.genres)}
          </Subtitle1>
          {artist?.eras && (
            <Subtitle1>
              <SvgCareerStageLegacy color="#0240FB" />
              {artist?.eras?.join(", ")}
            </Subtitle1>
          )}
          {artist?.audience_size && (
            <Subtitle1>
              <SvgCareerStage4 color="#0240FB" />
              {artist?.audience_size}
            </Subtitle1>
          )}
        </ArtistDetails>
      </ArtistContainer>
      <Button
        variant="basicText"
        w={100}
        onClick={() => navigate(`/admin/${listId}/${artist.id}`)}
      >
        View/Edit
      </Button>
      <Delete
        style={{ margin: "-3px 0 0 10px" }}
        onClick={() => filterArtists(artist.id)}
      />
    </ArtistChip>
  );
};

const ArtistDetails = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  margin-left: -5px;
  input {
    margin-bottom: 10px;
    width: 100%;
  }
  h4 {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000000;
    font-weight: 500;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    display: none;
  }
`;

const ArtistContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  h3 {
    font-weight: 600;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    h3 {
      font-size: 15px;
    }
  }
`;

const ArtistChip = styled.div`
  width: 100%;
  display: flex;
  height: 90px;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-right: 30px;
  ${(p) => p.theme.mediaQueries.mobile} {
    height: 70px;
    padding-right: 15px;
    & > svg {
      flex-shrink: 0;
    }
  }
`;

const BrandLogo = styled.img<{ isContain: boolean }>`
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  border: 0.5px solid #dcdcdc;
  margin-right: 25px;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-right: 10px;
  }
`;

const DragHandle = styled.img`
  height: 100%;
  object-fit: contain;
`;
