import styled, { css } from "styled-components";
import { Track } from "Providers/ListProvider/types";
import ReactHtmlParser from "react-html-parser";

export const Player = ({ id, type }: Track) => {
  if (!id || !type) {
    return null;
  }

  let embedProps = {};
  if (type === "spotify" || type === "spotify-artist") {
    embedProps = {
      frameBorder: 0,
      allowFullScreen: true,
      allow:
        "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
      loading: "lazy",
      src: `https://open.spotify.com/embed/${
        type === "spotify-artist" ? "artist" : "track"
      }/${id}?utm_source=generator`,
    };
  }
  if (type === "youtube") {
    embedProps = {
      frameBorder: 0,
      allowFullScreen: true,
      allow:
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      src: `https://www.youtube.com/embed/${id}?autoplay=0&cc_load_policy=1`,
    };
  }

  return (
    <Wrapper isVideo={type === "youtube"}>
      <Iframe {...embedProps} />
    </Wrapper>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const Wrapper = styled.div<{ isVideo?: boolean; isSpotifyArtist?: boolean }>`
  display: block;
  position: relative;
  padding-bottom: 152px;
  width: 100%;
  ${(p) =>
    p.isVideo &&
    css`
      padding-bottom: 56.25%;
    `};
  ${(p) =>
    p.isSpotifyArtist &&
    css`
      padding-bottom: 15px;
    `};
  ${Iframe} {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;
