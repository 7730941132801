import React from "react";
import styled, { css } from "styled-components";
import { Div, DivThemeCSSProps } from "../utils";
import { SvgCheck } from "../Svgs/Check";
import { colors } from "../Theme/colors";

const CheckIcon = styled(SvgCheck)`
  height: 14px;
  width: 14px;
`;

const Container = styled(Div)`
  height: 17px;
  width: 17px;
  border: 1px solid ${(props) => props.theme.colors.black20};
  border-radius: 2px;
  ${(props: any) =>
    props.isSelected &&
    css`
      border: 1px solid white;
      background-color: #0240fb;
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {
  isSelected: boolean;
  icon?: any;
} & DivThemeCSSProps;
export const CheckboxInput = ({ isSelected, icon = null, ...props }: Props) => {
  return (
    <Container {...{ isSelected, ...props }} tabIndex={0}>
      {isSelected &&
        (icon || (
          <Div c={colors.white} svgFill={colors.white} alignCenter>
            <CheckIcon />
          </Div>
        ))}
    </Container>
  );
};
