import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Body1, Selected } from "../Text";
import { Div, DivThemeCSSProps } from "../utils";
import { SvgAccordionClosed } from "../Svgs/AccordionClosed";
import { SvgAccordionOpen } from "../Svgs/AccordionOpen";

type SectionType = {
  header: React.ReactNode;
  headerRenderIfActive: React.ReactNode;
  body: React.ReactNode;
  defaultOpen?: boolean;
  markets?: boolean;
};

type Props = {
  sections: SectionType[];
} & DivThemeCSSProps;
export const Accordion = ({ sections, ...props }: Props) => {
  return (
    <Div {...props}>
      {sections.map((section, index) => (
        <Section {...section} key={index} />
      ))}
    </Div>
  );
};

const SectionWrapper = styled(Div)<{ isOpen: boolean; markets?: boolean }>`
  padding: 12px;
  background-color: #f4f7f9;
  margin-bottom: 20px;
  @media (max-width: 950px) {
    & > div + div {
      padding-left: 0;
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      & > div > div > svg {
        transform: rotate(180deg);
      }
    `}
  ${({ markets }) =>
    markets &&
    css`
      padding: 12px 0;
    `}
`;

const Section = ({
  header,
  body,
  defaultOpen,
  markets,
  headerRenderIfActive,
  ...props
}: SectionType) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <SectionWrapper flexColumn {...props} isOpen={isOpen} markets={markets}>
      <Div
        dflex
        alignStart
        clickable
        onClick={() => setIsOpen(!isOpen)}
        pr={markets ? 12 : 0}
      >
        <Div flexColumn justifyCenter mt={8} pr={12} pl={markets ? 12 : 0}>
          <SvgAccordionOpen />
        </Div>
        {typeof body === "string" ? (
          <Selected>{header}</Selected>
        ) : (
          <Div grow>
            <>
              {headerRenderIfActive && isOpen ? headerRenderIfActive : header}
            </>
          </Div>
        )}
      </Div>
      <Div pl={markets ? 0 : 32} flexColumn>
        {isOpen && (typeof body === "string" ? <Body1>{body}</Body1> : body)}
      </Div>
    </SectionWrapper>
  );
};
