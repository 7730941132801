/// <reference types="styled-components/cssprop" />
import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { colors } from "./colors";
import { fonts } from "./fonts";
import "./reset.css";
import { appCss } from "./appCss";
import { mediaQueries, mediaQueryBreakpoints } from "./appCss/mediaQueries";
import GlobalStyle from "./GlobalStyle";

export { colors };

export const theme = {
  colors,
  fonts,
  css: appCss,
  mediaQueries,
  mediaQueryBreakpoints,
};

export type ThemeType = typeof theme;

export const Theme: React.FC<{
  children?: React.ReactNode;
  theme?: ThemeType;
}> = ({ children, ...props }) => (
  <HelmetProvider>
    <ThemeProvider theme={theme} {...props}>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Archivo:wght@100..900&family=Poppins:wght@400;500;600;700&family=Work+Sans:wght@100..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </ThemeProvider>
  </HelmetProvider>
);
