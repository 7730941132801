export const SvgMarkerCluster = ({
  value,
  ...props
}: React.SVGProps<SVGSVGElement> & { value: string | number }) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#0240FB" />
    <text
      fill="white"
      xmlSpace="preserve"
      style={{ whiteSpace: "pre" }}
      fontFamily="Archivo"
      fontSize="16.5517"
      fontWeight="bold"
      letterSpacing="0em"
      textAnchor="middle"
    >
      <tspan x="15.78" y="21.3">
        {value}
      </tspan>
    </text>
  </svg>
);
