import { H2, H4, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import styled, { css } from "styled-components";
import { Modal } from "melodies-source/Modal";
import { ImageUpload as ImageInput } from "Components/ImageUpload";
import { useState } from "react";
import EditImageInput from "Components/Assets/Icons/editImageInput.svg?react";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { Radio } from "melodies-source/Selectable";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import { ShareLists, Market } from "Providers/ListProvider/types";
import { Select } from "Components/Select";
import { Datepicker } from "Components/DatePicker";
import { getDateTime } from "utils/date";
import { DateTime } from "luxon";
import { camelCase, set } from "lodash";
import useUserIdToken from "Hooks/useUserIdToken";
import { countryBounds, usStateBounds } from "utils";

interface EditListFormProps extends React.HTMLAttributes<HTMLDivElement> {
  listId: string;
  list?: Partial<ShareLists>;
  setListDetails?: React.Dispatch<React.SetStateAction<ShareLists>>;
  fullWidth?: boolean;
}

export const EditListForm = ({
  listId,
  list,
  setListDetails,
  fullWidth,
  ...props
}: EditListFormProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputAttr, setInputAttr] = useState<any>();
  const [inputMarket, setInputMarket] = useState<any>();
  const token = useUserIdToken();

  const setList = (property: keyof ShareLists, value: any) => {
    const listDetails = { ...list };
    if (property === "marketType" && value !== list?.marketType) {
      if (value === "Country") {
        listDetails.markets = [
          {
            id: "united-states",
            name: "United States",
            type: "Country",
            ...countryBounds.contiguousUS,
          },
        ];
      } else {
        listDetails.markets = [];
      }
    }
    const updatedList = set(listDetails, property, value);
    setListDetails(updatedList as ShareLists);
  };

  const searchAttrs = async (searchValue: string) => {
    if (searchValue && token) {
      try {
        const data = await fetch(`/api/attribute/search?q=${searchValue}`, {
          headers: { Authorization: `bearer ${token}` },
        });
        const jsonData = await data.json();
        return jsonData?.map((artist) => ({
          ...artist,
          value: artist.title,
          label: artist.title,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const searchMarkets = async (searchValue: string) => {
    if (searchValue && token && list?.marketType) {
      try {
        const data = await fetch(
          `/api/location/search?q=${searchValue}&type=${list.marketType}`,
          {
            headers: { Authorization: `bearer ${token}` },
          },
        );
        const jsonData = await data.json();
        return jsonData?.map((market) => {
          console.log(market);
          return {
            ...market,
            value: `${market.id}`,
            label: market.name,
          };
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <ListWrapper {...props} fullWidth={fullWidth}>
        <ModuleWrapper>
          <ModuleContainer>
            {!fullWidth && <H2>List Details</H2>}
            <ModuleDetailsContent>
              {list?.imageUrl ? (
                <ImageUploadField onClick={() => setIsOpen(true)}>
                  <ClientLogo src={list?.imageUrl} alt="" />
                  <EditImageInput />
                </ImageUploadField>
              ) : (
                <ImageUpload>
                  <Label>List Image</Label>
                  <ImageUploadField onClick={() => setIsOpen(true)}>
                    <EditImageInput />
                  </ImageUploadField>
                </ImageUpload>
              )}

              <div>
                <StyledTextInput
                  label="Campaign Name"
                  placeholder="Please type List Name"
                  value={list?.campaign}
                  onChange={(v) => setList("campaign", v)}
                />
                <StyledTextInput
                  label="Client"
                  placeholder="Please type Client Name"
                  value={list?.client}
                  onChange={(v) => setList("client", v)}
                />
                <StyledSelect
                  label="List Type"
                  onChange={(v) => setList("engagementType", v)}
                  value={list?.engagementType || "tourDates"}
                  options={[
                    { label: "Tour Dates", value: "tourDates" },
                    { label: "Digital Engagement", value: "digitalEngagement" },
                  ]}
                />
                <StyledSelect
                  label="List Status"
                  onChange={(v) => setList("type", v)}
                  value={list?.type || "Sample"}
                  options={[
                    { label: "Sample", value: "Sample" },
                    { label: "Vetted", value: "Vetted" },
                  ]}
                />
                {list?.type === "Vetted" && (
                  <StyledDatepicker
                    label="Expiration Date"
                    onChange={(v) => setList("expiresAt", v)}
                    value={getDateTime(list?.expiresAt || new Date())}
                    placeholder="Pick a date..."
                    minDate={DateTime.now().startOf("day")}
                    type="dateOnly"
                    clearable={false}
                  />
                )}
              </div>
              <div>
                <StyledTextInput
                  label="Campaign Goal"
                  placeholder="Please type Campaign Goal"
                  value={list?.campaignGoal}
                  onChange={(v) => setList("campaignGoal", v)}
                />
                <StyledTextInput
                  label="Target Persona"
                  placeholder="Please type Target Persona"
                  value={list?.targetPersona}
                  onChange={(v) => setList("targetPersona", v)}
                />
                <StyledSelect
                  label="Number of Artists"
                  onChange={(v) => setList("artistCount", v)}
                  value={list?.artistCount || "multi"}
                  options={[
                    { label: "Single Artist", value: "single" },
                    { label: "Multiple Artists", value: "multi" },
                  ]}
                />
              </div>
            </ModuleDetailsContent>
          </ModuleContainer>

          <ModuleContainer>
            <H2>Audience Attributes</H2>
            <ModuleContent>
              <AutocompleteAsync
                label="Attribute Search"
                placeholder="Search for an Attribute"
                value={inputAttr || ""}
                text={inputAttr}
                setText={setInputAttr}
                onChange={(v) => {
                  let update = [...(list?.attributes || [])];
                  setList("attributes", [...update, v.value]);
                  setInputAttr("");
                }}
                getOptions={searchAttrs}
              />
            </ModuleContent>
            <ChipContainer>
              {(list?.attributes || [])?.map((data) => {
                return (
                  <Chip key={data}>
                    <H4>{data}</H4>
                    <SvgCloseLarge
                      style={{ width: 16 }}
                      onClick={() => {
                        let update = [...(list.attributes || [])];
                        update = update.filter((item) => data !== item);
                        setList("attributes", [...update]);
                      }}
                    />
                  </Chip>
                );
              })}
            </ChipContainer>
          </ModuleContainer>

          <ModuleContainer>
            <H2>Market(s)</H2>
            <ModuleContent>
              <AutocompleteAsync
                label="Market Search"
                placeholder="Search for a Market"
                value={inputMarket || ""}
                text={inputMarket}
                setText={setInputMarket}
                onChange={(v) => {
                  const option = v as unknown as Market;
                  if (list?.markets?.find((item) => item.id === option.id)) {
                    return;
                  }

                  const value: Market = {
                    name: option.name,
                    id: option.id,
                    type: option.type,
                    coords: option.coords,
                  };

                  if (value.type !== "Country") {
                    value.name = value.name?.replace(", United States", "");
                  }
                  if (list.marketType === "State") {
                    const stateId = camelCase(value.name);
                    const stateBounds = usStateBounds[stateId]?.bounds;
                    value.bounds = stateBounds;
                  }
                  setList("markets", [
                    ...(list?.markets || []),
                    value,
                  ] as Market[]);
                  setInputMarket("");
                }}
                getOptions={searchMarkets}
                isDisabled={Boolean(list?.marketType === "Country")}
              />
            </ModuleContent>
            <RadioContainer>
              <Radio
                label="Cities (DMA)"
                value={list?.marketType === "DMA"}
                onChange={() => {
                  setList("marketType", "DMA");
                }}
              />
              <Radio
                label="States"
                value={list?.marketType === "State"}
                onChange={() => {
                  setList("marketType", "State");
                }}
              />
              <Radio
                label="Country"
                value={list?.marketType === "Country"}
                onChange={() => {
                  setList("marketType", "Country");
                }}
              />
            </RadioContainer>
            <ChipContainer>
              {(list?.markets || [])?.map((data, index) => {
                return (
                  <Chip
                    key={`__mkt_${data.id || index}`}
                    style={{ backgroundColor: "#01207e" }}
                  >
                    <H4>
                      {(typeof data === "string" ? data : data.name).replace(
                        ", United States",
                        "",
                      )}
                    </H4>
                    <SvgCloseLarge
                      style={{ width: 16 }}
                      onClick={() => {
                        let update = [...(list.markets || [])];
                        update = update.filter((item) => data.id !== item.id);
                        setList("markets", [...update]);
                      }}
                    />
                  </Chip>
                );
              })}
            </ChipContainer>
          </ModuleContainer>
        </ModuleWrapper>
      </ListWrapper>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} variant="small">
        <ImageInput
          uploadPath={`ame_sharelists/${listId}/`}
          aspectRatio={undefined}
          onComplete={(url) => {
            setList("imageUrl", url);
            setIsOpen(false);
          }}
          withAspectRatio
        />
      </Modal>
    </>
  );
};

const StyledDatepicker = styled(Datepicker)`
  margin-bottom: 10px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
`;

const ModuleWrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-bottom: 50px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    gap: 20px;
  }
`;

const ModuleContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 30px;
  background-color: #f4f7f9;
  box-shadow: 0px 4px 20px 0px #d1d0d0;
  & > h2 {
    font-weight: 800;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    padding: 15px 30px 30px;
  }
`;

const ModuleContent = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  & > div {
    width: 100%;
  }
`;

const ModuleDetailsContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 2fr 2fr;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;

const ListWrapper = styled.div<{ fullWidth?: boolean }>`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }

  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;

      ${ModuleDetailsContent} {
        grid-template-columns: 1fr;
        gap: 0;
      }

      ${ModuleContainer} {
        width: 100%;
        padding: 15px 30px 30px;
      }

      ${ModuleWrapper} {
        width: 100%;
        gap: 20px;
      }
    `};
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -10px;
  margin-top: -10px;
  max-width: 400px;
  & > div {
    align-items: center;
  }
`;

const ImageUpload = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 5px;
`;

const ImageUploadField = styled.div`
  height: 190px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px;
  aspect-ratio: 1/1;
  border: 1px solid #d5d5d5;
  border-radius: 1px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  svg {
    z-index: 5;
    position: absolute;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;

const ClientLogo = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  z-index: 1;
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const Chip = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  border-radius: 50px;
  background-color: #000000;
  color: #ffffff;
  width: fit-content;
  padding: 10px 20px;
  h4 {
    color: #ffffff;
  }
`;
