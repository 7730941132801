import { camelCase } from "lodash";
import { Market } from "Providers/ListProvider/types";
import { contiguousUSBounds, usStateBounds } from "utils";

export const getMarketBounds = (markets?: Market[]) => {
  if (!markets?.length) return;

  const bounds = new google.maps.LatLngBounds();
  for (const market of markets) {
    if (market.type === "DMA") {
      bounds.extend({ lat: market.coords.lat, lng: market.coords.lon });
      continue;
    }
    if (market.type === "State") {
      const name = camelCase(market.name);
      const stateBounds = market.bounds || usStateBounds[name]?.bounds;
      bounds.extend({ lat: stateBounds.south, lng: stateBounds.west });
      bounds.extend({ lat: stateBounds.north, lng: stateBounds.east });
      continue;
    }
    if (market.type === "Country") {
      const countryBounds = market.bounds || contiguousUSBounds;
      bounds.extend({ lat: countryBounds.south, lng: countryBounds.west });
      bounds.extend({ lat: countryBounds.north, lng: countryBounds.east });
      continue;
    }
  }
  return bounds;
};
