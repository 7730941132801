import { DiffCheckProps } from "Components/EditListModal";

export const getContent = ({ attributes, markets }: DiffCheckProps) => {
  let title = "";
  let description;
  if (attributes && !markets) {
    title = "Update Generated Text";
    description = (
      <>
        You have modified the audience attributes that were used to generate
        text for your artist(s) in this list.
        <br />
        <br />
        Do you want to update the text to reflect the audience attributes?
      </>
    );
  } else {
    title = "Update Text and/or Map?";
    description = (
      <>
        You have modified audience attributes or markets that were used to
        generate text and maps for your artist(s) in this list.
      </>
    );
  }
  return {
    title,
    description,
  };
};
