import { setDoc, doc, getFirestore, Timestamp } from "firebase/firestore";
import { CampaignOverview, useGenerateText } from "Hooks";
import { Button } from "melodies-source/Button";
import { Spinner } from "melodies-source/Spinner";
import { SvgLeft } from "melodies-source/Svgs/Left";
import { nanoid } from "nanoid";
import { ShareLists } from "Providers/ListProvider/types";
import { useState } from "react";
import styled from "styled-components";
import { EditListForm } from "./EditListForm";
import { useNavigate } from "react-router-dom";
import { useUser } from "reactfire";
import { toast } from "react-toastify";
import { listDefaults } from "Providers/ListProvider/listDefaults";

export const CreateList = () => {
  const [localList, setLocalList] = useState<Partial<ShareLists>>(listDefaults);
  const [loading, setLoading] = useState<boolean>();
  const { getCampaignOverview } = useGenerateText();
  const [listId] = useState(nanoid(6));
  const navigate = useNavigate();
  const { data: user } = useUser();

  const createList = async () => {
    try {
      const result = await getCampaignOverview({
        client: localList?.client,
        goal: localList?.campaignGoal,
        target: localList?.targetPersona,
        type: (
          localList?.type ?? "Sample"
        ).toLowerCase() as CampaignOverview["type"],
        artistCount: localList.artistCount,
      });

      await setDoc(doc(getFirestore(), "ame_sharelists", listId), {
        ...localList,
        createdAt: Timestamp.now(),
        displayDate: Timestamp.now(),
        published: false,
        overview: result?.overview || "",
        createdBy: {
          uid: user.uid,
          name: user.displayName || "",
        },
      });
      toast.success("List has been created!");
    } catch (err) {
      console.log(err);
    } finally {
      navigate(`/admin/${listId}`);
    }
  };

  return (
    <ListWrapper>
      <ActionContainer>
        <Button
          variant="basicText"
          w={170}
          onClick={() => navigate("/admin")}
          leftIcon={<SvgLeft />}
          gap="8px"
        >
          Back to Search
        </Button>
        <SearchContainer>
          <Button variant="basicOutline" w={200}>
            Save as Draft
          </Button>
          <Button
            variant="basic"
            w={230}
            onClick={() => {
              createList();
              setLoading(true);
            }}
          >
            {loading ? <Spinner /> : "Save and Add Artists"}
          </Button>
        </SearchContainer>
      </ActionContainer>
      <EditListForm
        setListDetails={setLocalList}
        list={localList}
        listId={listId}
      />
    </ListWrapper>
  );
};

export const ListWrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const ActionContainer = styled.div`
  width: clamp(900px, 80vw, 1170px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto 40px;
  ${(p) => p.theme.mediaQueries.mobile} {
    order: 1;
    margin: -20px auto;
    width: 100%;
    flex-direction: column;
    & > button {
      display: none;
    }
  }
`;

export const SearchContainer = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100%;
    flex-direction: column-reverse;
    & > button {
      width: 100%;
    }
  }
`;
