import { Modal } from "melodies-source/Modal";
import React, { useEffect, useState } from "react";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Radio } from "melodies-source/Selectable";

export const EditSocialModal = ({
  artist,
  isOpen,
  setIsOpen,
  isEditOpen,
  setOverride,
}) => {
  const [value, setValue] = useState<string>();
  const [isSelected, setIsSelected] = useState<string>();

  useEffect(() => {
    setValue(artist?.services?.[isEditOpen.toLowerCase()]?.followers);
    if (isEditOpen === "Spotify") {
      !!artist?.services?.spotify.listeners
        ? setIsSelected("listeners")
        : setIsSelected("followers");
    }
  }, [artist?.services?.[isEditOpen.toLowerCase()]?.followers]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen("")}
      variant="small"
      header={
        isEditOpen === "TikTok"
          ? !artist?.services?.tiktok?.followers
            ? `Add TikTok`
            : `Edit TikTok`
          : `Edit Spotify`
      }
    >
      <SearchContainer>
        {isEditOpen === "Spotify" && (
          <RadioContainer>
            <Radio
              variant="radio"
              label="Listeners"
              value={isSelected === "listeners"}
              onChange={() => setIsSelected("listeners")}
            />
            <Radio
              variant="radio"
              label="Followers"
              value={isSelected === "followers"}
              onChange={() => setIsSelected("followers")}
            />
          </RadioContainer>
        )}
        {isEditOpen === "TikTok" && (
          <TextInput
            label={`TikTok URL`}
            placeholder="Paste URL"
            value={artist?.services?.tiktok?.url}
            onChange={(v) => {
              setOverride(`followers.tiktok.url`, v);
            }}
          />
        )}
        <TextInput
          label={`${isEditOpen} ${isSelected ?? "followers"}`}
          placeholder={`Enter ${isSelected ?? "followers"}`}
          value={value}
          onChange={(v) => {
            setValue(v);
          }}
        />
      </SearchContainer>
      <ButtonWrapper>
        <Button variant="basicOutline" onClick={() => setIsOpen("")}>
          Cancel
        </Button>
        <Button
          variant="basic"
          onClick={() => {
            setOverride(
              `followers.${isEditOpen.toLowerCase()}.${
                isSelected ?? "followers"
              }`,
              parseInt(value),
            );
            setIsOpen("");
          }}
        >
          Save
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

const SearchContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-columns: 1fr;
  min-height: 20vh;
  align-content: flex-start;
`;
const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  & > div {
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: auto;
  align-content: flex-end;
`;
