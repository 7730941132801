import React from "react";
import styled, { css } from "styled-components";
import { ButtonThemeCSSProps, Div, HTMLButton } from "../utils";

const ButtonTypes = [
  "primary",
  "secondaryPrimary",
  "secondary",
  "tertiary",
  "destructive",
  "branded",
  "basic",
  "basicOutline",
  "basicText",
  "basicShadow",
] as const;
export type ButtonType = (typeof ButtonTypes)[number];

const isDisabledCss = css`
  border: none;
  color: ${(p) => p.theme.colors.black40};
  background: ${(props) => props.theme.colors.gray3};
  pointer-events: none;
`;

type Styles = {
  [K in ButtonType]?: any;
};
const styles: Styles = {
  primary: css`
    background: ${(props) => props.theme.colors.navy};
    color: ${(props) => props.theme.colors.white};
    :hover {
      background: ${(props) => props.theme.colors.navy60};
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  secondaryPrimary: css`
    background: ${(props) => props.theme.colors.white};
    border: solid 1px ${(props) => props.theme.colors.navy};
    color: ${(props) => props.theme.colors.navy};
    :hover {
      border: solid 1px transparent;
      background: ${(props) => props.theme.colors.navy60};
      color: ${(p) => p.theme.colors.white};
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  secondary: css`
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black80};
    border: solid 1px ${(p) => p.theme.colors.black20};
    :hover {
      border: solid 1px transparent;
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  tertiary: css`
    background: transparent;
    color: ${(props) => props.theme.colors.black60};
    border: none;
    ${(p: any) =>
      p.isDisabled &&
      css`
        ${isDisabledCss};
        background: transparent;
        color: ${(p) => p.theme.colors.black40};
      `}
    :hover {
      color: ${(p) => p.theme.colors.black80};
      background: ${(p) => p.theme.colors.gray2};
      box-shadow: none;
    }
  `,
  destructive: css`
    background: ${(p) => p.theme.colors.red};
    color: ${(props) => props.theme.colors.white};
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  branded: css`
    background: linear-gradient(45deg, #1b0076, #e71e3d);
    color: ${(props) => props.theme.colors.white};
    border-radius: 22px;
  `,
  basic: css`
    background: var(--secondary-color);
    color: ${(props) => props.theme.colors.white};
    border-radius: 50px;
  `,
  basicOutline: css`
    background-color: transparent;
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    border-radius: 50px;
    &:hover {
      background-color: var(--secondary-color);
      color: var(--primary-button-text-color);
    }
  `,
  basicText: css`
    color: var(--secondary-color);
    background: transparent;
    border-radius: 50px;
    padding: 0;
    height: auto;
    :hover {
      background: transparent;
      box-shadow: none;
    }
  `,
  basicShadow: css`
    color: #000000;
    background: #ffffff;
    border-radius: 50px;
    box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.1);
    padding: 35px;
    max-width: 300px;
  `,
};

type MaxButtonProps = {
  variant?: ButtonType;
  isDisabled?: boolean;
  gap?: string;
};
export const MaxButton = styled(HTMLButton)<MaxButtonProps>`
  height: 44px;
  border-radius: 4px;
  border: solid 1px transparent;
  user-select: none;
  padding: 10px 12px;
  :hover {
    cursor: pointer;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
  :focus {
    outline: none;
  }

  ${(p) => p.gap && `gap: ${p.gap};`};

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  ${(p) => {
    const c = p.theme.css;
    return [c.centered];
  }};

  ${({ variant }) => styles[variant || "primary"]};
`;

type BtnProps = {
  onClick?: () => void;
  variant?: ButtonType;
  children: any;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  isDisabled?: boolean;
  gap?: string;
} & ButtonThemeCSSProps;

export const Button = ({
  onClick = () => {},
  isDisabled = false,
  leftIcon,
  rightIcon,
  variant = "primary",
  children,
  type = "button",
  ...props
}: BtnProps) => {
  return (
    <MaxButton
      w100
      alignCenter
      type={type}
      tabIndex={0}
      onKeyDown={({ code }) => {
        if (code === "Space" || (code === "Enter" && onClick)) onClick();
      }}
      {...{ variant, isDisabled, onClick, ...props }}
    >
      {leftIcon && (
        <Div mr={0} centered>
          {leftIcon}
        </Div>
      )}
      {children}
      {rightIcon && (
        <Div mr={0} centered>
          {rightIcon}
        </Div>
      )}
    </MaxButton>
  );
};
