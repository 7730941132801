import { getOverrides } from "Providers/ListProvider/overrides";
import {
  Artist,
  Concert,
  Concerts,
  Era,
  Eras,
  Genre,
  HiddenProp,
  ShareLists,
} from "Providers/ListProvider/types";
import { UpdatedArtist, UpdatedArtistOverride } from ".";

const audienceOverrides = ["age", "gender", "ethnicity", "income"];

export const coreOverrides = [
  "audience_size",
  "eras",
  "image",
  "primaryGenre",
  "title",
  "market",
  "locations",
  "sound",
];

export const mergeOverrides = (
  artist: Artist,
  overrides?: UpdatedArtistOverride,
  excludeHidden?: boolean,
  list?: ShareLists,
) => {
  if (artist) {
    const uArtist: UpdatedArtist = artist;
    const {
      audience,
      biography,
      followers,
      track,
      website,
      wikipedia,
      overview,
      highlights,
      concerts,
      locations,
    } = overrides ?? {};

    const { text, tiktok, spotify, attrs, realEras } =
      getOverrides(overview?.html) ?? {};

    const isVisible = (val: HiddenProp) => !(excludeHidden && val.hidden);

    if (overview && isVisible(overview)) {
      uArtist.overview = { ...overview, html: text };
    }
    if (!!highlights?.length) {
      uArtist.highlights = highlights;
    }

    /**
     * Update locations from overrides, then convert each to string
     * and store in 'tempLocations' field for editing in textarea
     */
    if (uArtist.locations?.length || !!locations?.length) {
      const artistLocations = locations || uArtist.locations;
      uArtist.locations = artistLocations;
      uArtist.tempLocations = artistLocations
        .map((location) => location.title)
        .filter((location) => !!location)
        .join("\n");
    }

    /**
     * Hack to display TikTok followers as a service instead of Soundcloud in legacy lists
     */
    if (uArtist?.services?.soundcloud?.id === "0000") {
      const tiktok = uArtist?.services.soundcloud;
      const followers = uArtist?.services.soundcloud.followers as string;
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          ...tiktok,
          followers: parseInt(followers?.replace(/,/g, "")),
        },
      };
    }

    if (tiktok?.length) {
      const [followers, url] = tiktok;
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          followers: parseInt(followers?.replace(/,/g, "")),
          url,
        },
      };
    }
    if (!!followers?.tiktok?.followers) {
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          ...uArtist?.services.tiktok,
          followers: followers?.tiktok?.followers,
        },
      };
    }
    if (!!followers?.tiktok?.url) {
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          ...uArtist?.services.tiktok,
          url: followers?.tiktok?.url,
        },
      };
    }
    if (!!followers?.spotify?.followers) {
      uArtist.services = {
        ...uArtist?.services,
        spotify: {
          ...uArtist?.services.spotify,
          followers: followers?.spotify?.followers,
        },
      };
    }
    if (!!followers?.spotify?.listeners) {
      uArtist.services = {
        ...uArtist?.services,
        spotify: {
          ...uArtist?.services.spotify,
          listeners: followers?.spotify?.listeners,
        },
      };
    }

    if (spotify?.length) {
      const [listeners] = spotify;
      const spotifyService = uArtist?.services.spotify;
      uArtist.services = {
        ...uArtist?.services,
        spotify: {
          ...spotifyService,
          listeners: parseInt(listeners?.replace(/,/g, "")),
        },
      };
    }

    /**
     * Map root level overrides for audience_size, eras, title, market, sound
     */
    if (!!overrides) {
      ["audience_size", "eras", "title", "market", "sound"].forEach(
        (override) => {
          if (!!overrides[override]) {
            uArtist[override] = overrides[override];
          }
        },
      );
    }

    /**
     * Update image from overrides or retrieve from artist image endpoint
     */
    if (!uArtist?.image || overrides?.image) {
      uArtist.image = overrides?.image || `/api/artist/image/${artist.id}`;
    }

    /**
     * Filter out genres that are hidden in overrides
     */
    if (excludeHidden) {
      uArtist.genres = uArtist.genres.filter(
        ({ id }) => !overrides?.sound?.[id]?.hidden,
      );
    }

    /**
     * Update primary genre from overrides or top genre from the artist's genres
     */
    if (overrides?.primaryGenre) {
      uArtist.primaryGenre = overrides.primaryGenre;
    }

    /**
     * Update sample track with default spotify artist id or provided overrides
     */
    uArtist.track = {
      type: "spotify-artist",
      id: uArtist.services?.spotify?.id,
      ...track,
    };

    uArtist.followers = Object.assign({ ...uArtist.followers, ...followers });

    if (excludeHidden && followers && !!Object.keys(followers).length) {
      const hiddenServices = Object.keys(followers).filter((service) =>
        followers[service]?.hidden ? service : null,
      );
      hiddenServices.forEach((serviceName) => {
        if (!!uArtist?.services[serviceName]) {
          delete uArtist.services[serviceName];
        }
      });
    }

    if (website) {
      uArtist.services.website = { ...uArtist.services.website, ...website };
    }

    if (wikipedia) {
      uArtist.services.wikipedia = {
        ...uArtist.services.wikipedia,
        ...wikipedia,
      };
    }

    if (audience?.hidden) {
      uArtist.charts = { hidden: true };
    }

    if (biography?.html && !biography?.hidden) {
      uArtist.background_html = biography.html;
    } else {
      uArtist.background_html = null;
    }

    const attributes = artist?.audience_attributes?.reduce(
      (accum, currAttr) => {
        const attr = { ...currAttr };
        const onlyOverIndex = audience?.only_display_overindex;
        const onlyOverAverage = audience?.display_over_average;
        const key = attr?.category?.toLowerCase();
        const disableKey = `${key}_hidden`;

        if (excludeHidden && audience && !!audience[disableKey]) {
          return accum;
        }

        if (excludeHidden && audience?.[`attributes_hidden`]) {
          accum[`attributes_hidden`] = true;
        }

        if (excludeHidden && onlyOverAverage && attr?.value < 0.93) {
          accum["onlyOverAverage"] = true;
        }

        if (list?.attributes?.length && list.attributes.includes(attr.title)) {
          attr.selected = true;
        }

        if (attrs?.includes(attr.title)) {
          return accum;
        }

        if (
          excludeHidden &&
          audience?.attributes &&
          audience?.attributes[attr.id]?.hidden
        ) {
          return accum;
        }

        if (!!audienceOverrides.includes(key)) {
          accum[key] = [...(accum[key] || []), attr];
        } else {
          //accum["other"].length < 17
          if (list?.attributes?.includes(attr.title)) {
            // List-attributes get bumped to the top of the list
            accum["other"] = [attr, ...(accum["other"] || [])];
          } else {
            if (onlyOverIndex && attr.value - 1 <= 0) {
              return accum;
            }
            accum["other"] = [...(accum["other"] || []), attr];
          }
        }

        return accum;
      },
      {},
    );

    attributes["other"] = attributes["other"]?.slice(0, 17) || [];

    uArtist.concerts = updateConcerts(concerts);

    uArtist.eras = sortEras(uArtist.eras, realEras);

    return { ...uArtist, attributes };
  }
};

export const updateConcerts = (overrides?: Concerts) => {
  let mergedConcerts: Concert[] = [];

  if (overrides && Object.keys(overrides)?.length) {
    for (const [id, concert] of Object.entries(overrides)) {
      const { lat, lng } = concert.position || {};
      if (!isNaN(Number(lat)) && !isNaN(Number(lng))) {
        mergedConcerts.push({
          id,
          ...concert,
        });
      }
    }
  }

  return mergedConcerts.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
};

export const getTopGenre = (genres: Genre[]) => {
  const topGenre = genres?.find((a) => a.raw_weight_float === 1);
  const topWeightedGenre = genres?.sort(
    (a, b) => b.raw_weight_float - a.raw_weight_float,
  )?.[0];
  return topGenre?.title || topWeightedGenre?.title;
};

/**
 * Sorts eras in the correct order and removes any strings that are not valid
 * eras.  If `realEras` is false then the returned eras are artificially
 * inflated up to the current era.  For example:
 *
 *    sortEras(["70s", "80s"], false);
 *    // returns: ["70s", "80s", "90s", "00s", "10s", "20s"]
 *
 */
export const sortEras = (eras: Era[], realEras: boolean = false) => {
  const sorted = eras
    ?.filter((era) => Eras.includes(era))
    ?.sort((a, b) => Eras.indexOf(a) - Eras.indexOf(b));

  if (realEras !== true && sorted?.length) {
    const [firstEra] = sorted;
    const lastEra = `${
      Math.floor((new Date().getFullYear() % 1000) / 10) * 10
    }s` as Era;
    return Eras.slice(Eras.indexOf(firstEra), Eras.indexOf(lastEra) + 1);
  }
  return sorted;
};
