import React from "react";
import styled, { css } from "styled-components";
import { Div, DivThemeCSSProps } from "../utils";
import { Body1, Caption, H3, P as ListItemBase } from "../Text";

type LiProps = {
  hasCaption?: boolean;
};
const Li = styled(ListItemBase)<LiProps>`
  line-height: 22px;
  ${(p) =>
    p.hasCaption &&
    css`
      line-height: 21px;
    `}
`;

type IconContainerProps = {
  isAvatar?: boolean;
  dimensions: number;
  hasCaption?: boolean;
};
const IconContainer = styled(Div)<IconContainerProps>`
  min-width: ${(p) => p.dimensions}px;
  max-width: ${(p) => p.dimensions}px;
  height: calc(${(p) => p.dimensions}px - 8px - 8px);
  ${(p) => p.theme.css.centered};
  color: ${(props) => props.theme.colors.black80};
  img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 25px;
  }
  svg {
    height: 20px;
    width: 20px;
    ${(p) =>
      p.isAvatar &&
      css`
        height: 28px;
        width: 28px;
        ${p.hasCaption &&
        css`
          svg {
            height: 32px;
            width: 32px;
          }
        `}
      `}
  }
`;

type ListItemContainerProps = {
  onClick?: any;
};
const ListItemContainer = styled(Div)<ListItemContainerProps>`
  ${(p) => {
    const c = p.theme.css;
    return [c.alignCenter, c.spaceBetween];
  }};
  ${(p) =>
    p.onClick &&
    css`
      :hover {
        cursor: pointer;
        background: ${(p) => p.theme.colors.gray2};
      }
    `}
  padding: 8px 5px;
  min-width: 180px;
  user-select: none;
`;

export const LIST_ITEM_PADDING_LEFT = "12px";

type ListItemProps = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  avatar?: JSX.Element;
  caption?: string;
  isLarge?: boolean;
  isSelected?: boolean;
  children: JSX.Element | string;
} & DivThemeCSSProps;
export const ListItem = ({
  leftIcon,
  rightIcon,
  avatar,
  children,
  caption,
  isSelected = false,
  isLarge = false,
  ...props
}: ListItemProps) => {
  const leftUI = leftIcon || avatar;
  const pl = leftUI ? "0" : LIST_ITEM_PADDING_LEFT;

  if (!caption) {
    const minHeight = 44;
    return (
      <ListItemContainer minh={minHeight} pl={pl} {...props}>
        <Div alignCenter pl={11}>
          {leftUI && (
            <IconContainer dimensions={minHeight} isAvatar={!!avatar}>
              {leftUI}
            </IconContainer>
          )}
          <Li pr={3}>{children}</Li>
        </Div>
        {rightIcon && (
          <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
        )}
      </ListItemContainer>
    );
  } else {
    if (!avatar) {
      const minHeight = 44;
      const textML = leftUI ? 7 : 0;

      return (
        <ListItemContainer minh={minHeight} pl={pl} {...props}>
          <Div>
            <Div alignCenter>
              {leftUI && (
                <IconContainer dimensions={minHeight}>{leftUI}</IconContainer>
              )}
              {isLarge ? (
                <Div ml={textML}>
                  <H3>{children}</H3>
                </Div>
              ) : (
                <Li hasCaption>{children}</Li>
              )}
            </Div>
            <Div ml={leftUI ? minHeight : 0}>
              {isLarge ? (
                <Body1 mt={4} ml={textML}>
                  {caption}
                </Body1>
              ) : (
                <Caption>{caption}</Caption>
              )}
            </Div>
          </Div>
          {rightIcon && (
            <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      );
    } else {
      const minHeight = 52;
      return (
        <ListItemContainer minh={minHeight} pl={pl} {...props}>
          <Div alignCenter>
            <IconContainer
              dimensions={minHeight}
              isAvatar={true}
              hasCaption={true}
            >
              {avatar}
            </IconContainer>
            <Div>
              <Li>{children}</Li>
              <Caption>{caption}</Caption>
            </Div>
          </Div>
          {rightIcon && (
            <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      );
    }
  }
};
