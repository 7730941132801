import { useCallback, useMemo } from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { SvgMarkerCluster } from "assets";
import { MarkerProps } from "../types";
import { SvgPinMarker } from "assets/svg";
import styled, { css } from "styled-components";

export const Marker = ({
  id,
  value,
  position,
  onClick,
  isCluster,
  count,
  zoom,
  active,
  ...props
}: MarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onClick && onClick(marker!, id),
    [onClick, marker, id],
  );

  const markerDimensions = useMemo(() => {
    const BASE = isCluster ? 40 : 32;
    const dimension = BASE + count * zoom * 0.7;
    return {
      width: dimension,
      height: dimension,
    };
  }, [count, zoom, active, isCluster]);

  return (
    <StyledAdvancedMarker
      position={position}
      ref={markerRef}
      onClick={handleClick}
      active={active}
      {...markerDimensions}
    >
      {isCluster ? <SvgMarkerCluster value={value} /> : <SvgPinMarker />}
    </StyledAdvancedMarker>
  );
};

type MarkerStyledProps = {
  active?: boolean;
  width?: number | string;
  height?: number | string;
};

const StyledAdvancedMarker = styled(AdvancedMarker)<MarkerStyledProps>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  svg {
    transition: all 100ms ease-in;
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
  }

  ${(p) =>
    p.active &&
    css`
      transform: scale(1.2);
    `};
`;
