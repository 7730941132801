import { Button } from "melodies-source/Button";
import { Player } from "Components/Player";
import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import SpotifyColor from "Components/Assets/Icons/Spotify-Color.svg?react";
import YouTubeColor from "Components/Assets/Icons/YouTube-Color.svg?react";
import { useArtist } from "Providers/ArtistProvider";
import { SvgRefreshLarge } from "melodies-source/Svgs/RefreshLarge";
import { SpotifySearchModal } from "Components/SpotifySearchModal";
import { useUser } from "reactfire";

export const MusicSample = () => {
  const [showAddSong, setShowAddSong] = useState(false);
  const [platformSearch, setPlatformSearch] = useState<string>("");
  const { artist, setOverride, overrides, save } = useArtist();
  const [token, setToken] = useState<string>();
  const [tracks, setTracks] = useState<any[]>();
  const [videos, setVideos] = useState<any[]>();
  const { status, data: user } = useUser();

  useEffect(() => {
    const getToken = async () => {
      const token = await user.getIdToken();
      setToken(token);
    };
    getToken();
  }, []);

  const handleSpotifySearch = async () => {
    if (artist?.services?.spotify?.id && token) {
      try {
        const fetchTracks = await fetch(
          `/api/spotify/${artist?.services?.spotify?.id}`,
          {
            headers: { Authorization: `bearer ${token}` },
          },
        );
        const tracks = await fetchTracks.json();
        setTracks(tracks?.tracks);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleYoutubeSearch = async () => {
    if (artist?.services?.youtube?.id && token) {
      try {
        const fetchVideos = await fetch(
          `/api/youtube/${artist?.services?.youtube?.id}`,
          {
            headers: { Authorization: `bearer ${token}` },
          },
        );
        const videos = await fetchVideos.json();
        setVideos(videos);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Body>
        {showAddSong ? (
          <AddSongWrapper>
            <Button
              variant="basicShadow"
              leftIcon={<SpotifyColor style={{ width: 25, marginRight: 7 }} />}
              onClick={() => {
                setPlatformSearch("Spotify");
                handleSpotifySearch();
              }}
            >
              Add Song
            </Button>
            <Button
              variant="basicShadow"
              leftIcon={<YouTubeColor style={{ width: 25, marginRight: 7 }} />}
              onClick={() => {
                setPlatformSearch("Youtube");
                handleYoutubeSearch();
              }}
            >
              Add Video
            </Button>
          </AddSongWrapper>
        ) : (
          <>
            <Player {...artist?.track} />
            <Button
              variant="basicOutline"
              leftIcon={
                <SvgRefreshLarge style={{ width: 17, marginRight: 3 }} />
              }
              onClick={() => setShowAddSong(true)}
            >
              {" "}
              Replace
            </Button>
          </>
        )}
      </Body>

      {!!platformSearch && (
        <SpotifySearchModal
          isOpen={!!platformSearch}
          platformSearch={platformSearch}
          setIsOpen={setPlatformSearch}
          setShowAddSong={setShowAddSong}
          artist={artist}
          tracks={tracks}
          videos={videos}
          setOverride={setOverride}
        />
      )}
    </>
  );
};

const Body = styled.div`
  margin: 15px 0;
  display: flex;
  width: 100%;
  position: relative;

  flex-direction: column;
  align-items: flex-end;
  button {
    margin-top: 20px;
    width: 180px;
  }

  @media (max-width: 950px) {
    align-items: center;
    button {
      width: 100%;
    }
    max-width: 85vw;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const AddSongWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: center;
  gap: 25px;
  button {
    width: 100% !important;
    margin-top: 0 !important;
  }
`;
