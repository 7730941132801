import { Button } from "melodies-source/Button";
import styled from "styled-components";
import { H1 } from "melodies-source/Text";
import { useParams } from "react-router-dom";
import PencilSvg from "Components/Assets/Icons/pencil.svg?react";
import ExternalLinkSvg from "Components/Assets/Icons/external-link.svg?react";
import { SvgPencil } from "assets/svg";

export const AddArtistActions = ({
  campaignName,
  handlePublish,
  handleSaveArtists,
  editList,
}) => {
  const { listId } = useParams();

  return (
    <ActionContainer>
      <EditContainer>
        <H1>{campaignName}</H1>
        <Button
          variant="basicText"
          leftIcon={<SvgPencil />}
          onClick={() => editList()}
          gap="8px"
          style={{ marginTop: 0 }}
        >
          Edit List Details
        </Button>
      </EditContainer>
      <ButtonContainer>
        <Button
          variant="basicText"
          w={100}
          rightIcon={<ExternalLinkSvg style={{ width: 17, marginLeft: 4 }} />}
          onClick={() => window.open(`/${listId}`, "_blank")}
        >
          Preview
        </Button>
        <Button
          variant="basic"
          w={150}
          onClick={() => {
            handleSaveArtists();
            handlePublish();
          }}
        >
          Publish
        </Button>
      </ButtonContainer>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  width: clamp(900px, 80vw, 1170px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100%;
    padding: 0 15px;
  }
`;
const ButtonContainer = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100%;
    margin: auto;
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  }
`;
const EditContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  button {
    width: fit-content;
  }
  & > h1 {
    font-weight: 800;
    line-height: 40px;
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    display: none;
  }
`;
