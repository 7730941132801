import { Coords } from "Components/Maps";

type StateBounds = {
  [key: string]: {
    name: string;
    bounds: {
      north: number;
      east: number;
      south: number;
      west: number;
    };
    center: google.maps.LatLngLiteral;
  };
};

export const contiguousUSBounds = {
  north: 49.384358,
  east: -66.93457,
  south: 24.396308,
  west: -125.0,
};

export const countryBounds = {
  contiguousUS: {
    coords: { lat: 39.8283, lon: 98.5795 },
    bounds: contiguousUSBounds,
  },
};

export const usStateBounds: StateBounds = {
  alabama: {
    name: "Alabama",
    bounds: {
      north: 35.008028,
      east: -84.888246,
      south: 30.137521,
      west: -88.473227,
    },
    center: { lat: 32.806671, lng: -86.79113 },
  },
  alaska: {
    name: "Alaska",
    bounds: {
      north: 71.365162,
      east: -129.9795,
      south: 51.214183,
      west: -179.148909,
    },
    center: { lat: 61.370716, lng: -152.404419 },
  },
  arizona: {
    name: "Arizona",
    bounds: {
      north: 37.00426,
      east: -109.045223,
      south: 31.332177,
      west: -114.818269,
    },
    center: { lat: 33.729759, lng: -111.431221 },
  },
  arkansas: {
    name: "Arkansas",
    bounds: {
      north: 36.4996,
      east: -89.644395,
      south: 33.004106,
      west: -94.617919,
    },
    center: { lat: 34.969704, lng: -92.373123 },
  },
  california: {
    name: "California",
    bounds: {
      north: 42.009518,
      east: -114.131211,
      south: 32.534156,
      west: -124.409591,
    },
    center: { lat: 36.116203, lng: -119.681564 },
  },
  colorado: {
    name: "Colorado",
    bounds: {
      north: 41.003444,
      east: -102.041524,
      south: 36.993076,
      west: -109.045223,
    },
    center: { lat: 39.059811, lng: -105.311104 },
  },
  connecticut: {
    name: "Connecticut",
    bounds: {
      north: 42.050587,
      east: -71.786994,
      south: 40.950943,
      west: -73.727775,
    },
    center: { lat: 41.597782, lng: -72.755371 },
  },
  delaware: {
    name: "Delaware",
    bounds: {
      north: 39.839007,
      east: -75.048939,
      south: 38.451013,
      west: -75.789,
    },
    center: { lat: 39.318523, lng: -75.507141 },
  },
  florida: {
    name: "Florida",
    bounds: {
      north: 31.000888,
      east: -80.031362,
      south: 24.396308,
      west: -87.634938,
    },
    center: { lat: 27.766279, lng: -81.686783 },
  },
  georgia: {
    name: "Georgia",
    bounds: {
      north: 35.000659,
      east: -80.839729,
      south: 30.357851,
      west: -85.605165,
    },
    center: { lat: 33.040619, lng: -83.643074 },
  },
  hawaii: {
    name: "Hawaii",
    bounds: {
      north: 28.402123,
      east: -154.806773,
      south: 18.910361,
      west: -178.334698,
    },
    center: { lat: 21.094318, lng: -157.498337 },
  },
  idaho: {
    name: "Idaho",
    bounds: {
      north: 49.001146,
      east: -111.043564,
      south: 41.988057,
      west: -117.243027,
    },
    center: { lat: 44.240459, lng: -114.478828 },
  },
  illinois: {
    name: "Illinois",
    bounds: {
      north: 42.508337,
      east: -87.019935,
      south: 36.970298,
      west: -91.513079,
    },
    center: { lat: 40.349457, lng: -88.986137 },
  },
  indiana: {
    name: "Indiana",
    bounds: {
      north: 41.760592,
      east: -84.784579,
      south: 37.771742,
      west: -88.09776,
    },
    center: { lat: 39.849426, lng: -86.258278 },
  },
  iowa: {
    name: "Iowa",
    bounds: {
      north: 43.501196,
      east: -90.140061,
      south: 40.375501,
      west: -96.639704,
    },
    center: { lat: 42.011539, lng: -93.210526 },
  },
  kansas: {
    name: "Kansas",
    bounds: {
      north: 40.003162,
      east: -94.588413,
      south: 36.993076,
      west: -102.051744,
    },
    center: { lat: 38.5266, lng: -96.726486 },
  },
  kentucky: {
    name: "Kentucky",
    bounds: {
      north: 39.147458,
      east: -81.964971,
      south: 36.497129,
      west: -89.571509,
    },
    center: { lat: 37.66814, lng: -84.670067 },
  },
  louisiana: {
    name: "Louisiana",
    bounds: {
      north: 33.019457,
      east: -88.817017,
      south: 28.928609,
      west: -94.043147,
    },
    center: { lat: 31.169546, lng: -91.867805 },
  },
  maine: {
    name: "Maine",
    bounds: {
      north: 47.459686,
      east: -66.949895,
      south: 42.977764,
      west: -71.083924,
    },
    center: { lat: 44.693947, lng: -69.381927 },
  },
  maryland: {
    name: "Maryland",
    bounds: {
      north: 39.723043,
      east: -75.048939,
      south: 37.911717,
      west: -79.487651,
    },
    center: { lat: 39.063946, lng: -76.802101 },
  },
  massachusetts: {
    name: "Massachusetts",
    bounds: {
      north: 42.886589,
      east: -69.928393,
      south: 41.186328,
      west: -73.508142,
    },
    center: { lat: 42.230171, lng: -71.530106 },
  },
  michigan: {
    name: "Michigan",
    bounds: {
      north: 48.306063,
      east: -82.413474,
      south: 41.696118,
      west: -90.418136,
    },
    center: { lat: 43.326618, lng: -84.536095 },
  },
  minnesota: {
    name: "Minnesota",
    bounds: {
      north: 49.384358,
      east: -89.491739,
      south: 43.499356,
      west: -97.239209,
    },
    center: { lat: 45.694454, lng: -93.900192 },
  },
  mississippi: {
    name: "Mississippi",
    bounds: {
      north: 34.996052,
      east: -88.09776,
      south: 30.173943,
      west: -91.655009,
    },
    center: { lat: 32.741646, lng: -89.678696 },
  },
  missouri: {
    name: "Missouri",
    bounds: {
      north: 40.61364,
      east: -89.098843,
      south: 35.995683,
      west: -95.774704,
    },
    center: { lat: 38.456085, lng: -92.288368 },
  },
  montana: {
    name: "Montana",
    bounds: {
      north: 49.00139,
      east: -104.039138,
      south: 44.358221,
      west: -116.050003,
    },
    center: { lat: 46.921925, lng: -110.454353 },
  },
  nebraska: {
    name: "Nebraska",
    bounds: {
      north: 43.001708,
      east: -95.30829,
      south: 39.999998,
      west: -104.053514,
    },
    center: { lat: 41.12537, lng: -98.268082 },
  },
  nevada: {
    name: "Nevada",
    bounds: {
      north: 42.002207,
      east: -114.039648,
      south: 35.001857,
      west: -120.005746,
    },
    center: { lat: 38.313515, lng: -117.055374 },
  },
  newHampshire: {
    name: "New Hampshire",
    bounds: {
      north: 45.305476,
      east: -70.703921,
      south: 42.69699,
      west: -72.557247,
    },
    center: { lat: 43.452492, lng: -71.563896 },
  },
  newJersey: {
    name: "New Jersey",
    bounds: {
      north: 41.357423,
      east: -73.893979,
      south: 38.928519,
      west: -75.559614,
    },
    center: { lat: 40.298904, lng: -74.521011 },
  },
  newMexico: {
    name: "New Mexico",
    bounds: {
      north: 37.000232,
      east: -103.001964,
      south: 31.332301,
      west: -109.050173,
    },
    center: { lat: 34.840515, lng: -106.248482 },
  },
  newYork: {
    name: "New York",
    bounds: {
      north: 45.01585,
      east: -71.856214,
      south: 40.477399,
      west: -79.76259,
    },
    center: { lat: 42.165726, lng: -74.948051 },
  },
  northCarolina: {
    name: "North Carolina",
    bounds: {
      north: 36.588117,
      east: -75.460621,
      south: 33.842316,
      west: -84.321869,
    },
    center: { lat: 35.630066, lng: -79.806419 },
  },
  northDakota: {
    name: "North Dakota",
    bounds: {
      north: 49.000574,
      east: -96.554507,
      south: 45.935054,
      west: -104.049361,
    },
    center: { lat: 47.528912, lng: -99.784012 },
  },
  ohio: {
    name: "Ohio",
    bounds: {
      north: 41.977523,
      east: -80.518598,
      south: 38.403202,
      west: -84.820159,
    },
    center: { lat: 40.388783, lng: -82.764915 },
  },
  oklahoma: {
    name: "Oklahoma",
    bounds: {
      north: 37.002206,
      east: -94.430662,
      south: 33.615833,
      west: -103.002043,
    },
    center: { lat: 35.565342, lng: -96.928917 },
  },
  oregon: {
    name: "Oregon",
    bounds: {
      north: 46.292035,
      east: -116.463262,
      south: 41.991794,
      west: -124.566244,
    },
    center: { lat: 44.572021, lng: -122.070938 },
  },
  pennsylvania: {
    name: "Pennsylvania",
    bounds: {
      north: 42.26986,
      east: -74.689516,
      south: 39.7198,
      west: -80.519891,
    },
    center: { lat: 40.590752, lng: -77.209755 },
  },
  rhodeIsland: {
    name: "Rhode Island",
    bounds: {
      north: 42.018798,
      east: -71.12057,
      south: 41.146339,
      west: -71.862772,
    },
    center: { lat: 41.680893, lng: -71.51178 },
  },
  southCarolina: {
    name: "South Carolina",
    bounds: {
      north: 35.215402,
      east: -78.54203,
      south: 32.033454,
      west: -83.354003,
    },
    center: { lat: 33.856892, lng: -80.945007 },
  },
  southDakota: {
    name: "South Dakota",
    bounds: {
      north: 45.94545,
      east: -96.436589,
      south: 42.479635,
      west: -104.057698,
    },
    center: { lat: 44.299782, lng: -99.438828 },
  },
  tennessee: {
    name: "Tennessee",
    bounds: {
      north: 36.678118,
      east: -81.6469,
      south: 34.982972,
      west: -90.310298,
    },
    center: { lat: 35.747845, lng: -86.692345 },
  },
  texas: {
    name: "Texas",
    bounds: {
      north: 36.500704,
      east: -93.508292,
      south: 25.837377,
      west: -106.645646,
    },
    center: { lat: 31.054487, lng: -97.563461 },
  },
  utah: {
    name: "Utah",
    bounds: {
      north: 42.001567,
      east: -109.041058,
      south: 36.997968,
      west: -114.053928,
    },
    center: { lat: 40.150032, lng: -111.862434 },
  },
  vermont: {
    name: "Vermont",
    bounds: {
      north: 45.016659,
      east: -71.465256,
      south: 42.726933,
      west: -73.43774,
    },
    center: { lat: 44.045876, lng: -72.710686 },
  },
  virginia: {
    name: "Virginia",
    bounds: {
      north: 39.466012,
      east: -75.242266,
      south: 36.540738,
      west: -83.67529,
    },
    center: { lat: 37.769337, lng: -78.169968 },
  },
  washington: {
    name: "Washington",
    bounds: {
      north: 49.002494,
      east: -116.916512,
      south: 45.543541,
      west: -124.848974,
    },
    center: { lat: 47.400902, lng: -121.490494 },
  },
  westVirginia: {
    name: "West Virginia",
    bounds: {
      north: 40.638801,
      east: -77.719519,
      south: 37.201483,
      west: -82.644739,
    },
    center: { lat: 38.491226, lng: -80.954456 },
  },
  wisconsin: {
    name: "Wisconsin",
    bounds: {
      north: 47.30982,
      east: -86.249548,
      south: 42.491983,
      west: -92.888114,
    },
    center: { lat: 44.268543, lng: -89.616508 },
  },
  wyoming: {
    name: "Wyoming",
    bounds: {
      north: 45.005904,
      east: -104.05216,
      south: 40.994746,
      west: -111.056888,
    },
    center: { lat: 42.755966, lng: -107.30249 },
  },
};
