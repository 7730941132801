import {
  UploadTask,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Button } from "melodies-source/Button";
import { ProgressRing } from "Components/ProgressRing";
import { Body2, H3 } from "melodies-source/Text";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

interface ImageUploaderProps {
  blob: Blob | null;
  path: string;
  fileName?: string;
  withFileExt?: boolean;
  saveBlob?: boolean;
  onCancel?: () => void;
  onComplete?: (url: any) => void;
}

export const ImageUploader = ({
  blob,
  path,
  fileName = "",
  withFileExt = true,
  saveBlob,
  onCancel,
  onComplete,
}: ImageUploaderProps) => {
  const [progress, setProgress] = useState<number>(50);
  const uploadTask = useRef<UploadTask | null>(null);
  const done = progress === 100;

  const handleCancel = () => {
    uploadTask.current?.cancel();
    onCancel?.();
  };

  useEffect(() => {
    if (!uploadTask.current && blob) {
      if (saveBlob) {
        onComplete?.(blob);
      } else {
        const key = (Math.random() + 1).toString(36).substring(7);
        const type = blob.type.split(";")[0].split("/")[1];
        const ext = withFileExt ? `.${type}` : "";
        const name = fileName ? `${fileName}-${key}` : key;
        const storage = ref(getStorage(), `${path}/${name}${ext}`);

        uploadTask.current = uploadBytesResumable(storage, blob);

        uploadTask.current.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            console.error(error);
          },
          async () => {
            if (uploadTask.current) {
              const url = await getDownloadURL(uploadTask.current.snapshot.ref);
              onComplete?.(url);
            }
          },
        );
      }
    }
  }, []);

  return (
    <Container>
      <ProgressRing progress={progress} />
      <H3>{progress.toFixed(0)}%</H3>
      <Body2>{done ? "Image uploaded successfully" : "Please wait..."}</Body2>
      {!done && (
        <ButtonContainer>
          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonContainer>
      )}
    </Container>
  );
};
