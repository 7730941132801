import _ from "lodash";
import styled, { css } from "styled-components";
import Footer from "Components/Footer";
import { ProgressBar } from "Components/ProgressBar/index";
import { Checkbox } from "../../melodies-source/Selectable";
import { Body2, H2, H3, H4 } from "../../melodies-source/Text/index";
import { DonutChart } from "../../melodies-source/DonutChart/index";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { DivergingChart } from "Components/DivergingChart";
import { SwitchCards } from "Components/SwitchCards";
import { EditArtistActions } from "Components/EditArtistActions";
import { Listenership } from "./Markets/Listenership";
import { TourDates } from "./Markets/TourDates";
import { useArtist } from "Providers/ArtistProvider";
import { Highlights } from "Routes/EditArtist/Highlights";
import { ArtistOverview } from "Routes/EditArtist/ArtistOverview";
import { MusicSample } from "./MusicSample";
import { Socials } from "./Socials";
import { AppLoading } from "melodies-source/AppLoading";

export const EditArtist = () => {
  const { listId } = useParams();
  const isMobile = useMediaQuery();
  const { artist, setOverride, overrides, save, loading } = useArtist();

  if (loading) return <AppLoading />;

  const attributes = artist?.attributes;

  const genderChartData = attributes?.gender?.map((attr) => ({
    label: attr.title,
    value: Math.floor(attr.value * 100),
  }));

  const soundBars = artist?.genres?.sort(
    (a, b) => b.raw_weight_float - a.raw_weight_float,
  );
  const half = Math.ceil(soundBars?.length / 2);
  const soundBarArr1 = soundBars?.slice(0, half);
  const soundBarArr2 = soundBars?.slice(half);

  let renderAccordion = [];

  renderAccordion.push({
    hidden: artist?.overview?.hidden,
    name: "overview",
    header: (
      <Header>
        <H2>Artist Overview</H2>
      </Header>
    ),
    body: <Highlights />,
  });

  renderAccordion.push({
    hidden: artist?.sound?.hidden,
    name: "sound",
    header: (
      <Header>
        <H2>Sound</H2>
        <Body2>
          The artist's unique genre fingerprint and data-driven categorizations
        </Body2>
      </Header>
    ),
    body: (
      <ContainerProgress>
        <div>
          {soundBarArr1?.map((genre, i) => {
            const checked = !artist?.sound?.[genre?.id]?.hidden;
            const opacity = checked ? 1 : 0.3;

            return (
              <div key={i}>
                <Checkbox
                  label={<div style={{ opacity }}>{genre?.title}</div>}
                  value={checked}
                  onChange={(v) => {
                    setOverride(`sound.${genre?.id}.hidden`, !v);
                  }}
                />
                <div style={{ opacity }}>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {soundBarArr2?.map((genre, i) => {
            const checked = !artist?.sound?.[genre?.id]?.hidden;
            const opacity = checked ? 1 : 0.3;

            return (
              <div key={i} style={{ opacity }}>
                <Checkbox
                  label={<div style={{ opacity }}>{genre?.title}</div>}
                  value={checked}
                  onChange={(v) => {
                    setOverride(`sound.${genre?.id}.hidden`, !v);
                  }}
                />
                <div style={{ opacity }}>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              </div>
            );
          })}
        </div>
      </ContainerProgress>
    ),
  });

  renderAccordion.push({
    hidden: overrides?.track?.hidden,
    name: "track",
    header: (
      <Header>
        <H2>Music Sample</H2>
      </Header>
    ),
    body: <MusicSample />,
  });

  renderAccordion.push({
    hidden: overrides?.followers?.hidden,
    name: "followers",
    header: (
      <Header>
        <H2>Fanbase</H2>
        <Body2>
          Insights into an artist's social media following and fans' music
          streaming
        </Body2>
      </Header>
    ),
    body: <Socials />,
  });

  renderAccordion.push({
    hidden: overrides?.audience?.hidden,
    name: "audience",
    header: (
      <Header>
        <H2>Audience</H2>
        <Body2>
          Insights into an artist's audience based on a comparison to other
          artists' fan bases
        </Body2>
      </Header>
    ),
    body: (
      <AudienceWrapper>
        <CategoryWrapper>
          <Checkbox
            value={overrides?.audience?.display_over_average}
            onChange={(v) => setOverride("audience.display_over_average", v)}
          />
          <H4>
            Only show categories in which this artist exceeds the industry
            average
          </H4>
        </CategoryWrapper>
        <BodyAudience>
          <ChartWrapper>
            <ChartBox donut={true}>
              {overrides?.audience?.["gender_hidden"] && <Overlay donut />}
              <Checkbox
                label={<span>Gender</span>}
                value={!overrides?.audience?.["gender_hidden"]}
                onChange={(v) => setOverride("audience.gender_hidden", !v)}
                style={{ width: "fit-content", zIndex: 10 }}
              />
              <div>
                <DonutChart
                  w={isMobile ? 200 : 400}
                  onSelectItem={() => {}}
                  chartDimensions={isMobile ? 140 : 220}
                  isArtistList
                  withLegend
                  data={genderChartData}
                />
              </div>
            </ChartBox>

            <ChartBox>
              {overrides?.audience?.["age_hidden"] && <Overlay />}
              <Checkbox
                label={<span>Age</span>}
                value={!overrides?.audience?.["age_hidden"]}
                onChange={(v) => setOverride("audience.age_hidden", !v)}
                style={{ zIndex: 10, position: "relative" }}
              />
              <DivergingChart items={attributes?.age} />
            </ChartBox>

            {!!attributes?.income?.length && (
              <ChartBox>
                {overrides?.audience?.["income_hidden"] && <Overlay />}
                <Checkbox
                  label={<span>Household Income</span>}
                  value={!overrides?.audience?.["income_hidden"]}
                  onChange={(v) => setOverride("audience.income_hidden", !v)}
                  style={{ zIndex: 10, position: "relative" }}
                />
                <DivergingChart items={attributes?.income} />
              </ChartBox>
            )}
          </ChartWrapper>

          <ChartWrapper>
            {!!attributes?.ethnicity?.length && (
              <ChartBox>
                {overrides?.audience?.["ethnicity_hidden"] && <Overlay />}
                <Checkbox
                  label={<span>Ethnicity</span>}
                  value={!overrides?.audience?.["ethnicity_hidden"]}
                  onChange={(v) => setOverride("audience.ethnicity_hidden", !v)}
                  style={{ zIndex: 10, position: "relative" }}
                />
                <DivergingChart items={attributes?.ethnicity} />
              </ChartBox>
            )}

            {!!attributes?.other?.length && (
              <ChartBox>
                {overrides?.audience?.["attributes_hidden"] && <Overlay />}
                <Checkbox
                  label={<span>Consumer Attributes</span>}
                  value={!overrides?.audience?.["attributes_hidden"]}
                  onChange={(v) =>
                    setOverride("audience.attributes_hidden", !v)
                  }
                  style={{ zIndex: 10, position: "relative" }}
                />
                <DivergingChart
                  items={attributes?.other}
                  handleHiddenProp={setOverride}
                  artistOverrides={overrides}
                />
              </ChartBox>
            )}
          </ChartWrapper>
        </BodyAudience>
      </AudienceWrapper>
    ),
  });

  renderAccordion.push({
    hidden: overrides?.market?.hidden === true,
    name: "market",
    header: (
      <Header>
        <H2>Markets: Listenership</H2>
      </Header>
    ),
    body: <Listenership />,
  });

  renderAccordion.push({
    hidden: overrides?.marketTour?.hidden === true,
    name: "marketTour",
    header: (
      <Header>
        <H2>Markets: Tour Dates</H2>
      </Header>
    ),
    body: <TourDates />,
  });

  return (
    <Container>
      <EditArtistActions onSave={save} listId={listId} />
      <Wrapper>
        <ArtistOverview />
        <SwitchCards
          sections={renderAccordion}
          handleHiddenProperty={setOverride}
        />
      </Wrapper>
      <Footer isArtistPage={true} />
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  color: #000;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: 10px auto 50px auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-self: flex-start;
  @media (max-width: 950px) {
    margin: 35px auto;
    padding: 0;
    width: 100%;
  }
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid #d4d4d4;
  padding: 20px;
  justify-content: flex-start;
  gap: 15px;
  h4 {
    color: #0240fb;
  }
  & > div {
    width: fit-content;
  }
`;

const MarkdownBody = styled.div`
  font-family: "Work Sans";
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  h3 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 1.9rem;
  }
  table,
  tbody,
  tr,
  th {
    border: 1px solid #bfbfbf;
    padding: 10px;
    text-align: center !important;
  }
  td {
    border: 1px solid #e2e2e2;
    padding: 10px 15px;
    text-align: left !important;
    font-size: 0.8rem;
  }
`;
const BodyMap = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  h2 {
    font-weight: 800;
  }
  ${Body2} {
    color: ${(p) => p.theme.colors.black80};
    margin-top: 2px;
    margin-left: 30px;
    text-align: right;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    ${Body2} {
      text-align: left;
      margin-left: 0px;
    }
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    align-items: center;
  }
`;

const ContainerProgress = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 120px;
  row-gap: 0px;
  margin-top: 10px;
  h4 {
    color: #1b0076 !important;
  }
  @media (max-width: 950px) {
    max-width: 80vw;
    gap: 0px;
    grid-template-columns: 1fr;
  }
  & > div {
    margin: 10px 10px 10px 0;
    @media (max-width: 950px) {
      margin: 10px 10px -10px 0;
    }
  }
  & > div > div {
    margin: 0px 0px 20px 0;
    & > div:nth-child(2) {
      // margin-left: 25px;
    }
  }
`;

const BodyAudience = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  gap: 32px;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: calc(50% - 17px);
  gap: 30px;
  @media (max-width: 950px) {
    width: 100%;
  }
`;
const Overlay = styled.div<{ donut?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  ${(props) =>
    props.donut &&
    css`
      left: -10px;
    `}
`;

const ChartBox = styled.div<{ mb?: boolean; donut?: boolean }>`
  width: 100%;
  max-width: 500px;
  position: relative;
  background: #ffffff;
  border-radius: 0px;
  border: 1px solid #d4d4d4;
  padding: 24px 28px 32px;
  margin: 0px;
  h4 > span {
    font-size: 22px !important;
    font-weight: 600;
    position: relative;
    z-index: 10;
  }
  ${(props) => props.mb && "margin-bottom: 30px;"};
  @media (max-width: 950px) {
    padding: 20px 25px;
    & > ${H3} {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  ${(props) => props.theme.mediaQueries.desktop} {
    padding: 32px 48px 44px;
    & > ${H3} {
      margin-bottom: 24px;
      text-align: center;
    }
  }

  ${(props) =>
    props.donut &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      /* max-height: 400px; */
      /* height: 100%; */
      & > div {
        display: flex;
        flex-grow: 1;
        margin-left: 10px;
      }
    `}
`;
const MapLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  padding: 11px 24px;
  h4 {
    color: #4c4c4c;
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
  & > div {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-bottom: -5px;
  }
  & > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const ConcentrationImage = styled.img`
  width: 40px;
  margin-right: 15px;
`;
const ConcentrationBar = styled.div`
  height: 8px;
  width: 100%;
  background: linear-gradient(to right, #e8e5f1, #1b0076);
  border-radius: 40px;
`;
const FanContainer = styled.div`
  display: flex;
  margin-bottom: -12px;
  ${ConcentrationBar} {
    width: 55%;
  }
`;
const AudienceContainer = styled.div`
  display: flex;
`;
const AudienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MobileLegend = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  gap: 0px;
  margin-top: 20px;
  h4 {
    color: #4c4c4c;
    font-size: clamp(10px, 2vw, 12px);
  }
  p {
    font-size: 10px;
    margin-bottom: 5px;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    img {
      margin-right: 0;
      margin-bottom: 10px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  ${FanContainer} {
    flex: 3;
  }
  ${AudienceContainer} {
    flex: 1;
  }
`;
const MapContainer = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  aspect-ratio: 16/9;
  overflow: hidden;
`;
