import styled, { css } from "styled-components";
import { useMemo } from "react";
import {
  convertToMapPoints,
  getBounds,
} from "Components/Maps/components/helpers";
import { PlaceDetail } from "Components/Maps/types";
import { FlexRow } from "Components/Flex";
import { SvgBroadcast, SvgLiveLocation } from "assets";
import { CircleMap, ClusterMap } from "Components/Maps";
import { useArtist } from "Providers/ArtistProvider";
import { useIsMobile } from "melodies-source/utils";
import { concertsToMapPoints } from "Routes/EditArtist/Markets/TourDates";
import { getMarketBounds } from "Routes/EditArtist/Markets/getMarketBounds";
import { useListContext } from "Hooks/useListContext";
import MapLocation from "Components/Assets/MapLocation.png";
import { H4, P } from "melodies-source/Text";

export type MapViews = "listenership" | "tourDates";

interface MarketsMapProps extends React.HTMLAttributes<HTMLDivElement> {
  places?: PlaceDetail[];
  view?: MapViews;
}

const CIRCLE_MAP_ID = "cc30cff28b5f8c2a";
const CLUSTER_MAP_ID = "edd33314f1ce23b5";

export const MarketsMap = ({ view, ...props }: MarketsMapProps) => {
  const { overrides, artist } = useArtist();
  const { list } = useListContext();
  const isMobile = useIsMobile();
  const responsiveView = isMobile ? "mobile" : "desktop";
  const tourDatesMapOverrides = overrides?.maps?.tourDates?.[responsiveView];
  const listenershipMapOverrides =
    overrides?.maps?.listenership?.[responsiveView];

  const marketBounds = useMemo(
    () => getMarketBounds(list?.markets),
    [list?.markets],
  );

  const places = useMemo(
    () => concertsToMapPoints(artist?.concerts, marketBounds),
    [artist?.concerts, marketBounds],
  );

  const tourDatesMapSettings = useMemo(() => {
    return {
      ...tourDatesMapOverrides,
      bounds:
        tourDatesMapOverrides?.bounds ||
        getBounds(places?.filter(({ hidden }) => !hidden))?.toJSON(),
    };
  }, [tourDatesMapOverrides, places]);

  const points = useMemo(
    () => convertToMapPoints(artist?.top_markets),
    [artist?.top_markets],
  );

  return (
    <Wrapper {...props}>
      <Container>
        {view === "listenership" ? (
          <CircleMap
            id={CIRCLE_MAP_ID}
            points={points}
            disableControl
            bounds={listenershipMapOverrides?.bounds || marketBounds?.toJSON()}
            zoom={listenershipMapOverrides?.zoom}
          />
        ) : (
          <ClusterMap
            id={CLUSTER_MAP_ID}
            places={places}
            disableControl
            zoom={tourDatesMapSettings.zoom}
            clusterRadius={tourDatesMapSettings.clusterRadius}
            bounds={tourDatesMapSettings.bounds || marketBounds?.toJSON()}
          />
        )}
      </Container>
      {view === "listenership" && <CircleMapLegend />}
    </Wrapper>
  );
};

type MapToolbarProps = {
  onChange: (view: MapViews) => void;
  value: MapViews;
};

export const MapToolbar = ({ onChange, value }: MapToolbarProps) => {
  return (
    <Toolbar>
      <MapViewTab
        onClick={(e) => {
          e.stopPropagation();
          onChange("listenership");
        }}
        active={value === "listenership"}
      >
        <SvgBroadcast />
        Listenership
      </MapViewTab>
      <MapViewTab
        onClick={(e) => {
          e.stopPropagation();
          onChange("tourDates");
        }}
        active={value === "tourDates"}
      >
        <SvgLiveLocation />
        Tour Dates
      </MapViewTab>
    </Toolbar>
  );
};

export const CircleMapLegend = () => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MobileLegend>
      <AudienceContainer>
        <ConcentrationImage src={MapLocation} />
        <H4>Audience Size</H4>
      </AudienceContainer>
      <FanContainer>
        <ConcentrationBar></ConcentrationBar>
        <div>
          <P>LOW</P>
          <P>HIGH</P>
        </div>
        <H4>Fan Concentration</H4>
      </FanContainer>
    </MobileLegend>
  ) : (
    <MapLegend>
      <H4>Audience Size</H4>
      <ConcentrationImage src={MapLocation} />
      <H4>Fan Concentration</H4>
      <div>
        <ConcentrationBar></ConcentrationBar>
        <div>
          <P>LOW</P>
          <P>HIGH</P>
        </div>
      </div>
    </MapLegend>
  );
};

const MapViewTab = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 15px 0 0 15px;
  height: 30px;
  font-size: 12px;
  line-height: 18px;
  gap: 4px;
  padding: 10px 12px;
  font-family: "Poppins";
  font-weight: 600;

  &:hover {
    background-color: var(--secondary-color);
    color: var(--primary-button-text-color);
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
  }
  & + & {
    border-radius: 0 15px 15px 0;
  }

  ${(p) =>
    p.active &&
    css`
      background: var(--secondary-color);
      color: ${(props) => props.theme.colors.white};
      &:hover {
        cursor: default;
      }
    `};
`;

const Toolbar = styled(FlexRow)`
  justify-content: flex-end;
  width: 240px;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1.78;
  overflow: hidden;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    aspect-ratio: 0.5;
    max-height: calc(75dvh - 75px);
  }
`;

const MapLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  border-radius: 0px;
  border: 0px solid lightgrey;
  padding: 25px 25px 12px;
  h4 {
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }
  p {
    color: #000;
    font-weight: 600;
    font-size: 12px;
  }
  & > div {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-bottom: -5px;
  }
  & > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ConcentrationImage = styled.img`
  width: 40px;
  margin-right: 15px;
`;

const ConcentrationBar = styled.div`
  height: 15px;
  width: 100%;
  background: linear-gradient(to right, #e8e5f1, #0240fb);
  border-radius: 40px;
`;

const FanContainer = styled.div`
  display: flex;
  margin-bottom: -12px;
  ${ConcentrationBar} {
    width: 55%;
  }
`;

const AudienceContainer = styled.div`
  display: flex;
`;

const MobileLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin-top: 20px;
  h4 {
    color: #000;
    font-weight: 600;
    font-size: clamp(10px, 2vw, 12px);
  }
  p {
    color: #000;
    font-weight: 600;
    font-size: 10px;
    margin-bottom: 5px;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    img {
      margin-right: 0;
      margin-bottom: 10px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  ${FanContainer} {
    flex: 3;
  }
  ${AudienceContainer} {
    flex: 1;
  }
`;
