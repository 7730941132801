import React, { createContext, useEffect, useState } from "react";

export interface GeoContextProps {
  city?: string;
  state?: string;
}

export const GeoContext = createContext<GeoContextProps>({});

export const GeoProvider = ({ children }: { children: React.ReactNode }) => {
  const [geo, setGeo] = useState<GeoContextProps | null>(null);

  useEffect(() => {
    fetch("/api/geo")
      .then((res) => res.json())
      .then((data) => setGeo(data));
  }, []);

  if (!geo) {
    return <></>;
  }

  return <GeoContext.Provider value={geo}>{children}</GeoContext.Provider>;
};
