import React from "react";
import styled, { css } from "styled-components";
import { Div } from "../utils";

type ContainerProps = {
  value: boolean;
  isDisabled: boolean;
};
const Container = styled(Div)<ContainerProps>`
  width: 42px;
  height: 22px;
  background: #0240fb;
  border: 2px solid #0240fb;
  border-radius: 14px;
  user-select: none;
  ${(props) =>
    !props.value &&
    css`
      background: ${(p) => p.theme.colors.black20};
      border: 2px solid ${(p) => p.theme.colors.black20};
    `};
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
`;

type CircleProps = {
  value: boolean;
};
const Circle = styled(Div)<CircleProps>`
  width: 20px;
  height: 20px;
  border: solid 2px white;
  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  ${(p) =>
    p.value
      ? css`
          right: 0px;
        `
      : css`
          left: 0px;
        `}
`;

type Props = {
  value: any;
  onChange?: (v: boolean) => void;
  isDisabled?: boolean;
};
export const Switch = ({ value, onChange, isDisabled = false }: Props) => {
  return (
    <Container
      pRelative
      alignCenter
      value={value}
      isDisabled={isDisabled}
      onClick={() => {
        if (onChange) onChange(!value);
      }}
    >
      <Circle pAbsolute value={value} />
    </Container>
  );
};
