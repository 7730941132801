import { Button } from "melodies-source/Button";
import { LinkText } from "melodies-source/Text";
import styled, { css } from "styled-components";

export const ButtonRounded = styled(Button)<{ active?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 22px;
  box-shadow: none;
  color: #999;
  width: auto;
  height: 38px;
  padding: 7px 16px;
  gap: 6px;

  svg {
    width: 22px;
    height: 22px;
  }

  ${(p) =>
    p.active
      ? css`
          background-color: #0240fb;
          color: #fff;
          &:hover {
            box-shadow: none;
            background-color: #0240fb;
            color: #fff;
          }
        `
      : css`
          background-color: var(--disabled-background-color);
          color: var(--disabled-text-color);
          &:hover {
            box-shadow: none;
            color: #fff;
          }
        `};

  ${(p) =>
    p.disabled &&
    !p.active &&
    css`
      &:hover {
        background-color: var(--disabled-background-color);
        color: var(--disabled-text-color);
        box-shadow: none;
        pointer-events: none;
      }
    `};
`;

export const Tabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -78px;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0;
  }
`;

export const Tab = styled(ButtonRounded)`
  height: 36px;
  padding: 0 16px;
  gap: 6px;
  align-items: center;
  box-shadow: none;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MapContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  aspect-ratio: 1.78;
  width: 100%;
  min-height: 0;
  border-radius: 4px;
  overflow: hidden;

  ${(p) =>
    p.isActive &&
    css`
      border: 3px solid var(--secondary-color);
    `};
`;

export const MapPositionButton = styled(LinkText)<{ isDestructive?: boolean }>`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 8px;
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid var(--secondary-color);
  padding: 5px 12px;
  border-radius: 20px;
  user-select: none;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--secondary-color);
    color: var(--primary-button-text-color);
    cursor: pointer;
  }

  ${(p) =>
    p.isDestructive &&
    css`
      border-color: #e71e3d;
      color: #e71e3d;

      &:hover {
        background-color: #e71e3d;
      }
    `};
`;
