import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Body1, Body2, H4, Selected } from "../Text";
import { Div, DivThemeCSSProps } from "../utils";
import { CheckboxInput } from "./CheckboxInput";
import { RadioInput } from "./RadioInput";

const variants = ["radio", "checkbox"] as const;
type Variant = typeof variants[number];

const HoverIndicator = styled(Div)`
  background: ${(p) => p.theme.colors.gray2};
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: none;
`;

const SelectableContainer = styled(Div)`
  border-radius: 100%;
  ${(p) => p.theme.css.centered};
  :hover {
    cursor: pointer;
    ${HoverIndicator} {
      display: block;
    }
  }
`;

export type SelectableProps = {
  label?: string | ReactNode;
  value: boolean;
  description?: string;
  variant?: Variant;
  onChange?: (v: boolean) => void;
} & DivThemeCSSProps;
export const Selectable = ({
  label,
  value,
  onChange,
  variant = "radio",
  description,
  ...props
}: SelectableProps) => {
  const toggle = () => onChange?.(!value);

  const textUI = (
    <Div ml={10} w100 as="label">
      <Div selectNone as="label" alignCenter>
        <Div alignCenter clickable dInline onClick={() => toggle()}>
          {value ? (
            <H4 truncateText>{label}</H4>
          ) : (
            <H4 truncateText>{label}</H4>
          )}
        </Div>
      </Div>
      {description && <Body2>{description}</Body2>}
    </Div>
  );

  return (
    <Div dflex w100 {...props}>
      <SelectableContainer pRelative dim={20} onClick={() => toggle()}>
        <Div
          zIndex={1}
          tabIndex={0}
          onKeyDown={({ code }) => {
            if (code === "Space" || code === "Enter") toggle();
          }}
        >
          {variant === "radio" && <RadioInput isSelected={value} />}
          {variant === "checkbox" && <CheckboxInput isSelected={value} />}
        </Div>
        <HoverIndicator pAbsolute />
      </SelectableContainer>
      {label && textUI}
    </Div>
  );
};

type RadioInputWrapperProps = {
  isSelected: boolean;
};
export const RadioInputWrapper = styled(Div)<RadioInputWrapperProps>`
  margin-top: 18px;
  display: flex;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 500;
    `}
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    border: solid 1px ${(props) => props.theme.colors.navy};
    border-radius: 4px;
    padding: 10px 13px;
    display: flex;
    :hover {
      cursor: pointer;
      opacity: 0.8;
      border: solid 1px ${(props) => props.theme.colors.navy};
    }
  }
`;

export const Checkbox = ({ ...props }: SelectableProps) => (
  <Selectable variant="checkbox" {...props} />
);

export const Radio = ({ ...props }: SelectableProps) => (
  <Selectable variant="radio" {...props} />
);
