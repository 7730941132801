import { Coords, Features, PlacesGeoJSON } from "Components/Maps";
import { FeatureCollection } from "geojson";

type PointType = {
  id: string | number;
  position: Coords;
};

export const getGeoJSON = (points: PointType[]) => {
  const collection: PlacesGeoJSON = {
    type: "FeatureCollection",
    features: [],
  };
  for (const point of points) {
    if (
      !isNaN(Number(point.position?.lat)) &&
      !isNaN(Number(point.position?.lng))
    ) {
      collection.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.position.lng, point.position.lat],
        },
        properties: {
          ...point,
        },
      });
    }
  }
  return collection;
};
