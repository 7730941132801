import InstagramColor from "./Instagram-Color.svg?react";
import InstagramDark from "./Instagram-Dark.svg?react";
import FacebookColor from "./Facebook-Color.svg?react";
import FacebookDark from "./Facebook-Dark.svg?react";
import SpotifyColor from "./Spotify-Color.svg?react";
import SpotifyDark from "./Spotify-Dark.svg?react";
import YouTubeColor from "./YouTube-Color.svg?react";
import YouTubeDark from "./YouTube-Dark.svg?react";
import XDark from "./X-Dark.svg?react";
import TikTokColor from "./TikTok-Color.svg?react";
import TikTokDark from "./TikTok-Dark.svg?react";

export const socials = {
  InstagramColor,
  InstagramDark,
  FacebookColor,
  FacebookDark,
  SpotifyColor,
  SpotifyDark,
  YouTubeColor,
  YouTubeDark,
  XDark,
  TikTokColor,
  TikTokDark,
};
