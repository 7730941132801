import { useCallback } from "react";
import { Marker } from "./Marker";
import Supercluster, { ClusterProperties } from "supercluster";
import { PlaceCollection, PlacesGeoJSON } from "../types";
import { HandleCollectionClick, PlaceDetail } from "../types";
import useSupercluster from "use-supercluster";
import { BBox } from "geojson";
import { useMapViewport } from "../hooks/use-map-viewport";

export type MarkersProps = {
  map: google.maps.Map;
  geojson?: PlacesGeoJSON;
  onCollectionClick: HandleCollectionClick;
  bounds?: BBox;
  clusterRadius?: number;
  activeCollection?: PlaceCollection;
  zoom?: number;
  bbox?: BBox;
};

export const Markers = ({
  map,
  geojson,
  onCollectionClick,
  clusterRadius,
  activeCollection,
  zoom,
  bbox,
}: MarkersProps) => {
  const { clusters, supercluster } = useSupercluster({
    points: geojson.features,
    bounds: bbox,
    zoom,
    options: { radius: clusterRadius, extent: 156 * (zoom * 0.1) },
  });

  const handleClusterClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, clusterId: any) => {
      const leaves = supercluster?.getLeaves(clusterId);
      onCollectionClick(marker, { id: clusterId, features: leaves });
    },
    [supercluster, onCollectionClick],
  );

  const handleCollectionClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, markerId: any) => {
      const feature = clusters.find(
        (feat) => feat.properties.id === markerId,
      ) as Supercluster.PointFeature<PlaceDetail>;
      onCollectionClick(marker, { id: markerId, features: [feature] });
    },
    [clusters, onCollectionClick],
  );

  if (!clusters) {
    return null;
  }

  return clusters?.map((feature, index) => {
    const [lng, lat] = feature.geometry.coordinates;

    const properties = feature.properties as ClusterProperties;
    const isCluster: boolean = properties.cluster;
    const id =
      properties.cluster_id ||
      feature.id ||
      properties["id"] ||
      `marker-cluster-${index}`;

    const isActive = activeCollection?.id == id;
    return (
      <Marker
        key={id}
        id={id}
        isCluster={isCluster}
        position={{ lat, lng }}
        onClick={isCluster ? handleClusterClick : handleCollectionClick}
        value={properties.point_count_abbreviated}
        count={properties.point_count}
        zoom={zoom}
        active={!!isActive}
      />
    );
  });
};
